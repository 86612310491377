import { type FirestoreRoomStateRubricResultDetail } from '../../firestore/RoomStateRubricResultDetail'

export const mockRoomStateRubricResultDetailsForRoom: Array<FirestoreRoomStateRubricResultDetail> =
  [
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:56:47.363Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.6148583889007568,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:58:58.028Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.5972628593444824,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:58:58.028Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.46007275581359863,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',
      content: '',
      detailType: 0,

      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:58:58.028Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.41432687640190125,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',
      content: '',
      detailType: 0,

      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:58:58.028Z'),
      roomId: '',
      rubricId: 'DWHT7sfMvtPHZ0gv9z0x',
      match: 0.42205899953842163,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',
      content: '',
      detailType: 0,

      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:33.151Z'),
      roomId: '',
      rubricId: 'MRAtHaeHqEoRYB1AxGhk',
      match: 0.40948915481567383,
      score: 1,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:36.807Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.3891315460205078,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',
      content:
        'Student opined that scooters are not good and they are found in the ugliest places',
      detailType: 0,

      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:33.151Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.40673840045928955,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:33.151Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.4725531339645386,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:33.151Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.7851064205169678,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:36.807Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.5451059341430664,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:49:59.875Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.4673963487148285,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:58:58.028Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5920353531837463,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:01:26.698Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.695932924747467,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:58:58.028Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.4484464228153229,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:49:45.394Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5504429340362549,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:56:47.363Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.6762739419937134,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '1',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:01:18.206Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.5262490510940552,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:01:18.206Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.7599973678588867,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:01:18.206Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.5304704904556274,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:04:51.510Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.5951847434043884,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:08:12.705Z'),
      roomId: '',
      rubricId: 'MRAtHaeHqEoRYB1AxGhk',
      match: 0.5374582409858704,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:14:37.677Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.44594040513038635,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:09:04.237Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.36977604031562805,
      score: 3,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:01:18.206Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5312265157699585,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:48:27.404Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.4448263645172119,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:56:23.555Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5392298698425293,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:55:24.991Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.3798978626728058,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '2',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:49:15.255Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.6196428537368774,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:48:26.537Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.602949321269989,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.3811955153942108,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:02:08.783Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.48443278670310974,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:52:20.147Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.5393654108047485,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:51:05.757Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.6454653739929199,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.5211624503135681,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'CrqpC06l1GIBarbspGr2',
      match: 0.4739643931388855,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:09:46.364Z'),
      roomId: '',
      rubricId: 'DWHT7sfMvtPHZ0gv9z0x',
      match: 0.44131481647491455,
      score: 1,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'DWHT7sfMvtPHZ0gv9z0x',
      match: 0.5130643844604492,
      score: 1,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:49:15.255Z'),
      roomId: '',
      rubricId: 'DWHT7sfMvtPHZ0gv9z0x',
      match: 0.5303727388381958,
      score: 1,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'MRAtHaeHqEoRYB1AxGhk',
      match: 0.45598915219306946,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:10.240Z'),
      roomId: '',
      rubricId: 'MRAtHaeHqEoRYB1AxGhk',
      match: 0.33551254868507385,
      score: 2,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.543586790561676,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:10.240Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.49674513936042786,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:09:46.364Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.5310723185539246,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:09:46.364Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.4239748418331146,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:13:10.240Z'),
      roomId: '',
      rubricId: 'jj6KGcPesZCFWJO2p4K4',
      match: 0.4888724088668823,
      score: 4,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:49:15.255Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.586321234703064,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:48:26.537Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.4735913574695587,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T20:05:08.105Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5101864337921143,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:51:05.757Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5741443037986755,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:46:20.355Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.42165350914001465,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:57:27.357Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.546940803527832,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',

      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:49:15.255Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5671607851982117,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
    {
      assignmentId: '',
      detailType: 0,
      content: '',
      arguments: [],
      eventId: '',
      eventTime: new Date('2024-05-23T19:48:26.537Z'),
      roomId: '',
      rubricId: 'rBCv21nifElDTL0zlL2s',
      match: 0.5648701786994934,
      score: 5,
      sectionId: '',
      slideId: '',
      userId: '3',
    },
  ]
