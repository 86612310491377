import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

export const schema = z.object({
  roomId: z.string(),
  rubricId: z.string(),
  sectionId: nullSafeString(''),
  userId: nullSafeString(''),
  feedback: nullSafeString(''),
  assignmentId: nullSafeString(''),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    rubricId: '',
    sectionId: '',
    assignmentId: '',
    userId: '',
    feedback: '',
    updatedAt: null,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateRubricResultFeedbackInferred = z.infer<typeof schema>
export interface FirestoreRoomStateRubricResultFeedback
  extends FirestoreRoomStateRubricResultFeedbackInferred {}
