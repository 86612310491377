// User object that is shared publicly

import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreRoomStateAnswer,
} from '../firestore/RoomStateAnswer/schema'
import type { FirebaseRepository } from './FirebaseRepository'

let previewCounter = 0
export class RoomStateAnswer extends ObservableModelWithDecorators<FirestoreRoomStateAnswer> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  static emptyForPreview(
    repository: FirebaseRepository,
    {
      userId,
      slideQuestionId,
      answer,
      answerList,
    }: {
      userId: string
      slideQuestionId: string
      answer: number
      answerList: number[]
    }
  ) {
    const data: FirestoreRoomStateAnswer = {
      ...empty,
      slideQuestionId: slideQuestionId,
      userId: userId,
      answer: answer,
      answerList: answerList || [],
      submitted: false,
    }
    return emptyModel(repository, this, data, `preview-${previewCounter++}`)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateAnswer>
  ) {
    super(repository, doc)

    makeObservable(this)
  }
}
