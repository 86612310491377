import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'

export enum BloomScore {
  none = 0,
  remember = 1,
  understand = 2,
  apply = 3,
  analyze = 4,
  evaluate = 5,
  create = 6,
}

export enum PassFailScore {
  fail = 0,
  pass = 1,
}

const bloomsScore = z.nativeEnum(BloomScore)
const passFailScore = z.nativeEnum(PassFailScore)
const score = z.union([bloomsScore, passFailScore])

// this is the dart definition
// required List<String> arguments,
// required String justification, default: ''
// required String roomId,
// required BloomsScore score, default: BloomsScore.none
// required String rubricId,
// required PublicUser user,
// String assignmentId,
// String sectionId,
// int? updatedAt,
export const schema = z.object({
  arguments: nullSafeStringArray([]),
  justification: nullSafeString(''),
  roomId: z.string(),
  score: z.preprocess((val) => val ?? BloomScore.none, score),
  userId: z.string(),
  assignmentId: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    arguments: [],
    justification: '',
    roomId: '',
    score: BloomScore.none,
    userId: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateRubricResultInferred = z.infer<typeof schema>
export interface FirestoreRoomStateRubricResult
  extends FirestoreRoomStateRubricResultInferred {}
export type FirestoreRoomStateRubricResultWrite = z.infer<typeof schema>
