import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirebaseRepository } from './FirebaseRepository'
import {
  empty,
  type FirestoreSettingsFeatureFlags,
} from '../firestore/SettingsFeatureFlags/schema'

function hasLocalStorage() {
  try {
    if (window.localStorage) return true
    if (global.localStorage) return true
    return false
  } catch (e) {
    return false
  }
}

export class SettingsFeatureFlags extends ObservableModelWithDecorators<FirestoreSettingsFeatureFlags> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSettingsFeatureFlags>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get shouldShowMeetingFeedbackAsStars() {
    if (!hasLocalStorage) return false

    const value = localStorage.getItem('showMeetingFeedbackAsStars')
    if (value === 'false') return false
    if (value === 'true') return true

    const ratio = this.data.meetingFeedbackAsStarsRatio

    const random = Math.random()

    if (random < ratio) {
      localStorage.setItem('showMeetingFeedbackAsStars', 'true')
      return true
    } else {
      localStorage.setItem('showMeetingFeedbackAsStars', 'false')
      return false
    }
  }
}
