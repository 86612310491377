import type { ObservableMap } from 'mobx'
import { computed, makeObservable, observable, runInAction } from 'mobx'

import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { TeachingPlanStatus } from '../firestore/TeachingPlan/types'
import {
  changeStatus,
  deleteTeachingPlan,
  deleteTeachingPlanFile,
  getTeachingPlan,
  updateTeachingPlanDetails,
  updateTeachingPlanUpdatedAt,
  uploadTeachingPlanFile,
} from '../firestore/TeachingPlan'
import { TeachingPlan } from '../models/TeachingPlan'
import type {
  StaticModelCollection,
  TeachingPlanDetailsPayload,
  TeachingPlanUploadType,
} from '../types'
import { TeachingPlanModule } from '../models/TeachingPlanModule'
import type { ModuleFieldsForUpload } from '../firestore/TeachingPlanModule'
import {
  deleteTeachingPlanModule,
  getTeachingPlanModules,
  saveTeachingPlanModule,
  sortTeachingPlanModules,
} from '../firestore/TeachingPlanModule'
import type { SlideDeck } from '../models/SlideDeck'
import { getSlideDecksForCatalog } from '../firestore/SlideDeck'
import {
  deleteTeachingPlanModuleSlideDeck,
  fetchTeachingPlanModuleSlideDecks,
  saveTeachingPlanModuleSlideDeck,
} from '../firestore/TeachingPlanModuleSlideDeck'
import { TeachingPlanAuthor } from '../models/TeachingPlanAuthor'
import {
  deleteTeachingPlanAuthor,
  deleteTeachingPlanAuthorImage,
  getTeachingPlanAuthors,
  saveTeachingPlanAuthor,
  sortTeachingPlanAuthors,
  uploadTeachingPlanAuthorImage,
} from '../firestore/TeachingPlanAuthor'
import { getTeachingPlanAggregation } from '../firestore/TeachingPlanAggregation'
import { TeachingPlanAggregation } from '../models/TeachingPlanAggregation'

export class AdminTeachingPlanCubit extends Cubit {
  repository: FirebaseRepository
  teachingPlanId: string
  catalogId: string
  teachingPlan: TeachingPlan
  modules: StaticModelCollection<TeachingPlanModule>
  catalogSlideDecks: ObservableMap<string, SlideDeck>
  teachingPlanAggregation: TeachingPlanAggregation
  authors: StaticModelCollection<TeachingPlanAuthor>

  constructor(
    repository: FirebaseRepository,
    teachingPlanId: string,
    catalogId: string
  ) {
    super()
    makeObservable(this)

    this.repository = repository
    this.teachingPlanId = teachingPlanId
    this.catalogId = catalogId

    this.authors = TeachingPlanAuthor.emptyCollection(repository)
    this.teachingPlan = TeachingPlan.empty(repository)
    this.modules = TeachingPlanModule.emptyCollection(repository)
    this.catalogSlideDecks = observable.map<string, SlideDeck>(
      {},
      { deep: false }
    )
    this.teachingPlanAggregation = TeachingPlanAggregation.empty(repository)
  }

  initialize(): void {
    this.addStream(
      getTeachingPlan(this.repository, {
        teachingPlanId: this.teachingPlanId,
        catalogId: this.catalogId,
      }),
      (teachingPlan) => {
        this.teachingPlan.replaceModel(teachingPlan)
        this.getTeachingPlanModules()
      }
    )

    this.addStream(
      getSlideDecksForCatalog(this.repository, {
        catalogId: this.catalogId,
      }),
      (slideDecks) => {
        slideDecks.forEach((slideDeck) => {
          runInAction(() => {
            this.catalogSlideDecks.set(slideDeck.id, slideDeck)
          })
        })
      }
    )
  }

  getTeachingPlanModules() {
    this.addStream(
      getTeachingPlanModules(this.repository, {
        teachingPlanId: this.teachingPlanId,
        catalogId: this.catalogId,
      }),
      (modules) => {
        this.modules.replaceModels(modules)
      }
    )
    this.addStream(
      getTeachingPlanAggregation(this.repository, {
        catalogId: this.catalogId,
        teachingPlanId: this.teachingPlanId,
      }),
      (aggregation) => {
        this.teachingPlanAggregation.replaceModel(aggregation)
      }
    )

    this.addStream(
      getTeachingPlanAuthors(this.repository, {
        catalogId: this.catalogId,
        teachingPlanId: this.teachingPlanId,
      }),
      (authors) => {
        this.authors.replaceModels(authors)
      }
    )
  }

  @computed
  get cantPublishReason() {
    if (!this.teachingPlan.data.teachingPlanName) {
      return 'teaching_plan_name_required'
    }
    if (!this.teachingPlan.data.teachingPlanDescription) {
      return 'teaching_plan_description_required'
    }
    if (!this.teachingPlan.data.teachingPlanImageURL) {
      return 'teaching_plan_image_required'
    }
    if (this.teachingPlanAggregation.data?.moduleCount === 0) {
      return 'teaching_plan_modules_required'
    }
    if (this.teachingPlanAggregation.data?.slideDeckCount === 0) {
      return 'teaching_plan_experiences_required'
    }
    if (this.authors?.models.length === 0) {
      return 'teaching_plan_authors_required'
    }

    return null
  }

  publishTeachingPlan() {
    return changeStatus(
      this.repository,
      this.catalogId,
      this.teachingPlanId,
      TeachingPlanStatus.published
    )
  }

  unPublishTeachingPlan() {
    return changeStatus(
      this.repository,
      this.catalogId,
      this.teachingPlanId,
      TeachingPlanStatus.draft
    )
  }

  saveModule = async (
    values: ModuleFieldsForUpload & {
      newExperiences: { slideDeckId: string; slideDeckTypeId: string }[]
      oldExperienceIds: string[]
    },
    _moduleId?: string
  ) => {
    const { newExperiences, oldExperienceIds, ...moduleFields } = values

    const moduleId = await saveTeachingPlanModule(this.repository, {
      catalogId: this.catalogId,
      moduleFields: {
        moduleDescription: moduleFields.moduleDescription,
        moduleName: moduleFields.moduleName,
      },
      teachingPlanId: this.teachingPlanId,
      moduleId: _moduleId,
    })

    if (!moduleId) return

    const savePromises = newExperiences
      .filter((experience, index) => {
        const oldExperienceIndex = oldExperienceIds.indexOf(
          experience.slideDeckId
        )
        return oldExperienceIndex === -1 || oldExperienceIndex !== index
      })
      .map((experience, index) =>
        saveTeachingPlanModuleSlideDeck(this.repository, {
          catalogId: this.catalogId,
          slideDeckId: experience.slideDeckId,
          slideDeckTypeId: experience.slideDeckTypeId,
          moduleId,
          teachingPlanId: this.teachingPlanId,
          slideDeckOrder: index,
        })
      )

    // Delete old experiences that are not in the new experiences
    const newExperienceIds = newExperiences.map(
      (experience) => experience.slideDeckId
    )
    const experiencesToDelete = oldExperienceIds.filter(
      (experienceId) => !newExperienceIds.includes(experienceId)
    )
    const deletePromises = experiencesToDelete.map((slideDeckId) =>
      deleteTeachingPlanModuleSlideDeck(this.repository, {
        catalogId: this.catalogId,
        slideDeckId: slideDeckId,
        teachingPlanId: this.teachingPlanId,
        moduleId,
      })
    )

    await Promise.all([...savePromises, ...deletePromises])

    this.updateTeachingPlanUpdatedAt()
  }

  updateTeachingPlanUpdatedAt() {
    updateTeachingPlanUpdatedAt(
      this.repository,
      this.catalogId,
      this.teachingPlanId
    )
  }

  fetchTeachingPlanModules = (moduleId: string) => {
    return fetchTeachingPlanModuleSlideDecks(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      moduleId,
    })
  }

  saveTeachingPlan = (params: TeachingPlanDetailsPayload) => {
    return updateTeachingPlanDetails(
      this.repository,
      this.catalogId,
      this.teachingPlanId,
      params
    )
  }

  reorderModules = (
    currentOrder: string[],
    oldIndex: number,
    newIndex: number
  ) => {
    return sortTeachingPlanModules(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      currentOrder,
      oldIndex,
      newIndex,
    })
  }

  uploadTeachingPlanFile = (
    file: File,
    teachingPlanUploadType: TeachingPlanUploadType
  ) => {
    return uploadTeachingPlanFile(this.repository, {
      catalogId: this.catalogId,
      file,
      teachingPlanId: this.teachingPlanId,
      teachingPlanUploadType,
    })
  }

  deleteTeachingPlanFile = (teachingPlanUploadType: TeachingPlanUploadType) => {
    return deleteTeachingPlanFile(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      teachingPlanUploadType,
    })
  }

  deleteTeachingPlan = () => {
    return deleteTeachingPlan(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
    })
  }

  deleteTeachingPlanModule = async (moduleId: string) => {
    await deleteTeachingPlanModule(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      moduleId,
    })

    this.updateTeachingPlanUpdatedAt()
  }

  /****************************************************************
   *  Authors
   ***************************************************************/
  uploadTeachingPlanAuthorImage = async (
    params: Omit<
      Parameters<typeof uploadTeachingPlanAuthorImage>[1],
      'catalogId' | 'teachingPlanId'
    >
  ) => {
    const result = await uploadTeachingPlanAuthorImage(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      ...params,
    })

    this.updateTeachingPlanUpdatedAt()

    return result
  }

  deleteTeachingPlanAuthorImage = async (
    params: Omit<
      Parameters<typeof deleteTeachingPlanAuthorImage>[1],
      'catalogId' | 'teachingPlanId'
    >
  ) => {
    const result = await deleteTeachingPlanAuthorImage(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      ...params,
    })

    this.updateTeachingPlanUpdatedAt()

    return result
  }

  saveTeachingPlanAuthor = async (
    params: Omit<
      Parameters<typeof saveTeachingPlanAuthor>[1],
      'catalogId' | 'teachingPlanId'
    >
  ) => {
    const result = await saveTeachingPlanAuthor(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      ...params,
    })

    this.updateTeachingPlanUpdatedAt()

    return result
  }

  deleteTeachingPlanAuthor = async (
    params: Omit<
      Parameters<typeof deleteTeachingPlanAuthor>[1],
      'catalogId' | 'teachingPlanId'
    >
  ) => {
    const result = await deleteTeachingPlanAuthor(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      ...params,
    })

    this.updateTeachingPlanUpdatedAt()

    return result
  }

  reorderTeachingPlanAuthors = async (
    params: Omit<
      Parameters<typeof sortTeachingPlanAuthors>[1],
      'catalogId' | 'teachingPlanId'
    >
  ): Promise<void> => {
    const result = await sortTeachingPlanAuthors(this.repository, {
      catalogId: this.catalogId,
      teachingPlanId: this.teachingPlanId,
      ...params,
    })

    this.updateTeachingPlanUpdatedAt()

    return result
  }
}
