import {
  collection,
  collectionGroup,
  doc,
  writeBatch,
  type FirestoreDataConverter,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { addDocWithError } from '../../firestore-mobx/fetch'
import { serverTimestamp } from 'firebase/firestore'
import { type FirestoreRoomStateReset, schema } from './schema'
import { buildUnsupportedConverter } from '../shared/noops'
export * from './schema'
import {
  modelItemStream,
  partitionedQueryStream,
} from '../../firestore-mobx/stream'
import { RoomStateReset } from '../../models/RoomStateReset'

const readConverter: FirestoreDataConverter<FirestoreRoomStateReset> = {
  toFirestore: buildUnsupportedConverter<FirestoreRoomStateReset>().toFirestore,
  fromFirestore: (snapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

// const writeConverter: FirestoreDataConverter<z.infer<typeof writeSchema>> = {
//   toFirestore: (data) => {
//     writeSchema.partial().parse(data)
//     return data
//   },
//   fromFirestore:
//     buildUnsupportedConverter<z.infer<typeof writeSchema>>().fromFirestore,
// }

export const resetRoomState = async (
  repository: FirebaseRepository,
  {
    roomId,
    sectionId,
    assignmentId,
    timeout,
  }: {
    roomId: string
    sectionId: string
    assignmentId: string
    timeout?: number
  }
): Promise<void> => {
  const resetColRef = collection(
    repository.firestore,
    'room_state',
    roomId,
    'room_state_reset'
  )

  const ref = await addDocWithError(resetColRef, {
    endedAt: null,
    roomId: roomId,
    startedAt: serverTimestamp(),
    assignmentId: assignmentId,
    sectionId: sectionId,
  })

  const stream = modelItemStream(
    repository,
    ref.withConverter(readConverter),
    RoomStateReset
  )

  let timeoutId: NodeJS.Timeout | undefined

  const errorAfterTimeout = new Promise(
    (_, reject) =>
      (timeoutId = setTimeout(
        () => reject(new Error('timeout waiting for room state reset')),
        timeout || 10000
      ))
  )

  try {
    await Promise.race([
      stream.firstWhere((doc) => doc.data.success === true),
      errorAfterTimeout,
    ])
  } finally {
    stream.close()
    clearTimeout(timeoutId)
  }
}

export const resetRoomStates = async (
  repository: FirebaseRepository,
  {
    roomIds,
    sectionId,
    assignmentId,
    timeout,
  }: {
    roomIds: string[]
    sectionId: string
    assignmentId: string
    timeout?: number
  }
): Promise<void> => {
  if (!roomIds.length) return
  if (roomIds.length === 1) {
    return resetRoomState(repository, {
      roomId: roomIds[0],
      sectionId,
      assignmentId,
      timeout,
    })
  }

  const batch = writeBatch(repository.firestore)

  const resetDocIds = roomIds.map((roomId) => {
    const resetColRef = collection(
      repository.firestore,
      'room_state',
      roomId,
      'room_state_reset'
    )

    // make new doc ref
    const newDocRef = doc(resetColRef)

    batch.set(newDocRef, {
      endedAt: null,
      roomId: roomId,
      startedAt: serverTimestamp(),
      assignmentId: assignmentId,
      sectionId: sectionId,
    })

    // collection group documentId queries must be fully qualified paths
    return `room_state/${roomId}/room_state_reset/${newDocRef.id}`
  })

  // Validate and write the data in one operation
  await batch.commit()

  // get a stream of all the new docs
  const stream = partitionedQueryStream({
    repository,
    matchArray: resetDocIds,
    ref: collectionGroup(
      repository.firestore,
      'room_state_reset'
    ).withConverter(readConverter),
    model: RoomStateReset,
  })

  let timeoutId: NodeJS.Timeout | undefined

  const errorAfterTimeout = new Promise(
    (_, reject) =>
      (timeoutId = setTimeout(
        () => reject(new Error('timeout waiting for room state reset')),
        timeout || 10000
      ))
  )

  try {
    // wait for all the docs to be marked as successful
    await Promise.race([
      stream.firstWhere((docs) => {
        return docs.every((doc) => doc.data.success === true)
      }),
      errorAfterTimeout,
    ])
  } finally {
    stream.close()
    clearTimeout(timeoutId)
  }
}
