import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  collection,
  doc,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { schema } from './schema'
import type { FirestoreOrganizationInstructor } from '../OrganizationInstructor/schema'
import { updateDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreOrganizationInstructor> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  organizationId: string
): CollectionReference<FirestoreOrganizationInstructor> => {
  return collection(
    firestore,
    'organization',
    organizationId,
    'organization_instructor'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  organizationId: string,
  userId: string
) => {
  return doc(getColRef(firestore, organizationId), userId)
}

export const updateOrgInstructor = async (
  repository: FirebaseRepository,
  organizationId: string,
  userId: string,
  payload: Pick<FirestoreOrganizationInstructor, 'autoApproveInvoice'>
) => {
  const firestore = repository.firestore
  const docRef = getDocRef(firestore, organizationId, userId)

  await updateDocWithError(docRef, payload)
}
