import type {
  CollectionReference,
  DocumentData,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  collectionGroup,
  query,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { StreamInterface } from 'tricklejs/dist/types'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SectionPromotion } from '../../models/SectionPromotion'
import type { FirestoreSectionPromotion } from './schema'
import { schema, writeSchema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSectionPromotion> = {
  toFirestore: (user: PartialWithFieldValue<FirestoreSectionPromotion>) => {
    return writeSchema.partial().parse(user)
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  sectionId: string
): CollectionReference<FirestoreSectionPromotion, DocumentData> => {
  return collection(
    firestore,
    'section',
    sectionId,
    'section_promotion'
  ).withConverter(converter)
}

export const getSectionPromotions = (
  repository: FirebaseRepository,
  params: { sectionId: string }
): StreamInterface<SectionPromotion[]> => {
  const ref = getColRef(repository.firestore, params.sectionId)

  return modelListStream(repository, ref, SectionPromotion)
}

export const getSectionPromotionsForUser = (
  repository: FirebaseRepository,
  params: { userId: string }
): StreamInterface<SectionPromotion[]> => {
  const ref = collectionGroup(
    repository.firestore,
    'section_promotion'
  ).withConverter(converter)

  const q = query(ref, where('userId', '==', params.userId))

  return modelListStream(repository, q, SectionPromotion)
}
