import type { CollectionReference } from 'firebase/firestore'
import {
  query,
  serverTimestamp,
  Timestamp,
  where,
  type DocumentData,
} from 'firebase/firestore'
import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreSlideDeckRubricAI } from './schema'
import { schema } from './schema'
import { modelListStream } from '../../firestore-mobx/stream'
import { SlideDeckRubricAI } from '../../models/SlideDeckRubricAI'
import { addDocWithError, getDocsWithError } from '../../firestore-mobx/fetch'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSlideDeckRubricAI> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  { slideDeckId, rubricId }: { slideDeckId: string; rubricId: string }
): CollectionReference<FirestoreSlideDeckRubricAI, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'rubric',
    rubricId,
    'ai'
  ).withConverter(converter)
}

export const forceRubricTestAIProcessing = async (
  repository: FirebaseRepository,
  {
    rubricId,
    slideDeckId,
    rubricTestId,
  }: { slideDeckId: string; rubricId: string; rubricTestId?: string }
) => {
  const ref = getColRef(repository.firestore, { slideDeckId, rubricId })
  // Create a query to find records where 'endedAt' is null
  let q = query(ref, where('endedAt', '==', null))

  // If testId is provided, add an additional where clause to filter by testId
  if (rubricTestId) {
    q = query(q, where('rubricTestId', '==', rubricTestId))
  }

  // Execute the query to get matching documents
  const aiRecords = await getDocsWithError(
    q,
    'FetchFoForceRubricTestAIProcessingError'
  )

  const fiveMinutesAgo = Timestamp.fromDate(
    new Date(Date.now() - 5 * 60 * 1000)
  )

  // Check if all records are stale
  const allRecordsStale = aiRecords.docs.every((doc) => {
    const data = doc.data()
    return !data.startedAt || data.startedAt <= fiveMinutesAgo.toDate()
  })

  if (!aiRecords.empty && !allRecordsStale) {
    // There is at least one record that is still running. Do not start a new one.
    return false
  }

  await addDocWithError(
    ref,
    {
      rubricId: rubricId,
      startedAt: serverTimestamp(),
      endedAt: null,
      rubricTestId: rubricTestId ?? null, // Only add testId if it's provided
    },
    'ForceRubricTestAIProcessingError'
  )

  return true
}

export const getSlideDeckRubricAI = (
  repository: FirebaseRepository,
  { slideDeckId, rubricId }: { slideDeckId: string; rubricId: string }
) => {
  const ref = getColRef(repository.firestore, { slideDeckId, rubricId })

  return modelListStream(repository, ref, SlideDeckRubricAI)
}
