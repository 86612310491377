import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  PartialWithFieldValue,
  Query,
} from 'firebase/firestore'
import {
  and,
  collection,
  collectionGroup,
  doc,
  query,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { StaticModelCollection } from '../../firestore-mobx/model'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { RoomStateRubricResult } from '../../models/RoomStateRubricResult'
import type { FirestoreRoomStateRubricResult } from './schema'
import { empty, schema, writeSchema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreRoomStateRubricResult> = {
  toFirestore: (
    data: PartialWithFieldValue<FirestoreRoomStateRubricResult>
  ) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: { roomId: string }
): CollectionReference<FirestoreRoomStateRubricResult> => {
  return collection(
    firestore,
    'room_state',
    params.roomId,
    'rubric_result'
  ).withConverter(converter)
}

// @ts-expect-error - unused for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore,
  roomId: string
): DocumentReference<FirestoreRoomStateRubricResult, DocumentData> => {
  return doc(getColRef(firestore, { roomId }), roomId)
}

function getColGroupRef(
  firestore: Firestore
): Query<FirestoreRoomStateRubricResult> {
  return collectionGroup(firestore, 'rubric_result').withConverter(converter)
}

export const buildEmptyRoomStateRubricResultCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: RoomStateRubricResult,
    empty: empty,
  })
}

export const getRoomStateRubricResultStreamForAssignment = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  return modelListStream(repository, q, RoomStateRubricResult)
}

export const getRoomStateRubricResultsForUser = (
  repository: FirebaseRepository,
  { userId, roomStateId }: { userId: string; roomStateId: string }
) => {
  const q = query(
    collection(
      repository.firestore,
      'room_state',
      roomStateId,
      'user_rubric_result',
      userId,
      'rubric_result'
    ).withConverter(converter)
  )
  return modelListStream(repository, q, RoomStateRubricResult)
}
