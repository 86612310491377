import type { FirebaseRepository } from './FirebaseRepository'
import { PromotionType, type MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty, type FirestoreUserPromotion } from '../firestore/UserPromotion'
import { computed } from 'mobx'
import { DateTime } from 'luxon'

export class UserPromotion extends ObservableModelWithDecorators<FirestoreUserPromotion> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreUserPromotion>
  ) {
    super(repository, doc)
  }

  @computed
  get isActive() {
    const expirationDateTime = DateTime.fromJSDate(this.data.expirationDate)
    return expirationDateTime > this.repository.currentMinute
  }

  @computed
  get isModifiable() {
    if (this.data.promotionSource !== PromotionType.admin) {
      return false
    }
    if (this.data.updatedAt) {
      // is more than an hour ago
      const now = this.repository.currentMinute.toMillis()
      const updatedAt = this.data.updatedAt.getTime()
      const difference = Math.abs(now - updatedAt)
      return difference < 60 * 60 * 1000
    }
    return true
  }
}
