import type { Firestore } from 'firebase/firestore'
import type { ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import { getRoomMessages, sendMessage } from '../../firestore/RoomMessage'
import { type ChatMessage } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'
import type { BreakoutUser } from '../../models/BreakoutUser'
import type { StaticModelCollection } from '../../firestore-mobx/model'
import { RoomMessage } from '../../models/RoomMessage'
import { Cubit } from '../core'

function hasLocalStorage() {
  try {
    if (window.localStorage) return true
    if (global.localStorage) return true
    return false
  } catch (e) {
    return false
  }
}

export class MeetingChatController extends Cubit {
  firestore: Firestore
  roomId: string
  currentUser: BreakoutUser
  meeting: MeetingCubit
  readMessages: ObservableMap<string, boolean> = observable.map({})
  messagesCol: StaticModelCollection<RoomMessage>

  constructor(meeting: MeetingCubit) {
    super()
    this.currentUser = meeting.currentUser
    this.meeting = meeting
    this.firestore = meeting.firestore
    this.roomId = meeting.roomId

    this.messagesCol = RoomMessage.emptyCollection(meeting.repository)
    this.restoreReadMessages()

    makeObservable(this, {
      isLoading: computed,
      hasData: computed,
      messages: computed,
      hasUnreadMessages: computed,
      unreadMessageCount: computed,
      markAllMessagesRead: action,
    })
  }

  initialize(): void {
    this.addStream(
      getRoomMessages(this.meeting.repository, { roomId: this.roomId }),
      (messages) => {
        this.messagesCol.replaceModels(messages)
      }
    )
  }

  get hasData() {
    return this.messagesCol.hasDocuments
  }

  get isLoading() {
    return this.messagesCol.isLoading
  }

  get unreadMessageCount() {
    let count = 0
    for (const message of this.messagesCol.documents) {
      if (!this.readMessages.get(message.id)) {
        count++
      }
    }
    return count
  }

  get hasUnreadMessages() {
    for (const message of this.messagesCol.documents) {
      if (!this.readMessages.get(message.id)) {
        return true
      }
    }
    return false
  }

  get messages() {
    if (this.isLoading) return []
    const currentUser = this.meeting.currentUser
    const messages: ChatMessage[] = []
    for (const message of this.messagesCol.models) {
      const author = message.author

      const data = message.data
      const isCurrentUserMessage = data.authorId === currentUser.uid

      const timestamp = data.createdAt

      messages.push({
        ...data,
        text: data.text,
        id: message.id,
        author,
        firstFromUser: false,
        showAvatar: false,
        timestamp,
        isCurrentUserMessage,
      })
    }
    const sorted = messages.sort((a, b) => {
      const aTime = a.createdAt?.getTime() || 0
      const bTime = b.createdAt?.getTime() || 0

      return aTime > bTime ? 1 : -1
    })

    let firstFromUser = true
    let lastUid = ''
    sorted.forEach((message) => {
      firstFromUser = lastUid !== message.authorId
      lastUid = message.authorId

      message.showAvatar =
        firstFromUser &&
        !message.isCurrentUserMessage &&
        message.author.data.imageUrl !== null

      message.firstFromUser = firstFromUser
    })

    return sorted
  }

  sendMessage = async (text: string) => {
    sendMessage(this.firestore, this.roomId, this.currentUser.uid, text)
  }

  markAllMessagesRead() {
    for (const message of this.messagesCol.documents) {
      this.readMessages.set(message.id, true)
    }
    this.storeReadMessages()
  }

  storeReadMessages() {
    if (!hasLocalStorage()) return

    localStorage.setItem(
      this.localStorageKey(),
      JSON.stringify({
        // expire in 1h
        expires: Date.now() + 1000 * 60 * 60,
        ids: Array.from(this.readMessages.keys()),
      })
    )
  }

  restoreReadMessages() {
    if (!hasLocalStorage()) return

    const read = localStorage.getItem(this.localStorageKey())

    if (!read) return

    // attempt to restore read messages, if it fails, clear it
    try {
      const data = JSON.parse(read)

      if (Date.now() > data.expires) {
        localStorage.removeItem(this.localStorageKey())
        return
      }

      for (const id of data.ids) {
        this.readMessages.set(id, true)
      }
    } catch (e) {
      console.error('failed to restore read messages', e)
      localStorage.removeItem(this.localStorageKey())
    }
  }

  localStorageKey() {
    return `chat-${this.roomId}-readMessages`
  }
}
