import { z } from 'zod'
import {
  nullSafeFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'
export const defaultTokenPrice = '10'

export const schema = z.object({
  stripeProductId: nullSafeOptionalString(),
  tokenPrice: z.string(),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    stripeProductId: '',
    tokenPrice: defaultTokenPrice,
    updatedAt: null,
  })
)

export interface FirestoreSettingsPricing extends z.infer<typeof schema> {}
