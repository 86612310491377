import {
  CollectionReference,
  type DocumentData,
  type DocumentReference,
  serverTimestamp,
} from 'firebase/firestore'
import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideRubric } from '../../models/SlideRubric'
import type { FirestoreSlideRubric } from './schema'
import { schema, type writeSchema } from './schema'
import { type z } from 'zod'
import {
  addDocWithError,
  deleteDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSlideRubric> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  { slideDeckId }: { slideDeckId: string }
): CollectionReference<FirestoreSlideRubric, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'rubric'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  { slideDeckId, slideRubricId }: { slideDeckId: string; slideRubricId: string }
): DocumentReference<FirestoreSlideRubric, DocumentData> => {
  return doc(getColRef(firestore, { slideDeckId: slideDeckId }), slideRubricId)
}

export const getSlideRubrics = (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
) => {
  const ref = getColRef(repository.firestore, params)

  return modelListStream(repository, ref, SlideRubric)
}

export const getSlideDeckRubric = (
  repository: FirebaseRepository,
  params: { slideDeckId: string; slideRubricId: string }
) => {
  const ref = getDocRef(repository.firestore, params)

  return modelItemStream(repository, ref, SlideRubric)
}

export const deleteSlideRubric = async (
  repository: FirebaseRepository,
  { slideRubricId, slideDeckId }: { slideRubricId: string; slideDeckId: string }
) => {
  await deleteDocWithError(
    getDocRef(repository.firestore, { slideRubricId, slideDeckId }),
    'DeleteSlideRubricError'
  )
}

export const saveSlideRubric = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    rubricId,
    rubricFields,
  }: {
    rubricFields: Omit<z.infer<typeof writeSchema>, 'updatedAt'>
    slideDeckId: string
    rubricId?: string
  }
) => {
  const dataToWrite = {
    ...rubricFields,
    updatedAt: serverTimestamp(),
  }

  const ref = rubricId
    ? getDocRef(repository.firestore, { slideDeckId, slideRubricId: rubricId })
    : getColRef(repository.firestore, { slideDeckId })

  if (ref instanceof CollectionReference) {
    return await addDocWithError(ref, dataToWrite, 'CreateSlideRubricError')
  }

  return await updateDocWithError(ref, dataToWrite, 'UpdateSlideRubricError')
}

export const assignSlideRubricToSlide = async (
  repository: FirebaseRepository,
  {
    slideId,
    slideDeckId,
    rubricId,
  }: {
    slideId: string
    slideDeckId: string
    rubricId: string
  }
) => {
  const ref = getDocRef(repository.firestore, {
    slideDeckId,
    slideRubricId: rubricId,
  })

  await updateDocWithError(
    ref,
    {
      slideId,
      updatedAt: serverTimestamp(),
    },
    'AssignSlideRubricToSlideError'
  )
}
