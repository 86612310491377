import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeNumber,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
} from '../shared/schema'

// this is the dart definition
// required this.consumed,
// required this.tokenQuantity, default 1
// this.assignmentId,
// this.consumedCount,
// this.paymentId,
// this.price,
// this.roomId,
// this.sectionId,
export const schema = z.object({
  consumed: z.boolean(),
  tokenQuantity: nullSafeNumber(1),
  assignmentId: nullSafeOptionalString(),
  consumedCount: nullSafeOptionalNumber(),
  createdAt: nullSafeOptionalFirestoreTimestamp(),
  paymentId: nullSafeOptionalString().nullable(),
  price: nullSafeOptionalNumber(),
  purchaseDate: nullSafeOptionalFirestoreTimestamp(),
  purchaseType: nullSafeOptionalString(),
  roomId: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  slideDeckId: nullSafeOptionalString(),
  creatorUserId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  userId: nullSafeOptionalString(),
})

export const writeSchema = schema.extend({
  creatorUserId: z.string(),
  createdAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    consumed: false,
    tokenQuantity: 0,
  })
)

export type FirestoreUserProfileToken = z.infer<typeof schema>
