import { z } from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeString,
} from '../shared/schema'

export const teachingPlanModuleSchema = z.object({
  catalogId: nullSafeString(''),
  teachingPlanId: nullSafeString(''),
  moduleName: nullSafeString(''),
  moduleDescription: nullSafeString(''),
  moduleOrder: nullSafeOptionalNumber(0),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const emptyTeachingPlanModule = teachingPlanModuleSchema.parse({
  catalogId: '',
  teachingPlanId: '',
  moduleName: '',
  moduleDescription: '',
  moduleOrder: 0,
})

export type FirestoreTeachingPlanModule = z.infer<
  typeof teachingPlanModuleSchema
>
