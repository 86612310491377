import type {
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  doc,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelItemStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { RoomStateFeedback } from '../../models/RoomStateFeedback'
import type { FirestoreRoomStateFeedback } from './schema'
import { schema, writeSchema } from './schema'
import { setDocWithError } from '../../firestore-mobx/fetch'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreRoomStateFeedback> = {
  toFirestore: (user: PartialWithFieldValue<FirestoreRoomStateFeedback>) => {
    writeSchema.partial().parse(user)

    return user
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getDocRef = (
  firestore: Firestore,
  params: {
    roomId: string
    userId: string
  }
): DocumentReference<FirestoreRoomStateFeedback, DocumentData> => {
  const roomStateRef = doc(collection(firestore, 'room_state'), params.roomId)
  const feedbackCollectionRef = collection(
    roomStateRef,
    'feedback'
  ).withConverter(converter)

  return doc(feedbackCollectionRef, params.userId)
}

export const createRoomStateFeedback = async (
  firestore: Firestore,
  data: Omit<FirestoreRoomStateFeedback, 'updatedAt'>
) => {
  const ref = getDocRef(firestore, data)

  await setDocWithError(
    ref,
    {
      ...data,
      updatedAt: serverTimestamp(),
    },
    {
      errorName: 'CreateRoomStateFeedbackError',
    }
  )
}

export const getRoomStateFeedback = (
  repository: FirebaseRepository,
  params: {
    roomId: string
    userId: string
  }
) => {
  const ref = getDocRef(repository.firestore, params)

  return modelItemStream(repository, ref, RoomStateFeedback)
}
