import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'

// this is the dart definition
// required String catalogName, default: ''
// required List<String> catalogSharedSectionIds, default: []
// String? catalogDescription,
// int? updatedAt,
export const schema = z.object({
  // guarantee catalogName to be a string
  catalogName: nullSafeString(''),
  catalogDescription: nullSafeOptionalString(),
  catalogSharedSectionIds: nullSafeStringArray([]),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    catalogName: '',
    catalogDescription: '',
    catalogSharedSectionIds: [],
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreCatalog extends z.infer<typeof schema> {}
