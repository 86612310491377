import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  collection,
  doc,
  serverTimestamp,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import {
  type FirestoreOrganizationIntegration,
  type OrganizationIntegrationType,
  schema,
} from './schema'
import { modelListStream } from '../../firestore-mobx/stream'
import { OrganizationIntegration } from '../../models/OrganizationIntegration'
import { addDocWithError, updateDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreOrganizationIntegration> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  organizationId: string
): CollectionReference<FirestoreOrganizationIntegration> => {
  return collection(
    firestore,
    'organization',
    organizationId,
    'organization_integration'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  organizationId: string,
  integrationId: string
) => {
  return doc(getColRef(firestore, organizationId), integrationId)
}

export const getOrganizationIntegrations = (
  repository: FirebaseRepository,
  organizationId: string
) => {
  const firestore = repository.firestore
  const colRef = getColRef(firestore, organizationId)
  return modelListStream(repository, colRef, OrganizationIntegration)
}

export const createOrUpdateOrganizationIntegration = async (
  repository: FirebaseRepository,
  {
    integrationId, //optional, for update
    organizationId,
    integrationName,
    integrationType,
    integrationHostname,
    integrationClientId,
  }: {
    integrationId?: string
    organizationId: string
    integrationName: string
    integrationType: OrganizationIntegrationType
    integrationHostname: string
    integrationClientId: string
  }
) => {
  const firestore = repository.firestore
  const colRef = getColRef(firestore, organizationId)
  const data = {
    integrationName,
    integrationType,
    integrationHostname,
    integrationClientId,
    organizationId,
    updatedAt: serverTimestamp(),
  }

  if (integrationId) {
    const docRef = getDocRef(firestore, organizationId, integrationId)
    await updateDocWithError(docRef, data, 'updateOrganizationIntegrationError')
    return integrationId
  }
  const docRef = await addDocWithError(
    colRef,
    data,
    'createOrganizationIntegrationError'
  )
  return docRef.id
}
