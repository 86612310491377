import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'

import type { FirebaseRepository } from './FirebaseRepository'
import { SlideDeckReferenceType } from '../types'
import type { FirestoreSlideDeckReference } from '../firestore/SlideDeckReference'
import { empty } from '../firestore/SlideDeckReference'
import { DateTime } from 'luxon'

export { SlideDeckReferenceType }

export class SlideDeckReference extends ObservableModelWithDecorators<FirestoreSlideDeckReference> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideDeckReference>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  getFormattedPublishedAt() {
    // Ex. Apr 2013
    return DateTime.fromJSDate(this.data.publishedAt).toFormat('LLL yyyy')
  }
}
