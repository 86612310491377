import type { Firestore, Query } from 'firebase/firestore'
import {
  and,
  collectionGroup,
  query,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { RoomStateRubricResultDetail } from '../../models/RoomStateRubricResultDetail'
import type { FirestoreRoomStateRubricResultDetail } from './schema'
import { schema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreRoomStateRubricResultDetail> =
  {
    toFirestore: () => {
      throw new Error('Not implemented')
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot) => {
      const data = snapshot.data({ serverTimestamps: 'estimate' })
      return schema.parse(data)
    },
  }

function getColGroupRef(
  firestore: Firestore
): Query<FirestoreRoomStateRubricResultDetail> {
  return collectionGroup(firestore, 'rubric_result_detail').withConverter(
    converter
  )
}

export const getRoomStateRubricResultDetailsForRoom = (
  repository: FirebaseRepository,
  { roomId }: { roomId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(where('roomId', '==', roomId))
  )

  return modelListStream(repository, q, RoomStateRubricResultDetail)
}

export const getRoomStateRubricResultDetailsForAssignment = (
  repository: FirebaseRepository,
  { assignmentId, sectionId }: { assignmentId: string; sectionId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(
      where('assignmentId', '==', assignmentId),
      where('sectionId', '==', sectionId)
    )
  )

  return modelListStream(repository, q, RoomStateRubricResultDetail)
}
