import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty, type FirestoreCatalog } from '../firestore/Catalog/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class Catalog extends ObservableModelWithDecorators<FirestoreCatalog> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreCatalog>
  ) {
    super(repository, doc)

    makeObservable(this)
  }
}
