import z from 'zod'
import {
  nullSafeBoolean,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'
import { PromotionAppliesTo } from './types'

const promotionAppliesToSchema = z.nativeEnum(PromotionAppliesTo)

// - `appliesTo` ENUM number
//     - SECTION
//     - ASSIGNMENT
//     - ROOM_STATE
// - `consumable` boolean
// - `consumableUnit` string
// - `numberFieldLabels` string array
// - `stringFieldLabels` string array
export const schema = z.object({
  appliesTo: z.preprocess(
    (val) => val ?? PromotionAppliesTo.section,
    promotionAppliesToSchema
  ),
  consumable: nullSafeBoolean(false),
  consumableUnit: nullSafeString(''),
  collisionCode: nullSafeString(''),
  numberFieldLabels: nullSafeStringArray([]),
  stringFieldLabels: nullSafeStringArray([]),
})

export const writeSchema = schema.extend({})

export const empty = Object.freeze(
  schema.parse({
    appliesTo: PromotionAppliesTo.section,
    consumable: false,
    collisionCode: '',
    numberFieldLabels: [],
    stringFieldLabels: [],
  })
)

export type FirestorePromotion = z.infer<typeof schema>
