import { z } from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeString,
} from '../shared/schema'

export const teachingPlanModuleSlideDeckSchema = z.object({
  catalogId: nullSafeString(''),
  teachingPlanId: nullSafeString(''),
  moduleId: nullSafeString(''),
  slideDeckId: nullSafeString(''),
  slideDeckTypeId: nullSafeString(''),
  slideDeckOrder: nullSafeOptionalNumber(0),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const emptyTeachingPlanModuleSlideDeck =
  teachingPlanModuleSlideDeckSchema.parse({
    catalogId: '',
    teachingPlanId: '',
    moduleId: '',
    slideDeckId: '',
    slideDeckTypeId: '',
    slideDeckOrder: 0,
  })

export type FirestoreTeachingPlanModuleSlideDeck = z.infer<
  typeof teachingPlanModuleSlideDeckSchema
>
