import { computed, makeObservable, observable } from 'mobx'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { fetchFeaturedSlideDecks } from '../firestore/SlideDeck'
import {
  buildEmptySlideDeckMaterialCollection,
  fetchFeaturedSlideDeckMaterials,
} from '../firestore/SlideDeckMaterial'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SlideDeck } from '../models/SlideDeck'
import type { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { Cubit } from './core'

export class LoginCubit extends Cubit {
  repository: FirebaseRepository

  slideDecks: StaticModelCollection<SlideDeck>
  slideDeckMaterials = observable.map<
    string,
    StaticModelCollection<SlideDeckMaterial>
  >()

  slideDecksInitialized = false

  constructor(repository: FirebaseRepository) {
    super()
    this.repository = repository

    this.slideDecks = SlideDeck.emptyCollection(repository)

    makeObservable(this)
  }

  @computed
  get slideDecksWithMaterials() {
    const array: {
      slideDeck: SlideDeck
      material: SlideDeckMaterial
    }[] = []

    for (const slideDeck of this.slideDecks.models) {
      const materials = this.slideDeckMaterials.get(slideDeck.id)
      if (!materials || !materials.length) continue
      array.push({ slideDeck, material: materials.models[0] })
    }

    return array
  }

  // only run once
  async initSlideDecks() {
    if (this.slideDecksInitialized) return
    this.slideDecksInitialized = true

    const newSlideDecks = await fetchFeaturedSlideDecks(this.repository)

    // randomized array of featured slide decks with name and image
    // We only run it once, because we want that set to stay the same
    // for the duration of the visit on the page
    const filtered = newSlideDecks
      .filter((slideDeck) => {
        return slideDeck.data.slideDeckImageURL && slideDeck.data.slideDeckName
      })
      .sort(() => Math.random() - 0.5)
      .slice(0, 5)

    this.slideDecks.replaceModels(filtered)

    const promises: Promise<void>[] = []
    for (const slideDeck of filtered) {
      promises.push(this.addSlideDeckMaterials(slideDeck.id))
    }

    await Promise.all(promises)
  }

  async addSlideDeckMaterials(slideDeckId: string) {
    const collection = buildEmptySlideDeckMaterialCollection(this.repository)
    this.slideDeckMaterials.set(slideDeckId, collection)

    const slideDeckMaterials = await fetchFeaturedSlideDeckMaterials(
      this.repository,
      { slideDeckId }
    )

    collection.replaceModels(slideDeckMaterials)
  }
}
