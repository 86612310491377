import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
} from 'firebase/firestore'
import { doc, onSnapshot } from 'firebase/firestore'
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
  toJS,
} from 'mobx'
import { assert, createUniqueId, getErrorMessage } from './utils'
import { getDocWithError } from './fetch'

interface Options {
  debug?: boolean
  serverTimestamps?: 'estimate' | 'previous' | 'none'
}

export interface MobxDocument<T> {
  id: string
  data: T
  ref: DocumentReference
  fromCache: boolean
  hasPendingWrites: boolean
}

function isDocumentReference(source: SourceType): source is DocumentReference {
  return (source as DocumentReference).type === 'document'
}

function isCollectionReference(
  source: SourceType
): source is CollectionReference {
  return (source as CollectionReference).type === 'collection'
}

function getPathFromCollectionRef(collectionRef?: CollectionReference) {
  return collectionRef ? `${collectionRef.path}/__no_document_id` : undefined
}

export type SourceType = DocumentReference | CollectionReference

export class ObservableDocument<T extends DocumentData> {
  _data?: T | null = null
  isLoading = false

  protected debugId = createUniqueId()
  documentRef?: DocumentReference<T>
  protected collectionRef?: CollectionReference
  protected isDebugEnabled = false

  protected readyPromise?: Promise<T | undefined>
  protected readyResolveFn?: (data?: T) => void
  protected onSnapshotUnsubscribeFn?: () => void
  protected observedCount = 0
  protected firedInitialFetch = false
  protected sourcePath?: string
  protected listenerSourcePath?: string

  protected onErrorCallback?: (err: Error) => void
  protected onDataCallback?: (data: T) => void

  protected options: Options

  public fromCache = false
  public hasPendingWrites = false

  constructor(source?: SourceType, options?: Options) {
    this.options = options || {}
    if (options) {
      this.isDebugEnabled = options.debug || false
    }

    /**
     * By placing the Mobx initialization after calling changeLoadingState we
     * prevent having to make that protected method an action.
     */
    makeObservable(this, {
      _data: observable,
      isLoading: observable,
      isLoaded: computed,
      data: computed,
      document: computed,
      attachTo: action,
      hasData: computed,
      fromCache: observable,
      hasPendingWrites: observable,
      documentRef: false,
    })

    this.initializeReadyPromise()

    if (!source) {
      // do nothing?
    } else if (isCollectionReference(source)) {
      this.collectionRef = source
      this.sourcePath = source.path
      this.logDebug('Constructor from collection reference')
    } else if (isDocumentReference(source)) {
      this.documentRef = source as DocumentReference<T>
      this.collectionRef = source.parent
      this.sourcePath = source.path
      this.logDebug('Constructor from document reference')
      /**
       * In this case we have data to wait on from the start. So initialize the
       * promise and resolve function.
       */
      this.changeLoadingState(true)
    }

    onBecomeObserved(this, '_data', () => this.resumeUpdates())
    onBecomeUnobserved(this, '_data', () => this.suspendUpdates())

    onBecomeObserved(this, 'isLoading', () => this.resumeUpdates())
    onBecomeUnobserved(this, 'isLoading', () => this.suspendUpdates())
  }

  get id(): string {
    return this.documentRef ? this.documentRef.id : '__no_id'
  }

  attachTo(documentId?: string) {
    this.changeSourceViaId(documentId)

    /**
     * Return "this" so we can chain ready() when needed.
     */
    return this
  }

  get isLoaded(): boolean {
    return !this.isLoading
  }

  get data(): T {
    assert(this._data, 'No data available')
    return toJS(this._data)
  }

  get ref() {
    assert(this.documentRef, 'No document available')
    return this.documentRef
  }

  get document(): MobxDocument<T> {
    assert(this.documentRef && this._data, 'No document available')

    return {
      id: this.documentRef.id,
      data: toJS(this._data),
      ref: this.documentRef,
      fromCache: this.fromCache,
      hasPendingWrites: this.hasPendingWrites,
    }
  }

  onError(cb: (err: Error) => void) {
    this.onErrorCallback = cb
    return this
  }

  onData(cb: (data: T) => void) {
    this.onDataCallback = cb
    return this
  }

  protected get isObserved(): boolean {
    return this.observedCount > 0
  }

  get path(): string | undefined {
    return this.documentRef ? this.documentRef.path : undefined
  }

  ready(): Promise<T | undefined> {
    const isListening = !!this.onSnapshotUnsubscribeFn

    if (!isListening && this.documentRef) {
      /**
       * If the client is calling ready() but document is not being observed /
       * no listeners are set up, we treat ready() as a one time fetch request,
       * so data is available after awaiting the promise.
       */
      this.logDebug('Ready requested without listeners => fetch')
      this.fetchInitialData()
    } else {
      this.logDebug('Ready requested with active listeners')
    }

    assert(this.readyPromise, 'Missing ready promise')

    return this.readyPromise
  }

  get hasData(): boolean {
    return typeof this._data !== 'undefined' && this._data !== null
  }

  protected changeReady(isReady: boolean) {
    this.logDebug(`Change ready ${isReady}`)

    if (isReady) {
      const readyResolve = this.readyResolveFn
      assert(readyResolve, 'Missing ready resolve function')

      readyResolve(this.hasData ? this.data : undefined)

      /**
       * After the first promise has been resolved we want subsequent calls to
       * ready() to immediately return with the available data. Ready is only
       * meant to be used for initial data fetching
       */
      this.readyPromise = Promise.resolve(this.hasData ? this.data : undefined)
    }
  }

  protected initializeReadyPromise() {
    this.logDebug('Initialize new ready promise')
    this.readyPromise = new Promise((resolve) => {
      this.readyResolveFn = resolve
    })
  }

  protected fetchInitialData() {
    if (this.firedInitialFetch || !this.documentRef) {
      this.logDebug('Ignore fetch initial data')
      return
    }

    this.logDebug('Fetch initial data')

    /**
     * Pass the promise from the snapshot get to the handler function, which
     * will resolve the ready promise just like the snapshot passed in from the
     * normal listener.
     */
    getDocWithError(this.documentRef)
      .then((snapshot) => this.handleSnapshot(snapshot))
      .catch((err) =>
        this.handleError(
          new Error(`Fetch initial data failed: ${getErrorMessage(err)}`)
        )
      )

    this.firedInitialFetch = true
  }

  protected resumeUpdates() {
    this.observedCount += 1

    this.logDebug(`Resume. Observed count: ${this.observedCount}`)

    if (this.observedCount === 1) {
      this.logDebug('Becoming observed')
      this.updateListeners(true)
    }
  }

  protected suspendUpdates() {
    this.observedCount -= 1

    this.logDebug(`Suspend. Observed count: ${this.observedCount}`)

    if (this.observedCount === 0) {
      this.logDebug('Becoming un-observed')
      this.updateListeners(false)
    }
  }

  protected handleSnapshot(snapshot: DocumentSnapshot) {
    try {
      const data = snapshot.data({
        serverTimestamps: this.options.serverTimestamps,
      }) as T | undefined

      this.logDebug('Handle snapshot data:', data)

      runInAction(() => {
        this._data = data
        this.fromCache = snapshot.metadata.fromCache
        this.hasPendingWrites = snapshot.metadata.hasPendingWrites

        /**
         * We only need to call back if data exists. This function needs to fire
         * before the loading/ready state is set, so that one document can depend
         * on data from another. For example `isSomethingLoading = a.isLoading ||
         * b.isLoading` would not work if a has isLoading false before b is able
         * to access the data via the callback.
         */
        if (data && typeof this.onDataCallback === 'function') {
          this.onDataCallback(data)
        }

        this.changeLoadingState(false)
      })
    } catch (err) {
      console.error('error', err)
    }
  }

  /**
   * If there is an error handler callback we use that, otherwise we throw.
   */
  protected handleError(err: Error) {
    if (typeof this.onErrorCallback === 'function') {
      this.onErrorCallback(err)
    } else {
      this.logError(err)
      throw err
    }
  }

  protected changeSourceViaId(documentId?: string) {
    if (this.id === documentId) {
      return
    }

    if (documentId && !this.collectionRef) {
      this.handleError(
        new Error(
          `Can not change source via id if there is no known collection reference`
        )
      )
      return
    }

    const newRef =
      documentId && this.collectionRef
        ? doc(this.collectionRef, documentId)
        : undefined

    const newPath = newRef
      ? newRef.path
      : getPathFromCollectionRef(this.collectionRef)

    this.logDebug(`Change source via id to ${newPath}`)
    this.documentRef = newRef as DocumentReference<T> | undefined
    this.sourcePath = newPath
    this.firedInitialFetch = false

    const hasSource = !!newRef

    this.initializeReadyPromise()

    this._data = undefined

    if (!hasSource) {
      this.changeLoadingState(false)

      if (this.isObserved) {
        this.logDebug('Change document -> clear listeners')
        this.updateListeners(false)
      }
    } else {
      this.changeLoadingState(true)

      if (this.isObserved) {
        this.logDebug('Change document -> update listeners')
        this.updateListeners(true)
      }
    }
  }

  protected logDebug(...args: unknown[]) {
    if (this.isDebugEnabled) {
      if (!this.documentRef) {
        // eslint-disable-next-line no-console
        console.log(
          `${this.debugId} (${getPathFromCollectionRef(this.collectionRef)})`,
          ...args
        )
      } else {
        // eslint-disable-next-line no-console
        console.log(`${this.debugId} (${this.documentRef.path})`, ...args)
      }
    }
  }

  protected logError(err: unknown) {
    if (!this.documentRef) {
      console.error(
        `${this.debugId} (${getPathFromCollectionRef(
          this.collectionRef
        )}): ${getErrorMessage(err)}`
      )
    } else {
      console.error(
        `${this.debugId} (${this.documentRef.path}): ${getErrorMessage(err)}`
      )
    }
  }

  protected updateListeners(shouldListen: boolean) {
    const isListening = !!this.onSnapshotUnsubscribeFn

    if (
      shouldListen &&
      isListening &&
      this.sourcePath === this.listenerSourcePath
    ) {
      return
    }

    if (isListening) {
      this.logDebug('Unsubscribe listeners')

      this.onSnapshotUnsubscribeFn && this.onSnapshotUnsubscribeFn()
      this.onSnapshotUnsubscribeFn = undefined
      this.listenerSourcePath = undefined
    }

    if (shouldListen) {
      if (!this.documentRef) {
        return
      }

      this.logDebug('Subscribe listeners')

      try {
        this.onSnapshotUnsubscribeFn = onSnapshot(
          this.documentRef,
          (snapshot) => this.handleSnapshot(snapshot),
          (err) => this.handleError(err)
        )
      } catch (err) {
        throw new Error(
          `Failed to subscribe with onSnapshot: ${getErrorMessage(err)}`
        )
      }

      this.listenerSourcePath = this.sourcePath
    }
  }

  changeLoadingState(isLoading: boolean) {
    runInAction(() => {
      this.logDebug(`Change loading state: ${isLoading}`)
      this.changeReady(!isLoading)
      this.isLoading = isLoading
    })
  }
}

export class MockObservableDocument<
  T extends DocumentData,
> extends ObservableDocument<T> {
  constructor(mockData: T) {
    super()
    this._data = mockData
  }

  get id(): string {
    return this.documentRef ? this.documentRef.id : '__no_id'
  }

  ready(): Promise<T> {
    return Promise.resolve(this.data)
  }
}
