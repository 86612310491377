import z from 'zod'
import {
  nullSafeNumber,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

// this is the dart SettingsProcessingVideo definition
// required String caption, default: ''
// required double endTime, default: 0
// required String slideDeckId, default: ''
// required String slideId, default: ''
// required double startTime, default: 0
// int? updatedAt,
export const schema = z.object({
  caption: nullSafeString(''),
  endTime: nullSafeNumber(0),
  slideDeckId: nullSafeString(''),
  slideId: nullSafeString(''),
  startTime: nullSafeNumber(0),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    caption: '',
    endTime: 0,
    slideDeckId: '',
    slideId: '',
    startTime: 0,
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreSlideCaption extends z.infer<typeof schema> {}
