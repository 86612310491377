import { makeObservable } from 'mobx'

import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { collection, doc } from 'firebase/firestore'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  getDocWithError,
  setDocWithError,
  updateDocWithError,
} from '../firestore-mobx/fetch'

export class DesignSystemErrorCubit extends Cubit {
  repository: FirebaseRepository

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)
    this.repository = repository
  }

  runFailingGetDocs = async () => {
    const ref = collection(this.repository.firestore, 'nonexistent')
    return await getDocsWithError(ref, 'FailingGetDocsError')
  }

  runFailingGetDoc = async () => {
    const ref = collection(this.repository.firestore, 'nonexistent')
    const docRef = doc(ref, 'nonexistent')
    return await getDocWithError(docRef, 'FailingGetDocError')
  }

  runFailingAddDoc = async () => {
    const ref = collection(this.repository.firestore, 'nonexistent')
    return await addDocWithError(ref, { foo: 'bar' }, 'FailingAddDocError')
  }

  runFailingUpdateDoc = async () => {
    const ref = collection(this.repository.firestore, 'nonexistent')
    const docRef = doc(ref, 'nonexistent')
    return await updateDocWithError(
      docRef,
      { foo: 'bar' },
      'FailingUpdateDocEror'
    )
  }

  runFailingSetDoc = async () => {
    const ref = collection(this.repository.firestore, 'nonexistent')
    const docRef = doc(ref, 'nonexistent')
    return await setDocWithError(
      docRef,
      { foo: 'bar' },
      {
        errorName: 'FailingSetDocError',
      }
    )
  }

  runFailingDeleteDoc = async () => {
    const ref = collection(this.repository.firestore, 'nonexistent')
    const docRef = doc(ref, 'nonexistent')
    return await deleteDocWithError(docRef, 'FailingDeleteDocError')
  }
}
