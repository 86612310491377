import {
  collection,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'

import { type FirestoreOrganizationCatalog, schema } from './schema'
import { type FirebaseRepository } from '../../models/FirebaseRepository'
import { modelListStream } from '../../firestore-mobx/stream'
import { OrganizationCatalog } from '../../models/OrganizationCatalog'

const converter: FirestoreDataConverter<FirestoreOrganizationCatalog> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getOrganizationCatalogs = (
  repository: FirebaseRepository,
  { organizationId }: { organizationId: string }
) => {
  const colRef = collection(
    repository.firestore,
    'organization',
    organizationId,
    'organization_catalog'
  ).withConverter(converter)
  return modelListStream(repository, colRef, OrganizationCatalog)
}
