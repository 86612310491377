import type { FirebaseRepository } from '../../models/FirebaseRepository'
import {
  collection,
  doc,
  type DocumentData,
  type DocumentReference,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'

import { modelItemStream } from '../../firestore-mobx/stream'
import { TeachingPlanAggregation } from '../../models/TeachingPlanAggregation'
import type { FirestoreTeachingPlanAggregation } from './schema'
import { teachingPlanAggregationSchema } from './schema'

const converter: FirestoreDataConverter<FirestoreTeachingPlanAggregation> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return teachingPlanAggregationSchema.parse(data)
  },
}

const getDocRef = (
  firestore: Firestore,
  params: {
    catalogId: string
    teachingPlanId: string
  }
): DocumentReference<FirestoreTeachingPlanAggregation, DocumentData> => {
  return doc(
    collection(
      firestore,
      'catalog',
      params.catalogId,
      'teaching_plan',
      params.teachingPlanId,
      'teaching_plan_aggregation'
    ).withConverter(converter),
    params.teachingPlanId
  )
}

export const getTeachingPlanAggregation = (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  const docRef = getDocRef(repository.firestore, params)

  return modelItemStream(repository, docRef, TeachingPlanAggregation)
}
