import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

// this is the dart definition
// required String slideDeckId,
// required String userId,
// int? updatedAt,
//
// no default values
export const schema = z.object({
  slideDeckId: nullSafeString(''),
  userId: nullSafeString(''),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    slideDeckId: '',
    userId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreUserProfilePurchase = z.infer<typeof schema>
