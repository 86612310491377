import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeString,
} from '../shared/schema'
import { RubricType } from '../../types'

const rubricTypeSchema = z.nativeEnum(RubricType)

// dart definition
// required String rubric, default: ''
// required String rubricDescription, default: ''
// required String rubricPrompt, default: ''
// required RubricType rubricType, default: RubricType.blooms
// String? rubricLabel0, default: 'Did Not Discuss'
// String? rubricLabel1, default: 'Discussed'
// String? slideId,
// int? updatedAt,
export const schema = z.object({
  rubric: nullSafeString(''),
  rubricDescription: nullSafeString(''),
  rubricPrompt: nullSafeString(''),
  // failing on fake AI
  rubricType: z.preprocess((val) => val ?? RubricType.blooms, rubricTypeSchema),
  rubricLabel0: nullSafeOptionalString('Did Not Discuss'),
  rubricLabel1: nullSafeOptionalString('Discussed'),
  slideId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    rubric: '',
    rubricDescription: '',
    rubricPrompt: '',
    rubricType: RubricType.blooms,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreSlideRubric = z.infer<typeof schema>
