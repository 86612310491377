export class DisposerTracker {
  private disposers: (() => void)[] = []

  add(disposer: () => void) {
    this.disposers.push(disposer)
  }

  dispose() {
    this.disposers.forEach((disposer) => disposer())
    this.disposers = []
  }
}
