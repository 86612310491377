import { type SlideDeck } from '../models/SlideDeck'
import { SlideDeckGroup } from '../stores/SlideDeckGroup'

export const groupSlideDecksByTypeAndSortByUpdateDate = (
  slideDecks: SlideDeck[]
) => {
  const slideDecksByType: Record<string, SlideDeckGroup> = {}
  slideDecks.sort((a, b) => {
    const aUpdatedAt = a.data.updatedAt || new Date(0)
    const bUpdatedAt = b.data.updatedAt || new Date(0)
    return bUpdatedAt.getTime() - aUpdatedAt.getTime()
  })
  slideDecks.forEach((slideDeck) => {
    const {
      data: { slideDeckTypeId, slideDeckParentId },
    } = slideDeck
    if (!slideDecksByType[slideDeckTypeId])
      slideDecksByType[slideDeckTypeId] = new SlideDeckGroup(
        slideDeckParentId || '',
        true
      )
    slideDecksByType[slideDeckTypeId].addSlideDeck(slideDeck)
  })
  return slideDecksByType
}
