import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeNumber,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

export const schema = z.object({
  rubricId: nullSafeString(''),
  rubricTestName: nullSafeString(''),
  rubricTestScore: nullSafeNumber(0),
  rubricTestScript: nullSafeString(''),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  archivedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    rubricId: '',
    rubricTestName: '',
    rubricTestScript: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreSlideDeckRubricTest = z.infer<typeof schema>
