import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { BreakoutUser } from '../models/BreakoutUser'
import { SectionAssignment } from '../models/SectionAssignment'
import { Section } from '../models/Section'
import { RoomState, RoomStateStatus } from '../models/RoomState'
import { SlideDeck } from '../models/SlideDeck'
import { LibraryObjectState, UserProfileRole } from '../types'
import { computed, makeObservable } from 'mobx'
import { DateTime } from 'luxon'
import { assert } from '../firestore-mobx/utils'
import { SectionState } from '../firestore/Section'
import { type SlideQuestion } from '../models/SlideQuestion'
import { type RoomStateAnswer } from '../models/RoomStateAnswer'
import { type StudentLibraryCubit } from '../cubits/StudentLibraryCubit'

export class ValidLibraryObject {
  repository: FirebaseRepository
  user: BreakoutUser
  private cubitData:
    | {
        cubit: StudentLibraryCubit
        sectionId: string
        assignmentId: string
        roomId?: string //not always a room associated with an assignment
      }
    | {
        section: Section
        assignment: SectionAssignment
        roomState: RoomState
        slideDeck: SlideDeck
        quizData?: {
          slideQuestions: SlideQuestion[]
          userAnswers: RoomStateAnswer[]
        }
      }

  constructor(
    params:
      | {
          repository: FirebaseRepository
          section: Section
          assignment: SectionAssignment
          roomState: RoomState
          slideDeck: SlideDeck
          quizData?: {
            slideQuestions: SlideQuestion[]
            userAnswers: RoomStateAnswer[]
          }
        }
      | {
          cubit: StudentLibraryCubit
          assignmentId: string
          sectionId: string
          roomId?: string
        }
  ) {
    makeObservable(this)
    const user =
      'cubit' in params
        ? params.cubit.repository.breakoutUser
        : params.repository.breakoutUser
    const repository =
      'cubit' in params ? params.cubit.repository : params.repository
    assert(user, 'User must be logged in')
    this.user = user
    this.repository = repository
    this.cubitData = params
  }

  @computed
  get libraryObjectId() {
    // if there is a roomState, use the roomState id
    if (this.roomState.id) return this.assignment.id + '-' + this.roomState.id
    return this.assignment.id
  }

  @computed
  get slideDeckId() {
    return this.slideDeck.id
  }

  @computed
  get slideDeckName() {
    return this.slideDeck.data.slideDeckName
  }

  @computed
  get slideDeckPrice() {
    return this.slideDeck.data.slideDeckPrice
  }

  @computed
  get slideDeckImageURL() {
    return this.slideDeck.data.slideDeckImageURL
  }

  @computed
  get slideDeckTeaser() {
    return this.slideDeck.data.slideDeckTeaser
  }

  @computed
  get requiresPayment() {
    if (
      this.assignment.isExpired ||
      this.section.data.sectionState === SectionState.completed
    )
      return false
    return !this.hasAccessToSlideDeckContents
  }

  @computed
  get hasAccessToSlideDeckContents() {
    const matchingTokensCount = this.user.tokens
      .filter((tokenModel) => {
        const token = tokenModel.data
        return (
          (token.consumed === true &&
            token.sectionId !== null &&
            token.assignmentId !== null &&
            token.sectionId === this.section.id &&
            token.assignmentId === this.assignment.id) ||
          (this.roomState.isNotEmpty &&
            token.roomId !== null &&
            token.roomId === this.roomState.id)
        )
      })
      .reduce((previousValue, element) => {
        return previousValue + element.data.tokenQuantity
      }, 0)

    const havePurchaseRecord = this.user.purchases.some(
      (purchase) => purchase.id === this.slideDeck.id
    )

    const result =
      (matchingTokensCount < this.slideDeck.data.slideDeckPrice ||
        !havePurchaseRecord) &&
      this.user.role === UserProfileRole.student &&
      !this.slideDeck.data.slideDeckFree

    return !result
  }

  @computed
  get roomStateStatus() {
    return this.roomState.getRoomStateStatus(
      this.assignment.expiresAt,
      this.section.data.sectionState,
      this.slideDeck.data.slideDeckSlideCount
    )
  }

  @computed
  get beforeSession() {
    return (
      this.libraryObjectState === LibraryObjectState.enrolled ||
      this.libraryObjectState === LibraryObjectState.invited ||
      this.libraryObjectState === LibraryObjectState.mustSchedule ||
      this.libraryObjectState === LibraryObjectState.pending ||
      this.libraryObjectState === LibraryObjectState.scheduled
    )
  }

  @computed
  get afterSession() {
    return (
      this.libraryObjectState === LibraryObjectState.completed ||
      this.libraryObjectState === LibraryObjectState.expired
    )
  }

  @computed
  get groupLeader() {
    return this.roomState.groupLeaderUserIds.includes(this.user.uid)
  }

  @computed
  get instructor() {
    return this.section.instructor
  }

  @computed
  get libraryObjectState(): LibraryObjectState {
    // if no group leaders, anyone can schedule and then become group leader
    const canSchedule =
      this.groupLeader || this.roomState.groupLeaderUserIds.length === 0
    const expired =
      this.section.data.sectionState === SectionState.completed ||
      this.assignment.isExpired

    // NO ROOM
    if (!this.roomState.id) {
      if (expired) return LibraryObjectState.expired
      if (this.requiresPayment) return LibraryObjectState.invited
      return LibraryObjectState.enrolled
    }

    // WITH ROOM (did not start)
    if (!this.roomState.didStart) {
      if (expired) return LibraryObjectState.expired
      if (this.requiresPayment) return LibraryObjectState.invited
      if (this.roomState.isScheduled) {
        if (this.canStart) return LibraryObjectState.readyToStart
        return LibraryObjectState.scheduled
      }
      if (canSchedule) return LibraryObjectState.mustSchedule
      return LibraryObjectState.pending
    }

    // WITH ROOM (did start)
    if (this.roomStateStatus === RoomStateStatus.completed)
      return LibraryObjectState.completed
    if (this.roomStateStatus === RoomStateStatus.suspended) {
      if (!this.roomState.canStart) return LibraryObjectState.scheduled
      return LibraryObjectState.suspended
    }
    if (expired) return LibraryObjectState.expired
    if (this.requiresPayment) return LibraryObjectState.invited
    return LibraryObjectState.live
  }

  @computed
  get currentUserCanStartRoom() {
    return (
      this.libraryObjectState === LibraryObjectState.readyToStart ||
      (this.libraryObjectState === LibraryObjectState.live &&
        !this.roomState.didStart)
    )
  }

  @computed
  get currentUserCanJoinRoom() {
    return (
      this.libraryObjectState === LibraryObjectState.live &&
      this.roomState.didStart
    )
  }

  @computed
  get roomCanStartTime() {
    const scheduledAt = this.roomState.data.scheduledAt
    if (!scheduledAt) return null
    return DateTime.fromJSDate(scheduledAt).minus({ minutes: 10 })
  }

  @computed
  get canStart() {
    const scheduledAt = this.roomState.data.scheduledAt
    const roomCanStartTime = this.roomCanStartTime
    if (!scheduledAt || !roomCanStartTime) return false

    return this.repository.currentMinute > roomCanStartTime
  }

  @computed
  // Returns 10 minutes before meeting is scheduled to start, ex: 9/12/24 12:50pm PT
  get canJoinTimeFormatted() {
    const roomCanStartTime = this.roomCanStartTime
    if (!roomCanStartTime) return ''

    return roomCanStartTime.toFormat('M/d/yy hh:mma ZZZZ')
  }

  @computed
  get quizDataRequiredForActionState() {
    // if not completed but is scheduled, we must check if we need to complete the quiz
    return (
      this.roomState.isNotEmpty &&
      this.roomState.isScheduled &&
      !(this.roomStateStatus === RoomStateStatus.completed) &&
      !this.assignment.isExpired &&
      !this.requiresPayment
    )
  }

  get actionState(): ValidLibraryObjectActionState {
    if (this.assignment.assignedAtIsInFuture)
      return ValidLibraryObjectActionState.availableOn

    if (
      this.quizDataRequiredForActionState &&
      this.libraryObjectState !== LibraryObjectState.completed &&
      this.libraryObjectState !== LibraryObjectState.expired &&
      this.libraryObjectState !== LibraryObjectState.invited
    ) {
      if (this.quizData === undefined) {
        return ValidLibraryObjectActionState.loading
      }
      // check if we have answers for all the pre-work quiz questions
      const preMeetingQuizQuestions = this.quizData.slideQuestions.filter(
        (q) => q.isPreMeetingQuestion
      )
      if (
        !preMeetingQuizQuestions.every((question) =>
          this.quizData!.userAnswers.some(
            (answer) => answer.data.slideQuestionId === question.id
          )
        )
      ) {
        return ValidLibraryObjectActionState.completeQuiz
      }
    }

    switch (this.libraryObjectState) {
      case LibraryObjectState.live:
        // if room is not active (but live), we can start it
        if (this.currentUserCanStartRoom)
          return ValidLibraryObjectActionState.startSession
        return ValidLibraryObjectActionState.joinSession
      case LibraryObjectState.readyToStart: {
        if (this.currentUserCanStartRoom)
          return ValidLibraryObjectActionState.startSession
        return ValidLibraryObjectActionState.sessionScheduled
      }
      case LibraryObjectState.invited:
        return ValidLibraryObjectActionState.enroll
      case LibraryObjectState.enrolled:
        return ValidLibraryObjectActionState.joinGroup
      case LibraryObjectState.mustSchedule:
        return ValidLibraryObjectActionState.scheduleSession
      case LibraryObjectState.scheduled:
        return ValidLibraryObjectActionState.sessionScheduled
      case LibraryObjectState.pending:
        return ValidLibraryObjectActionState.pending
      case LibraryObjectState.completed:
        return ValidLibraryObjectActionState.viewResults
      case LibraryObjectState.expired:
        return ValidLibraryObjectActionState.experienceExpired
      case LibraryObjectState.suspended:
        return ValidLibraryObjectActionState.suspended
    }
  }

  @computed
  get quizData() {
    if ('cubit' in this.cubitData) {
      const { cubit } = this.cubitData
      const {
        id,
        data: { slideDeckId },
      } = this.roomState
      const slideQuestions = cubit.slideQuestionsBySlideDeckId.get(slideDeckId)
      const userAnswers = cubit.userAnswersByRoomId.get(id)
      const questionsReady = slideQuestions && slideQuestions.isLoaded
      const answersReady = userAnswers && userAnswers.isLoaded
      if (!questionsReady || !answersReady) return undefined
      return {
        slideQuestions: slideQuestions.models,
        userAnswers: userAnswers.models,
      }
    }
    return this.cubitData.quizData
  }

  @computed
  get section() {
    if ('cubit' in this.cubitData) {
      const { cubit } = this.cubitData
      const section = cubit.sections.models.find(
        (s) => s.id === this.assignment.data.sectionId
      )
      return section || Section.empty(this.repository)
    }
    return this.cubitData.section
  }

  @computed
  get assignment() {
    if ('cubit' in this.cubitData) {
      const { cubit, assignmentId } = this.cubitData
      const assignment = cubit.allSectionAssignments.find(
        (a) => a.id === assignmentId
      )
      return assignment || SectionAssignment.empty(this.repository)
    }
    return this.cubitData.assignment
  }

  @computed
  get roomState() {
    if ('cubit' in this.cubitData) {
      const { cubit, roomId } = this.cubitData
      const roomState = cubit.roomStates.models.find((r) => r.id === roomId)
      return roomState || RoomState.empty(this.repository)
    }
    return this.cubitData.roomState
  }

  @computed
  get slideDeck() {
    if ('cubit' in this.cubitData) {
      const { cubit } = this.cubitData
      const slideDeck = cubit.slideDecksById.get(
        this.assignment.data.slideDeckId
      )
      return slideDeck || SlideDeck.empty(this.repository)
    }
    return this.cubitData.slideDeck
  }
}

export enum ValidLibraryObjectActionState {
  enroll = 'enroll',
  availableOn = 'availableOn',
  joinGroup = 'joinGroup',
  scheduleSession = 'scheduleSession',
  completeQuiz = 'completeQuiz',
  sessionScheduled = 'sessionScheduled',
  pending = 'pending',
  joinSession = 'joinSession',
  startSession = 'startSession',
  viewResults = 'viewResults',
  experienceExpired = 'experienceExpired',
  loading = 'loading',
  suspended = 'suspended',
}
