import { action, computed, makeObservable, observable } from 'mobx'

export class ManagedDialogState {
  @observable dialogActive: boolean = false
  @observable dialogDebounced: boolean = false
  debounceTimeout: NodeJS.Timeout | undefined

  constructor() {
    makeObservable(this)
  }

  //make a getter for canPop
  @computed
  get canShow() {
    return !this.dialogActive && !this.dialogDebounced
  }

  _clearDebounce = () => {
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = undefined
  }

  @action
  mounted() {
    this.dialogActive = true
    this._clearDebounce()
  }

  @action
  unmounted() {
    this._clearDebounce()
    this.dialogDebounced = true
    this.dialogActive = false
    this.debounceTimeout = setTimeout(() => {
      this.undebounce()
    }, 30 * 1000)
  }

  @action
  undebounce() {
    this.dialogDebounced = false
  }
}
