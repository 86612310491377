export enum TeachingPlanStatus {
  deleted = -1,
  draft = 0,
  published = 1,
}

export enum TeachingPlanUploadType {
  jpg = 0,
  mp4 = 1,
}

export type TeachingPlanDetailsPayload = {
  teachingPlanName: string
  teachingPlanDescription: string
  teachingPlanImageURL: string
  teachingPlanVideoURL: string
}
