import type {
  CollectionReference,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  orderBy,
  query,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { ObservableModelCollection } from '../../firestore-mobx/model'
import { type ObservableModel } from '../../firestore-mobx/model'
import {
  collectionSnapshots,
  convertDocumentSnapshotToModel,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreSectionAssignmentSlides } from './schema'
import { schema, writeSchema } from './schema'
import type { StreamInterface } from 'tricklejs/dist/types'
import { SectionAssignmentSlides } from '../../models/SectionAssignmentSlides'

export interface SectionAssignmentSlidesObservableModel
  extends ObservableModel<FirestoreSectionAssignmentSlides> {}

export interface SectionAssignmentObservableModelCollection
  extends ObservableModelCollection<
    SectionAssignmentSlidesObservableModel,
    FirestoreSectionAssignmentSlides
  > {}

const converter: FirestoreDataConverter<FirestoreSectionAssignmentSlides> = {
  toFirestore: (
    data: PartialWithFieldValue<FirestoreSectionAssignmentSlides>
  ) => {
    writeSchema.partial().parse(data)
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const empty = Object.freeze(schema.parse({}))

const getColRef = (
  firestore: Firestore,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
): CollectionReference<FirestoreSectionAssignmentSlides> => {
  return collection(
    firestore,
    'section',
    sectionId,
    'assignment',
    assignmentId,
    'slides'
  ).withConverter(converter)
}

export const getSectionAssignmentSlidesStream = (
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
): StreamInterface<SectionAssignmentSlides[]> => {
  const ref = getColRef(repository.firestore, params)

  const orderByUpdatedAt = orderBy('updatedAt', 'desc')
  const slidesQuery = query(ref, orderByUpdatedAt)

  return collectionSnapshots(slidesQuery).map((snapshot) => {
    const models = snapshot.docs
      // keep only documents with a url
      .filter((doc) => doc.data().url)
      .map((doc) => {
        return convertDocumentSnapshotToModel(
          repository,
          doc,
          SectionAssignmentSlides
        )
      })
    return models
  })
}
