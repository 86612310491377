import z from 'zod'
import { nullSafeOptionalString } from '../shared/schema'

// this is the dart SettingsProcessingVideo definition
// int? videoDuration,
// String? videoError,
// String? videoURL,
// int? updatedAt,
export const schema = z.object({
  // These 2 are in the production db
  videoURL: nullSafeOptionalString(),
  storageURL: nullSafeOptionalString(),
  // these 3 are not
  // videoDuration: z.number().optional(),
  // videoError: z.string().optional(),
  // updatedAt: z.number().optional(),
})

export const empty = Object.freeze(
  schema.parse({
    videoURL: '',
    storageURL: '',
  })
)

export type FirestoreSettingsProcessingVideo = z.infer<typeof schema>
