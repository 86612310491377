import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  QuerySnapshot,
} from 'firebase/firestore'

import type { IObservableArray } from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx'
import type { Options, QueryCreatorFn } from './collection'
import { ObservableCollection } from './collection'
import type { MobxDocument } from './document'
import { ObservableDocument } from './document'
import type { FirebaseRepository } from '../models/FirebaseRepository'

export class ObservableModel<T extends DocumentData> {
  firestore: Firestore
  id: string
  doc: MobxDocument<T> | ObservableDocument<T>
  repository: FirebaseRepository

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<T> | ObservableDocument<T>
  ) {
    this.id = doc.id || ''
    this.repository = repository
    this.firestore = repository.firestore
    this.doc = doc
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  replaceDoc(doc: MobxDocument<T> | ObservableDocument<T>) {
    this.doc = doc
  }

  public get data(): T {
    return this.doc.data
  }

  async ready() {
    if (this.doc instanceof ObservableDocument) {
      return this.doc.ready()
    } else {
      return Promise.resolve(this.data)
    }
  }

  // This is not optimal, but it allows for a consistent API
  replaceModel(model: ObservableModel<T>) {
    this.doc = model.doc
  }
}

export class ObservableModelWithDecorators<
  T extends DocumentData,
> extends ObservableModel<T> {
  @observable declare doc: MobxDocument<T> | ObservableDocument<T>
  @observable declare id: string

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<T> | ObservableDocument<T>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get isLoading(): boolean {
    if (this.doc instanceof ObservableDocument) {
      return this.doc.isLoading
    } else {
      return false
    }
  }

  @computed
  get isLoaded(): boolean {
    return !this.isLoading
  }

  @computed
  get hasData(): boolean {
    if (this.doc instanceof ObservableDocument) {
      return this.doc.hasData
    } else {
      return this.doc.data !== undefined && this.doc.data !== null
    }
  }

  @computed
  public get data(): T {
    return this.doc.data
  }

  public get dataToJs(): T {
    return toJS(this.doc.data)
  }

  @computed
  protected get unsafeData(): T | undefined {
    if (this.doc instanceof ObservableDocument) {
      return this.doc.hasData ? this.doc.data : undefined
    } else {
      return this.doc.data
    }
  }

  get isEmpty(): boolean {
    return !this.id
  }

  get isNotEmpty(): boolean {
    return !this.isEmpty
  }

  async ready() {
    if (this.doc instanceof ObservableDocument) {
      return this.doc.ready()
    } else {
      return Promise.resolve(this.data)
    }
  }

  @action
  replaceDoc(doc: MobxDocument<T> | ObservableDocument<T>) {
    this.doc = doc
    this.id = doc.id
    this.changeLoadingState(false)
    return this
  }

  // This is not optimal, but it allows for a consistent API
  @action
  replaceModel(model: ObservableModel<T>) {
    this.doc = model.doc
    this.id = model.id
    this.changeLoadingState(false)
    return this
  }

  // This is not optimal, but it allows for a consistent API
  @action
  replaceData(newData: T) {
    const newDoc = {
      ...this.doc,
      data: newData,
    } as MobxDocument<T>
    this.doc = newDoc
    this.changeLoadingState(false)
    return this
  }

  @action
  testUpdate(data: Partial<T> & { id?: string }) {
    // HERE BE DRAGONS
    // this is only for testing, NEVER use this in production
    // Mutability is hard, mobx is being very smart about it and we should not fight it
    // This is a way to modify data in tests, but if you try this in production, you will have a bad time
    if (process.env.MODE === 'test') {
      const newData = { ...this.data, ...data }
      const newDoc = {
        ...this.doc,
        data: newData,
      } as MobxDocument<T>
      if (data.id) {
        this.id = data.id
        newDoc.id = data.id
      }
      this.doc = newDoc
      this.changeLoadingState(false)
    }
    return this
  }

  changeLoadingState(loading: boolean) {
    if (this.doc instanceof ObservableDocument) {
      this.doc.changeLoadingState(loading)
    }
  }
}

export type ObservableModelClass<
  T extends DocumentData,
  M = ObservableModel<T>,
> = new (repository: FirebaseRepository, doc: MobxDocument<T>) => M

export class ObservableModelCollection<
  M extends ObservableModel<T>,
  T extends DocumentData,
> extends ObservableCollection<T> {
  modelClass: ObservableModelClass<T, M>
  repository: FirebaseRepository
  _models: IObservableArray<M> = observable.array<M>([], { deep: false })
  empty: T

  memoizationKey?: string

  constructor({
    ref,
    repository,
    model,
    query,
    options,
    empty,
  }: {
    ref?: CollectionReference<DocumentData>
    repository: FirebaseRepository
    model: ObservableModelClass<T, M>
    query?: QueryCreatorFn
    options?: Options
    empty: T
  }) {
    super(ref, query, options)
    this.modelClass = model
    this.repository = repository
    this.empty = { ...empty }

    makeObservable(this, {
      models: computed,
      length: computed,
      replaceDocs: action,
      replaceModels: action,
    })
  }

  buildEmptyModel(id?: string): M {
    const model = new ObservableModelDocument({
      repository: this.repository,
      model: this.modelClass,
      empty: this.empty,
    }).model
    if (id) model.id = id
    return model
  }

  get models(): M[] {
    if (this.isLoading) return []

    return this._models.concat()
  }

  protected handleSnapshot(snapshot: QuerySnapshot) {
    this.logDebug(
      `handleSnapshot, ${Date.now()} docs.length: ${snapshot.docs.length}`
    )

    runInAction(() => {
      const newDocs = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ref: doc.ref,
          fromCache: doc.metadata.fromCache,
          hasPendingWrites: doc.metadata.hasPendingWrites,
          data: doc.data({
            serverTimestamps: this.options.serverTimestamps,
          }) as T,
        } as MobxDocument<T>
      })

      this.replaceDocs(newDocs)

      this.changeLoadingState(false)
    })
  }

  replaceDocs(docs: MobxDocument<T>[]) {
    this._documents = docs

    // This is an alternative implementation that has no optimization
    // but is much, much simpler
    const newModels = docs.map((doc) => {
      return this.buildModel(doc)
    })

    this._models.replace(newModels)
  }

  replaceModels(models: M[]) {
    // This is an alternative implementation that has no optimization
    // but is much, much simpler
    this._models.replace(models)

    const docs = this._models.map((model) => model.doc)

    this._documents = docs

    this.changeLoadingState(false)
  }

  get length(): number {
    return this.documents.length
  }

  buildModel(doc: MobxDocument<T>): M {
    return new this.modelClass(this.repository, doc) as M
  }
}

export class FetchedModelCollection<
  M extends ObservableModel<T>,
  T extends DocumentData,
> extends ObservableModelCollection<M, T> {
  updateListeners(shouldFireInitialFetch: boolean) {
    if (shouldFireInitialFetch && !this.firedInitialFetch) {
      this.fetchInitialData()
    }
  }
}

export class ObservableModelDocument<
  M extends ObservableModel<T>,
  T extends DocumentData = DocumentData,
> extends ObservableDocument<T> {
  empty: T
  modelClass: ObservableModelClass<T, M>
  repository: FirebaseRepository
  modelObject?: M

  constructor({
    ref,
    model,
    repository,
    options,
    empty,
  }: {
    ref?: DocumentReference<T>
    model: ObservableModelClass<T, M>
    repository: FirebaseRepository
    options?: Options
    empty: T
  }) {
    super(ref, options)
    this.modelClass = model
    this.repository = repository
    this.empty = empty
  }

  get model(): M {
    if (this.modelObject) return this.modelObject

    // ObservableDocument satisfies the Document interface
    this.modelObject = this.buildModel(this)

    return this.modelObject
  }

  get data(): T {
    // hasData gu
    return this.hasData ? (this._data as T) : this.empty
  }

  buildModel(doc: MobxDocument<T>): M {
    return new this.modelClass(this.repository, doc) as M
  }
}

// Static means it does no automatic updates
export class StaticModelDocument<
  M extends ObservableModel<T>,
  T extends DocumentData = DocumentData,
> extends ObservableModelDocument<M, T> {
  _id: string = ''
  constructor(params: {
    id?: string
    ref?: DocumentReference<T>
    model: ObservableModelClass<T, M>
    repository: FirebaseRepository
    options?: Options
    empty: T
    startLoaded?: boolean
  }) {
    super(params)
    if (params.id) this._id = params.id
    if (!params.startLoaded) {
      this.changeLoadingState(true)
    }
  }

  get id(): string {
    return this.isLoaded ? this.document.id : this._id
  }

  protected resumeUpdates(): void {}

  protected suspendUpdates(): void {}

  protected updateListeners(): void {}
}

// Static means it does no automatic updates
export class StaticModelCollection<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  M extends ObservableModel<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> extends ObservableModelCollection<M, any> {
  constructor(params: {
    ref?: CollectionReference<DocumentData>
    repository: FirebaseRepository
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: ObservableModelClass<any, M>
    query?: QueryCreatorFn
    options?: Options
    startLoaded?: boolean
    empty: DocumentData
  }) {
    super(params)
    if (!params.startLoaded) {
      this.changeLoadingState(true)
    }
  }

  get length(): number {
    return this.documents.length
  }

  protected resumeUpdates(): void {}

  protected suspendUpdates(): void {}

  protected updateListeners(): void {}
}

export function emptyModel<
  M extends ObservableModel<T>,
  T extends DocumentData = DocumentData,
>(
  repository: FirebaseRepository,
  modelClass: ObservableModelClass<T, M>,
  empty: T,
  id?: string
): M {
  return new StaticModelDocument<M, T>({
    repository,
    model: modelClass,
    empty: empty,
    id: id,
  }).model
}

export function emptyCollection<
  M extends ObservableModel<T>,
  T extends DocumentData = DocumentData,
>(
  repository: FirebaseRepository,
  modelClass: ObservableModelClass<T, M>,
  empty: T
): StaticModelCollection<M> {
  return new StaticModelCollection<M>({
    repository,
    model: modelClass,
    empty: empty,
  })
}
