import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
} from '../shared/schema'

// this is the dart definition
// required double cameraOffTime,
// required double durationPresent,
// required String roomId,
// required double totalTalkTime,
// required double totalTime,
// required String userId,
// required double userTalkTime,
// String assignmentId,
// String sectionId,
// int? updatedAt,
export const schema = z.object({
  // failing on fake AI
  cameraOffTime: nullSafeOptionalNumber(),
  // failing on fake AI
  durationPresent: nullSafeOptionalNumber(),
  roomId: z.string(),
  // failing on fake AI
  totalTalkTime: nullSafeOptionalNumber(),
  // failing on fake AI
  totalTime: nullSafeOptionalNumber(),
  userId: z.string(),
  // failing on fake AI
  userTalkTime: nullSafeOptionalNumber(),
  assignmentId: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    cameraOffTime: 0,
    durationPresent: 0,
    roomId: '',
    totalTalkTime: 0,
    totalTime: 0,
    userId: '',
    userTalkTime: 0,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateEngagementInferred = z.infer<typeof schema>
export type FirestoreRoomStateEngagement = FirestoreRoomStateEngagementInferred
export type FirestoreRoomStateEngagementWrite = z.infer<typeof schema>
