import type { RoomStateAnswer } from '../models/RoomStateAnswer'
import { type SlideQuestion } from '../models/SlideQuestion'
import { SlideQuestionType } from '../models/SlideQuestionType'

/** returns a float between 0 and 1 for sorting questions
 * returns a binary (0 , 1) for all other questions types
 */
export function getQuizAnswerScore({
  answer,
  question,
}: {
  answer: RoomStateAnswer
  question: SlideQuestion
}): number {
  let sum = 0
  // switch on question type
  switch (question.questionType) {
    case SlideQuestionType.sorting:
      // map over the answers and calculate the average
      // score for the sorting question
      // by getting the absolute difference between the
      // value in each answerList[] value and the index
      // answerList may be null so handle that case by excluding
      // it from the calculation
      if (answer.data.answerList) {
        sum = answer.data.answerList
          .map((value, index) => Math.abs(index - value))
          .reduce((value, element) => value + element)
      }

      return (
        (question.getWorstSortingScore - (sum ?? 0)) /
        question.getWorstSortingScore
      )
    case SlideQuestionType.multipleChoice:
      if (answer.data.answer === question.data.correct) {
        return 1
      } else {
        return 0
      }
    case SlideQuestionType.poll:
    case SlideQuestionType.customPoll:
    case SlideQuestionType.title:
      return 0
  }
  return 0
}

export function getAggregateQuizAnswerScore({
  answers,
  question,
}: {
  answers: RoomStateAnswer[]
  question: SlideQuestion
}) {
  switch (question.questionType) {
    case SlideQuestionType.sorting: {
      const sums = answers
        .map((answer) => answer.data.answerList)
        .filter((list) => Array.isArray(list))
        .map((answerList) =>
          answerList!
            .map((entry, index) => Math.abs(index - entry))
            .reduce((value, element) => value + element)
        )
      const average =
        sums.length === 0
          ? 0
          : sums.reduce((value, element) => value + element) / sums.length
      return average
    }
    case SlideQuestionType.multipleChoice: {
      const correct = answers
        .map((answer) => getQuizAnswerScore({ answer, question }))
        .reduce((value, element) => value + element)
      return correct / answers.length
    }
    default:
      return 0
  }
}

/// create histogram from the getMultipleChoicePerformance
/// in 20% wide buckets, return a map of bucket and count
/// always return a map of 5 buckets whose key is `n %`
/// put the results in the correct bucket
export function getSortingQuizHistogram({
  answers,
  question,
}: {
  answers: Array<RoomStateAnswer>
  question: SlideQuestion
}) {
  const histogram: Map<string, number> = new Map()
  const scores: Map<string, number> = new Map()
  const resultsInBuckets: Map<string, Array<RoomStateAnswer>> = new Map()

  // create a list of buckets starting from the question's worst score
  // and decreasing to zero. Buckets should be equal in size
  // and start with 0 members
  const bucketSize = question.getWorstSortingScore / 10
  for (let n = 0; n < 10; n++) {
    histogram.set(
      (question.getWorstSortingScore - bucketSize * n).toFixed(0),
      0
    )
  }
  // for each answer, calculate the score and put it in the correct bucket
  for (const answer of answers) {
    const score =
      question.getWorstSortingScore -
      getQuizAnswerScore({ answer, question }) * question.getWorstSortingScore
    scores.set(answer.data.userId, score)

    // loop over entries in reverse order and find the first bucket
    // that the score is less than or equal to bucket name + bucketSize
    const entries = Array.from(histogram.entries()).reverse()
    for (const entry of entries) {
      const [key, value] = entry
      const bucket = Number(key)
      if (score <= bucket + bucketSize) {
        histogram.set(key, value + 1)
        resultsInBuckets.set(key, [
          ...(resultsInBuckets.get(key) ?? []),
          answer,
        ])
        break
      }
    }
  }
  // sort all the results in the buckets by score descending
  for (const entry of resultsInBuckets.entries()) {
    const [entryKey, entryValue] = entry
    const valueSorted = entryValue.sort((a, b) => {
      const aScore = scores.get(a.data.userId) ?? 0
      const bScore = scores.get(b.data.userId) ?? 0
      return bScore - aScore
    })
    resultsInBuckets.set(entryKey, valueSorted)
  }

  return { histogram, resultsInBuckets }
}

export const getCoefficientOfVariation = (data: number[]) => {
  if (!data.length) return 0
  const n = data.length
  const mean = data.reduce((a, b) => a + b) / n
  if (mean === 0) return NaN
  const standardDeviation = Math.sqrt(
    data.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  )
  return standardDeviation / mean
}
