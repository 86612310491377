import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreOrganizationInstructor,
} from '../firestore/OrganizationInstructor/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class OrganizationInstructor extends ObservableModelWithDecorators<FirestoreOrganizationInstructor> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreOrganizationInstructor>
  ) {
    super(repository, doc)

    makeObservable(this)
  }
}
