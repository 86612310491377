import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreSlideDeckAuthor } from '../firestore/SlideDeckAuthor/schema'
import { emptySlideDeckAuthor } from '../firestore/SlideDeckAuthor/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class SlideDeckAuthor extends ObservableModelWithDecorators<FirestoreSlideDeckAuthor> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, emptySlideDeckAuthor)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, emptySlideDeckAuthor)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideDeckAuthor>
  ) {
    super(repository, doc)
  }
}
