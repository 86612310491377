import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RubricType } from '../models/SlideRubric'
import { RoomStateEngagement } from '../models/RoomStateEngagement'
import { RoomStateRubricResult } from '../models/RoomStateRubricResult'
import { type MobxDocument } from '../types'
import { doc } from 'firebase/firestore'
import { mockRoomStateEngagement } from './mockMeetingResultsData/MockRoomStateEngagement'
import { mockRoomStateRubricResultDetailsForRoom } from './mockMeetingResultsData/MockRoomStateRubricResultDetailsForRoom'
import { MeetingResultsCubit } from './MeetingResultsCubit'
import { getSlideRubrics } from '../firestore/SlideRubric'
import { RoomStateRubricResultDetail } from '../models/RoomStateRubricResultDetail'
import type { MeetingCubit } from './MeetingCubit'

export class PreviewMeetingResultsCubit extends MeetingResultsCubit {
  constructor(
    repository: FirebaseRepository,
    params: {
      roomStateId: string
      slideDeckId: string
      meetingCubit: MeetingCubit
      userId: string
      inDialog: boolean
    }
  ) {
    super(repository, params)
    this.userId = '1'
  }

  /**
   * load data from mocks
   */
  initialize(): void {
    const emptyRef = doc(this.repository.firestore, 'empty/empty')
    const mobxDocFromData = <T>(data: T): MobxDocument<T> => ({
      id: '',
      data,
      ref: emptyRef,
      fromCache: false,
      hasPendingWrites: false,
    })

    // mock data
    const roomStateEngagement = mockRoomStateEngagement.map(
      (d) => new RoomStateEngagement(this.repository, mobxDocFromData(d))
    )
    const rubricResultDetails = mockRoomStateRubricResultDetailsForRoom.map(
      (d) =>
        new RoomStateRubricResultDetail(this.repository, mobxDocFromData(d))
    )
    this._roomStateEngagement.replaceModels(roomStateEngagement)
    this.rubricResultDetails.replaceModels(rubricResultDetails)

    // get slide rubric stream
    // when rubrics come in update the rubrics and make some mock rubric results
    this.addStream(
      getSlideRubrics(this.repository, { slideDeckId: this.slideDeckId }),
      (rubrics) => {
        this.rubrics.replaceModels(rubrics)
        this.generateMockRubricResultsForCurrentUser()
        this.removeStream('slideRubrics')
      },
      { name: 'slideRubrics' }
    )
  }

  get aiStatus() {
    return 'success' as const
  }

  get sessionResults() {
    const engagementDataArr = this._roomStateEngagement.models
    const talkTimeMinutes =
      (this.userEngagementData.data.userTalkTime || 0) / 60
    const groupAverageTalkTimeMinutes =
      engagementDataArr.reduce(
        (acc, { data: { userTalkTime } }) => acc + (userTalkTime || 0),
        0
      ) /
      60 /
      engagementDataArr.length
    const cameraOnPercent = (this.userEngagementData.cameraOnRatio || 0) * 100

    const groupCameraOnRatios = engagementDataArr
      .map((d) => d.cameraOnRatio)
      .filter((r) => r !== null) as number[]

    const groupAverageCameraOnPercent =
      (groupCameraOnRatios.reduce((total, ratio) => total + ratio, 0) /
        groupCameraOnRatios.length) *
      100

    return {
      isLoaded: true,
      talkTimeMinutes,
      groupAverageTalkTimeMinutes,
      cameraOnPercent,
      groupAverageCameraOnPercent,
      pollAgreement: 0.85,
      quizPerformance: 0.95,
      userQuizPerformance: 0.75,
    }
  }

  private generateMockRubricResultsForCurrentUser() {
    const rubrics = this.rubrics.models
    const scoreSetForPassFail = new Set([0, 1])
    const scoreSetForBlooms = new Set([0, 1, 2, 3, 4, 5, 6])
    const passFailDefaultSize = 2
    const bloomsDefaultSize = 7
    const getRandomScore = (rubricType: RubricType) => {
      // get random element from set
      const isPassFail = rubricType === RubricType.passFail
      const setToUse = isPassFail ? scoreSetForPassFail : scoreSetForBlooms
      const defaultSizeToUse = isPassFail
        ? passFailDefaultSize
        : bloomsDefaultSize
      const setHasElements = setToUse.size > 0
      // if set has no elements return random 0 or 1
      if (!setHasElements) return Math.floor(Math.random() * defaultSizeToUse)
      const randomIndex = Math.floor(Math.random() * setToUse.size)
      const randomElement = Array.from(setToUse)[randomIndex]
      setToUse.delete(randomElement)
      return randomElement
    }

    function getRandomUniqueSentences(outputArrayLength: number) {
      const loremIpsumSentences = [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        'Curabitur pretium tincidunt lacus, sit amet lacinia mi efficitur sed.',
        'Nullam fringilla libero at erat consectetur, non egestas velit vestibulum.',
        'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
        'Phasellus vulputate justo ac magna cursus, id pretium libero tempor.',
        'Integer sollicitudin lacus in turpis condimentum, id convallis velit faucibus.',
        'Aliquam erat volutpat. Proin sodales orci eget tortor fermentum, at aliquet sapien vehicula.',
        'Vestibulum euismod nunc at nisl consectetur, nec malesuada justo dictum.',
        'Morbi sit amet magna non dolor faucibus aliquet in at elit.',
        'Vivamus in odio vitae urna pharetra gravida in eu orci.',
        'Maecenas et magna eu nisi volutpat sollicitudin sed id neque.',
        'Suspendisse potenti. Etiam ut velit vel elit ultrices gravida.',
        'In hac habitasse platea dictumst. Cras ac diam ut nisi gravida porttitor.',
        'Nam congue urna sed justo bibendum, non dictum dolor tincidunt.',
        'Donec auctor velit nec urna pulvinar, vel placerat risus scelerisque.',
        'Fusce ut eros eget arcu auctor malesuada nec et eros.',
      ]

      const min = 0
      const max = loremIpsumSentences.length - 1
      if (max - min + 1 < outputArrayLength) {
        throw new Error('Not enough unique numbers in the specified range.')
      }
      const uniqueNumbers: Set<number> = new Set()

      while (uniqueNumbers.size < outputArrayLength) {
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
        uniqueNumbers.add(randomNum)
      }
      return Array.from(uniqueNumbers).map(
        (index) => loremIpsumSentences[index]
      )
    }

    const rubricIds = rubrics.map((rubric) => rubric.id)

    this.rubricResultDetails.models.forEach((rubricResultDetail) => {
      const { data } = rubricResultDetail
      rubricResultDetail.replaceData({
        ...data,
        rubricId: rubricIds[Math.floor(Math.random() * rubricIds.length)],
        content: getRandomUniqueSentences(1)[0],
      })
    })

    const results = rubrics.map((rubric) => {
      // generate array of 1 - 5 random unique numbers between 0 and 9
      const randomArguments = getRandomUniqueSentences(
        Math.floor(Math.random() * 5) + 1
      )
      // generate array of 4 - 6 random unique numbers between 0 and 9
      const randomJustification = getRandomUniqueSentences(
        Math.floor(Math.random() * 3) + 4
      ).join(' ')
      const randomScore = getRandomScore(rubric.rubricType || RubricType.blooms)
      const rubricResult = new RoomStateRubricResult(this.repository, {
        id: rubric.id,
        data: {
          userId: this.userId,
          score: randomScore,
          arguments: randomArguments,
          justification: randomJustification,
          roomId: '',
        },
        ref: doc(this.repository.firestore, 'empty/empty'),
        fromCache: false,
        hasPendingWrites: false,
      })
      return rubricResult
    })
    const resultsCol = RoomStateRubricResult.emptyCollection(this.repository)
    resultsCol.replaceModels(results)
    this._rubricResultsByUser.set(this.userId, resultsCol)
  }
}
