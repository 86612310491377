import type { MobxDocument } from '../firestore-mobx'
import {
  empty,
  type FirestoreSlideQuestion,
} from '../firestore/SlideQuestion/schema'
import type { FirebaseRepository } from './FirebaseRepository'
import { computed, makeObservable } from 'mobx'
import { SlideQuestionType, slideQuestionType } from './SlideQuestionType'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'

// This should be called SlideDeckQuestion, but Dart has it as SlideQuestion,
// so for compatibility reasons we keep it as SlideQuestion
export class SlideQuestion extends ObservableModelWithDecorators<FirestoreSlideQuestion> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideQuestion>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get questionType(): SlideQuestionType | undefined {
    if (!this.hasData) return undefined
    return slideQuestionType(this.data.questionType)
  }

  @computed
  get worstPossibleScore() {
    // if not sorting, it's 0
    if (this.data.questionType !== 4) return 0

    // sorting
    const n = this.data.answers.length
    const halfN = Math.floor(n / 2)
    return 2 * (halfN * (halfN + 1) - halfN * (1 - (n % 2)))
  }

  get getWorstSortingScore() {
    // sorting
    const n = this.data.answers.length
    const halfN = Math.floor(n / 2)
    return 2 * (halfN * (halfN + 1) - halfN * (1 - (n % 2)))
  }

  @computed
  get hasSlideId() {
    return Boolean(this.data.slideId)
  }

  @computed
  get hasGroupSlideId() {
    return Boolean(this.data.groupSlideId)
  }

  @computed
  get isQuiz(): boolean {
    return (
      this.questionType === SlideQuestionType.multipleChoice ||
      this.questionType === SlideQuestionType.sorting
    )
  }

  @computed
  get isSorting(): boolean {
    return this.questionType === SlideQuestionType.sorting
  }

  @computed
  get questionWithoutNumbersAtBeginning(): string {
    const pattern = /^\d+\.\s/
    return this.data.question.replace(pattern, '')
  }

  /// test element is of type poll
  get isPoll(): boolean {
    return (
      this.questionType === SlideQuestionType.poll ||
      this.questionType === SlideQuestionType.customPoll
    )
  }

  get isPreMeetingQuestion(): boolean {
    return (
      this.data.slideId === 'pre-meeting-quiz' &&
      (this.data.questionType === SlideQuestionType.sorting ||
        this.data.questionType === SlideQuestionType.multipleChoice)
    )
  }
}
