import type {
  FieldValue,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  CollectionReference,
  collection,
  doc,
  orderBy,
  query,
  serverTimestamp,
  writeBatch,
  type Firestore,
} from 'firebase/firestore'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { TeachingPlanModule } from '../../models/TeachingPlanModule'
import {
  deleteTeachingPlanModuleSlideDeck,
  fetchTeachingPlanModuleSlideDecks,
} from '../TeachingPlanModuleSlideDeck'
import type { FirestoreTeachingPlanModule } from './schema'
import { teachingPlanModuleSchema } from './schema'

const converter: FirestoreDataConverter<FirestoreTeachingPlanModule> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return teachingPlanModuleSchema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: {
    catalogId: string
    teachingPlanId: string
  }
): CollectionReference<FirestoreTeachingPlanModule> => {
  return collection(
    firestore,
    'catalog',
    params.catalogId,
    'teaching_plan',
    params.teachingPlanId,
    'module'
  ).withConverter(converter)
}

export const getTeachingPlanModules = (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId: params.catalogId,
    teachingPlanId: params.teachingPlanId,
  })
  const q = query(colRef, orderBy('moduleOrder'))
  return modelListStream(repository, q, TeachingPlanModule)
}

export const getTeachingPlanModule = (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId: params.catalogId,
    teachingPlanId: params.teachingPlanId,
  })
  const docRef = doc(colRef, params.moduleId)
  return modelItemStream(repository, docRef, TeachingPlanModule)
}

export type ModuleFieldsForUpload = Omit<
  FirestoreTeachingPlanModule,
  'updatedAt' | 'moduleId' | 'moduleOrder' | 'catalogId' | 'teachingPlanId'
>

export const fetchTeachingPlanModules = async (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId: params.catalogId,
    teachingPlanId: params.teachingPlanId,
  })
  const q = query(colRef, orderBy('moduleOrder'))
  const docs = await getDocsWithError(q, 'FetchTeachingPlanModulesError')
  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, TeachingPlanModule)
  })
}

export const saveTeachingPlanModule = async (
  repository: FirebaseRepository,
  {
    moduleFields,
    catalogId,
    teachingPlanId,
    moduleId,
  }: {
    catalogId: string
    teachingPlanId: string
    moduleFields: ModuleFieldsForUpload
    moduleId?: string
  }
) => {
  interface WriteInterface
    extends Omit<FirestoreTeachingPlanModule, 'updatedAt'> {
    updatedAt: FieldValue
  }

  const dataToWrite: WriteInterface = {
    ...moduleFields,
    catalogId,
    teachingPlanId,
    updatedAt: serverTimestamp(),
  }

  if (!moduleId) {
    dataToWrite.moduleOrder = 999
  }

  const ref = moduleId
    ? doc(
        getColRef(repository.firestore, { catalogId, teachingPlanId }),
        moduleId
      )
    : getColRef(repository.firestore, { catalogId, teachingPlanId })

  if (ref instanceof CollectionReference) {
    const doc = await addDocWithError(
      ref,
      dataToWrite,
      'CreateTeachingPlanModuleError'
    )
    reorderModules(repository, {
      catalogId,
      teachingPlanId,
    })
    return doc.id
  }

  //@ts-expect-error - todo: not sure why the type system hates this
  await updateDocWithError(ref, dataToWrite, 'UpdateTeachingPlanModuleError')
  return moduleId
}

export const deleteTeachingPlanModule = async (
  repository: FirebaseRepository,
  {
    moduleId,
    catalogId,
    teachingPlanId,
  }: {
    moduleId: string
    catalogId: string
    teachingPlanId: string
  }
) => {
  const deletePromises = []

  const slideDecks = await fetchTeachingPlanModuleSlideDecks(repository, {
    catalogId,
    teachingPlanId,
    moduleId,
  })

  slideDecks.forEach((slideDeck) => {
    deletePromises.push(
      deleteTeachingPlanModuleSlideDeck(repository, {
        catalogId,
        teachingPlanId,
        moduleId,
        slideDeckId: slideDeck.id,
      })
    )
  })

  deletePromises.push(
    deleteDocWithError(
      doc(
        getColRef(repository.firestore, { catalogId, teachingPlanId }),
        moduleId
      ),
      'DeleteTeachingPlanModuleError'
    )
  )

  await Promise.all(deletePromises)

  reorderModules(repository, {
    catalogId,
    teachingPlanId,
  })
}

const reorderModules = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId,
    teachingPlanId,
  })
  const q = query(colRef, orderBy('moduleOrder'))
  const docs = await getDocsWithError(q, 'FetchFoReorderModulesError')
  const batch = writeBatch(repository.firestore)
  docs.docs.forEach((doc, index) => {
    if (doc.data().moduleOrder === index) return
    batch.update(doc.ref, { moduleOrder: index })
  })
  await batch.commit()
}

/**
 * Sort the modules in the teaching_plan by the moduleOrder field and update
 * the moduleOrder field with the index of the author in the list so that
 * the moduleOrder is always correct and the modules are always sorted by
 * the moduleOrder field when the teaching_plan is loaded from Firestore.
 *
 * if **oldIndex** and **newIndex** are supplied then move element at **oldIndex** to **newIndex**
 */

export const sortTeachingPlanModules = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const moduleIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = moduleIds.splice(oldIndex, 1)[0]

    //insert at new index
    moduleIds.splice(newIndex, 0, targetId)
  }

  const batch = writeBatch(repository.firestore)
  moduleIds.forEach((moduleId, index) => {
    // get the author doc ref
    const moduleRef = doc(
      getColRef(repository.firestore, { catalogId, teachingPlanId }),
      moduleId
    )
    batch.update(moduleRef, { moduleOrder: index })
  })

  // perform the batch write
  await batch.commit()
}
