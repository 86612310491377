import {
  and,
  collection,
  doc,
  documentId,
  limit,
  query,
  serverTimestamp,
  startAfter,
  where,
  type CollectionReference,
  type DocumentData,
  type DocumentReference,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { StreamInterface } from 'tricklejs/dist/types'
import { ZodError } from 'zod'
import {
  collectionSnapshots,
  convertDocumentSnapshotToModel,
  modelItemStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { UserProfile } from '../../models/UserProfile'
import { unsupportedConverter } from '../shared/noops'
import type { FirestoreUserProfile } from './schema'
import type { UserProfileRole } from './types'
import { schema } from './schema'
import { partition, unique } from '../../util/arrays'
import {
  deleteDocWithError,
  getDocsWithError,
  setDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreUserProfile> = {
  toFirestore: unsupportedConverter,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    try {
      return schema.parse(data)
    } catch (e) {
      if (e instanceof ZodError) {
        console.error('Error parsing user profile', e.errors)
      }
      throw e
    }
  },
}

export const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreUserProfile, DocumentData> => {
  return collection(firestore, 'user_profile').withConverter(converter)
}

const docRef = (
  firestore: Firestore,
  userId: string
): DocumentReference<FirestoreUserProfile, DocumentData> => {
  return doc(getColRef(firestore), userId)
}

export const getUserProfile = (
  repository: FirebaseRepository,
  params: { userId: string }
): StreamInterface<UserProfile> => {
  const ref = docRef(repository.firestore, params.userId)

  return modelItemStream(repository, ref, UserProfile)
}

export const getTAInstructorIDs = (repository: FirebaseRepository) => {
  const profileInstructorsCollectionRef = collection(
    repository.firestore,
    'user_profile',
    repository.currentUser?.uid ?? '',
    'instructor'
  )
  return collectionSnapshots(profileInstructorsCollectionRef).map((snap) =>
    snap.docs.map((doc) => doc.id)
  )
}

export const fetchTAInstructorIDs = async (repository: FirebaseRepository) => {
  const profileInstructorsCollectionRef = collection(
    repository.firestore,
    'user_profile',
    repository.currentUser?.uid ?? '',
    'instructor'
  )
  const snap = await getDocsWithError(
    profileInstructorsCollectionRef,
    'FetchTAInstructorIdsError'
  )
  return snap.docs.map((doc) => doc.id)
}

export const fetchUsersForAdmin = async (
  repository: FirebaseRepository,
  {
    role,
  }: {
    role: UserProfileRole | UserProfileRole[]
  }
) => {
  const roleArr = Array.isArray(role) ? role : [role]
  const colRef = getColRef(repository.firestore)
  const q = query(
    colRef,
    where('role', 'in', roleArr)
    // orderBy('lastName'),
    // limit(10)
  )

  const snap = await getDocsWithError(q, 'FetchUsersForAdminError')

  return snap.docs.map((doc) =>
    convertDocumentSnapshotToModel(repository, doc, UserProfile)
  )
}

export async function fetchAllUserProfiles(
  repository: FirebaseRepository,
  role: UserProfileRole | UserProfileRole[]
) {
  const roleArr = Array.isArray(role) ? role : [role]
  const users: UserProfile[] = []

  const documentLimit = 100
  let lastDocument: QueryDocumentSnapshot | undefined

  let work = true
  while (work) {
    let queryRef = query(
      getColRef(repository.firestore),
      where('role', 'in', roleArr),
      limit(documentLimit)
    )

    if (lastDocument) {
      queryRef = query(queryRef, startAfter(lastDocument))
    }

    const querySnapshot = await getDocsWithError(
      queryRef,
      'FetchAllUserProfilesError'
    )
    const docs = querySnapshot.docs

    if (docs.length === 0) {
      work = false
    } else {
      const converted = docs.map((doc) => {
        return convertDocumentSnapshotToModel(repository, doc, UserProfile)
      })
      users.push(...converted)
      lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1]
    }
  }

  return users
}

export async function fetchAllUserProfilesByEmail(
  repository: FirebaseRepository,
  role: UserProfileRole | UserProfileRole[],
  emailSearch: string
) {
  const users: UserProfile[] = []
  const roleArr = Array.isArray(role) ? role : [role]

  const documentLimit = 100
  let lastDocument: QueryDocumentSnapshot | undefined

  let work = true
  while (work) {
    let queryRef = query(
      getColRef(repository.firestore),
      and(
        where('role', 'in', roleArr),
        where('emailAddress', '>=', emailSearch),
        where('emailAddress', '<', emailSearch + '\uf8ff')
      ),
      limit(documentLimit)
    )

    if (lastDocument) {
      queryRef = query(queryRef, startAfter(lastDocument))
    }

    const querySnapshot = await getDocsWithError(
      queryRef,
      'FetchAllUserProfilesError'
    )
    const docs = querySnapshot.docs

    if (docs.length === 0) {
      work = false
    } else {
      const converted = docs.map((doc) => {
        return convertDocumentSnapshotToModel(repository, doc, UserProfile)
      })
      users.push(...converted)
      lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1]
    }
  }

  return users
}

export const fetchUserProfiles = (
  repository: FirebaseRepository,
  params: { userIds: string[] }
): Promise<UserProfile[]> => {
  const ref = getColRef(repository.firestore)
  // filter out empty strings
  const userIds = unique(params.userIds).filter((id) => id)

  if (userIds.length === 0) return Promise.resolve([])

  const limit = 30
  const promises: Promise<UserProfile[]>[] = []

  if (userIds.length > limit) {
    const partitions = partition(userIds, limit)

    partitions.forEach((part) => {
      const promise = new Promise<UserProfile[]>((resolve) => {
        const q = query(ref, where(documentId(), 'in', part))
        getDocsWithError(q, 'FetchUserProfilesPartitionError').then(
          (snapshot) => {
            const converted = snapshot.docs.map((doc) => {
              return convertDocumentSnapshotToModel(
                repository,
                doc,
                UserProfile
              )
            })

            resolve(converted)
          }
        )
      })
      promises.push(promise)
    })
  } else {
    const q = query(ref, where(documentId(), 'in', userIds))
    const promise = getDocsWithError(q, 'FetchUserProfilesError').then(
      (snapshot) => {
        return snapshot.docs.map((doc) => {
          return convertDocumentSnapshotToModel(repository, doc, UserProfile)
        })
      }
    )
    promises.push(promise)
  }

  return Promise.all(promises).then((results) => {
    return results.flat()
  })
}

export const addCatalogToUserProfile = (
  repository: FirebaseRepository,
  params: { catalogId: string; userId: string; organizationId?: string }
): Promise<void> => {
  const catalogCollectionRef = collection(
    repository.firestore,
    'user_profile',
    params.userId,
    'catalogs'
  )
  const docRef = doc(catalogCollectionRef, params.catalogId)

  return setDocWithError(docRef, {
    catalogId: params.catalogId,
    userId: params.userId,
    createdAt: serverTimestamp(),
    organizationId: params.organizationId,
  })
}

export const removeCatalogFromUserProfile = (
  repository: FirebaseRepository,
  params: { catalogId: string; userId: string }
): Promise<void> => {
  const catalogCollectionRef = collection(
    repository.firestore,
    'user_profile',
    params.userId,
    'catalogs'
  )
  const docRef = doc(catalogCollectionRef, params.catalogId)

  return deleteDocWithError(docRef, 'RemoveCatalogFromUserProfileError')
}

export const updateEmail = (
  repository: FirebaseRepository,
  userId: string,
  email: string
): Promise<void> => {
  const ref = docRef(repository.firestore, userId)

  return updateDocWithError(ref, { emailAddress: email }, 'updateEmail')
}
