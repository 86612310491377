import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirebaseRepository } from './FirebaseRepository'
import { empty, type FirestoreInvitation } from '../firestore/Invitation/schema'

export class Invitation extends ObservableModelWithDecorators<FirestoreInvitation> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreInvitation>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get demoFullName() {
    if (!this.data.demoArguments) return ''

    return [
      this.data.demoArguments.firstName,
      this.data.demoArguments.lastName,
    ].join(' ')
  }

  get demoEmail() {
    if (!this.data.demoArguments) return ''

    return this.data.demoArguments.emailAddress
  }

  get demoRoomId() {
    if (!this.data.demoArguments) return undefined

    return this.data.demoArguments.roomId
  }
}
