export enum SlideQuestionType {
  /// Poll
  poll = 0,

  /// Multiple Choice
  multipleChoice = 1,

  /// title
  title = 2,

  /// Custom Poll
  customPoll = 3,

  /// sorting element
  sorting = 4,
}

// function the converts the JSON value to the SlideQuestionType
export function slideQuestionType(
  slideQuestionType: number
): SlideQuestionType {
  switch (slideQuestionType) {
    case 0:
      return SlideQuestionType.poll
    case 1:
      return SlideQuestionType.multipleChoice
    case 2:
      return SlideQuestionType.title
    case 3:
      return SlideQuestionType.customPoll
    case 4:
      return SlideQuestionType.sorting
    default:
      throw new Error('Unhandled SlideQuestionType')
  }
}

// Function to get JSON value equivalent
export function jsonValue(type: SlideQuestionType): number {
  switch (type) {
    case SlideQuestionType.poll:
      return 0
    case SlideQuestionType.multipleChoice:
      return 1
    case SlideQuestionType.title:
      return 2
    case SlideQuestionType.customPoll:
      return 3
    case SlideQuestionType.sorting:
      return 4
    default:
      throw new Error('Unhandled SlideQuestionType')
  }
}

// Function to check compatible slide types
export function compatibleSlideTypes(type: SlideQuestionType): string[] {
  switch (type) {
    case SlideQuestionType.poll:
    case SlideQuestionType.customPoll:
      return ['interactivePoll']
    case SlideQuestionType.multipleChoice:
    case SlideQuestionType.sorting:
      return ['interactiveQuiz', 'soloQuiz']
    case SlideQuestionType.title:
      return ['everyType'] // Assuming everyType symbolically represents all types.
  }
}

// Function to check group quiz compatibility
export function isGroupQuizCompatible(type: SlideQuestionType): boolean {
  return type === SlideQuestionType.sorting
}
