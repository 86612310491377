import z from 'zod'
import {
  firestoreTimestamp,
  firestoreWriteTimestamp,
  nullSafeString,
} from '../shared/schema'

// - `userId` string
// - `promotionId` string
// - `promotionName` string
// - `expirationDate` timestamp
// - `sectionId` string
// - `userPromotionRedemptionId` string
// - `updatedAt` timestamp
export const schema = z.object({
  userId: z.string(),
  promotionId: z.string(),
  promotionName: nullSafeString(''),
  expirationDate: firestoreTimestamp,
  sectionId: nullSafeString(''),
  userPromotionRedemptionId: nullSafeString(''),
  updatedAt: firestoreTimestamp,
})

export const writeSchema = schema.extend({
  expirationDate: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    userId: '',
    promotionId: '',
    promotionName: '',
    expirationDate: { seconds: 0, nanoseconds: 0 },
    sectionId: '',
    userPromotionRedemptionId: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSectionPromotion = z.infer<typeof schema>
export type FirestoreSectionPromotionWrite = z.infer<typeof writeSchema>
