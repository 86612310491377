import type { FirebaseRepository } from './FirebaseRepository'
import type { MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreUserPromotionRedemption,
} from '../firestore/UserPromotionRedemption/schema'

export class UserPromotionRedemption extends ObservableModelWithDecorators<FirestoreUserPromotionRedemption> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreUserPromotionRedemption>
  ) {
    super(repository, doc)
  }
}
