import { z } from 'zod'
import {
  nullSafeNumber,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

export const teachingPlanAggregationSchema = z.object({
  catalogId: nullSafeString(''),
  teachingPlanId: nullSafeString(''),
  moduleCount: nullSafeNumber(0),
  slideDeckCount: nullSafeNumber(0),

  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const emptyTeachingPlanAggregation = teachingPlanAggregationSchema.parse(
  {
    catalogId: '',
    teachingPlanId: '',
    moduleCount: 0,
    slideDeckCount: 0,
  }
)

export type FirestoreTeachingPlanAggregation = z.infer<
  typeof teachingPlanAggregationSchema
>
