import z from 'zod'
import { firestoreTimestamp, firestoreWriteTimestamp } from '../shared/schema'

export enum SectionRequestState {
  withdrawn = -1,
  pending = 0,
  approved = 1,
  rejected = 2,
}

export enum SectionRequestType {
  invoice = 0,
}

export const schema = z.object({
  organizationId: z.string(),
  requestedAt: firestoreTimestamp,
  sectionId: z.string(),
  sectionRequestAssignmentCount: z.number(),
  sectionRequestReason: z.string().optional(),
  sectionRequestState: z.nativeEnum(SectionRequestState),
  sectionRequestStudentCount: z.number(),
  sectionRequestType: z.nativeEnum(SectionRequestType),
  updatedAt: firestoreTimestamp,
  userId: z.string().optional(),
  updatedByUserId: z.string().optional(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
  requestedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    sectionId: '',
    slideDeckId: '',
    userId: '',
    organizationId: '',
    sectionRequestType: 0,
    sectionRequestState: 0,
    requestedAt: { seconds: 0, nanoseconds: 0 },
    updatedAt: { seconds: 0, nanoseconds: 0 },
    sectionRequestStudentCount: 0,
    sectionRequestAssignmentCount: 0,
  })
)

export type FirestoreSectionRequest = z.infer<typeof schema>
export type FirestoreSectionRequestWrite = z.infer<typeof writeSchema>
