import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import {
  getRoomStatesRunning,
  getRoomStatesScheduled,
} from '../firestore/RoomState'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RoomState } from '../models/RoomState'
import type { StaticModelCollection } from '../types'
import { observeRoom } from '../util/observe'
import { Cubit } from './core'
import { Section } from '../models/Section'
import { SlideDeck } from '../models/SlideDeck'
import { fetchSection } from '../firestore/Section'
import { fetchSlideDeck } from '../firestore/SlideDeck'
import { fetchSlideCount } from '../firestore/Slide'
import { PublicUser } from '../models/PublicUser'

export enum AdminDashboardTab {
  scheduled = 'scheduled',
  running = 'running',
}

export class DashboardItem {
  repository: FirebaseRepository
  roomState: RoomState
  section?: Section
  slideDeck?: SlideDeck
  slideCount?: number
  id: string

  constructor(
    repository: FirebaseRepository,
    roomState: RoomState,
    section?: Section,
    slideDeck?: SlideDeck,
    slideCount?: number
  ) {
    makeObservable(this)
    this.id = roomState.id
    this.repository = repository
    this.roomState = roomState
    this.section = section
    this.slideDeck = slideDeck
    this.slideCount = slideCount
  }

  @computed
  get instructor() {
    const instructorUserId = this.section?.data.instructorUserId
    return instructorUserId
      ? this.repository.userStore.getUser(instructorUserId)
      : PublicUser.empty(this.repository)
  }
}

export class AdminDashboardCubit extends Cubit {
  repository: FirebaseRepository

  runningRooms: StaticModelCollection<RoomState>
  scheduledRooms: StaticModelCollection<RoomState>
  sections = observable.map<string, Section>()
  slideDecks = observable.map<string, SlideDeck>()
  slideCounts = observable.map<string, number>()

  @observable
  tab: AdminDashboardTab = AdminDashboardTab.scheduled

  @observable
  filters = observable.array<string>([])

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)

    this.repository = repository
    this.runningRooms = RoomState.emptyCollection(repository)
    this.scheduledRooms = RoomState.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(getRoomStatesRunning(this.repository), (rooms) => {
      this.runningRooms.replaceModels(rooms)
      this.fetchDataForRooms()
    })
    this.addStream(getRoomStatesScheduled(this.repository), (rooms) => {
      this.scheduledRooms.replaceModels(rooms)
      this.fetchDataForRooms()
    })
  }

  @action
  fetchDataForRooms(): void {
    // use sets to avoid duplicate fetches
    const sectionIds = new Set<string>()
    const slideDeckIds = new Set<string>()
    const slideCountIds = new Set<string>()

    // iterate through all rooms and fetch data for sections and slide decks
    // add empties to mark that we are fetching and also return placeholders
    // in the view
    this.allRooms.forEach((roomState) => {
      const sectionId = roomState.data.sectionId
      if (sectionId && !this.sections.has(sectionId)) {
        this.sections.set(sectionId, Section.empty(this.repository))
        sectionIds.add(sectionId)
      }

      const slideDeckId = roomState.data.slideDeckId
      if (slideDeckId && !this.slideDecks.has(slideDeckId)) {
        this.slideDecks.set(slideDeckId, SlideDeck.empty(this.repository))
        slideDeckIds.add(slideDeckId)
      }

      if (!this.slideCounts.has(slideDeckId)) {
        slideCountIds.add(slideDeckId)
        this.slideCounts.set(slideDeckId, 0)
      }
    })

    sectionIds.forEach((sectionId) => {
      fetchSection(this.repository, { sectionId }).then((section) => {
        runInAction(() => {
          this.sections.set(sectionId, section)
        })
      })
    })

    slideDeckIds.forEach((slideDeckId) => {
      fetchSlideDeck(this.repository, { slideDeckId }).then((slideDeck) => {
        runInAction(() => {
          this.slideDecks.set(slideDeckId, slideDeck)
        })
      })
    })

    slideCountIds.forEach((slideDeckId) => {
      fetchSlideCount(this.repository, { slideDeckId }).then((count) => {
        runInAction(() => {
          this.slideCounts.set(slideDeckId, count)
        })
      })
    })
  }

  async observeRoom(roomState: RoomState) {
    return observeRoom(this.repository, roomState)
  }

  @computed
  get allRooms() {
    return [...this.runningRooms.models, ...this.scheduledRooms.models]
  }

  @computed
  get roomCollection() {
    if (this.tab === AdminDashboardTab.running) {
      return this.runningRooms
    }
    return this.scheduledRooms
  }

  @computed
  get rooms() {
    return this.roomCollection.models
      .map((roomState) => {
        const section =
          roomState.data.sectionId &&
          this.sections.get(roomState.data.sectionId)
        const slideDeck = this.slideDecks.get(roomState.data.slideDeckId)
        const slideCount = this.slideCounts.get(roomState.data.slideDeckId)
        return new DashboardItem(
          this.repository,
          roomState,
          section || undefined,
          slideDeck,
          slideCount
        )
      })
      .filter((item): item is DashboardItem => item !== null)
      .filter((item) => {
        if (this.filters.length === 0) return true

        return this.filters.every((filter) => {
          const targets: string[] = []

          const section = item.section
          if (section) {
            const className = section.data.className.toLowerCase()
            const sectionName = section.data.sectionName.toLowerCase()

            targets.push(className)
            targets.push(sectionName)
          }
          const slideDeck = item.slideDeck
          if (slideDeck) {
            const slideDeckName = slideDeck.data.slideDeckName.toLowerCase()
            targets.push(slideDeckName)
          }

          const instructor = item.instructor
          if (instructor) {
            const firstName = instructor.data.firstName.toLowerCase()
            const lastName = instructor.data.lastName.toLowerCase()
            targets.push(firstName)
            targets.push(lastName)
          }

          return targets.some((target) => target.includes(filter.toLowerCase()))
        })
      })
      .sort((a, b) => {
        const aScheduledAt = a.roomState.data.scheduledAt
        const bScheduledAt = b.roomState.data.scheduledAt
        if (!aScheduledAt || !bScheduledAt) return 1

        return aScheduledAt.getTime() - bScheduledAt.getTime()
      })
  }

  @action
  changeTab(tab: AdminDashboardTab): void {
    this.tab = tab
  }

  @action
  addFilter(filter: string) {
    this.filters.push(filter)
  }

  @action
  removeFilter = (filter: string) => {
    this.filters.remove(filter)
  }
}
