import z from 'zod'
import { PromotionAppliesTo } from '../Promotion/types'
import {
  firestoreTimestamp,
  firestoreWriteTimestamp,
  nullSafeNumberArray,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'
import { PromotionType } from './types'

const promotionTypeSchema = z.nativeEnum(PromotionType)

const promotionAppliesToSchema = z.nativeEnum(PromotionAppliesTo)

// - `userId` string
// - `promotionId` string
// - `expirationDate` timestamp
// - `promotionName` string
// - `consumableCount` optional number
// - `collisionCode` optional string
// - `numberField` optional number array
// - `stringField` optional string array
// - `promotionSource` = `invitation`
// - `invitationId` = the invitationId
export const schema = z.object({
  userId: z.string(),
  promotionId: z.string(),
  expirationDate: firestoreTimestamp,
  appliesTo: z.preprocess(
    (val) => val ?? PromotionAppliesTo.section,
    promotionAppliesToSchema
  ),
  promotionName: nullSafeString(''),
  promotionSource: z.preprocess(
    (val) => val ?? PromotionType.invitation,
    promotionTypeSchema
  ),
  consumableCount: nullSafeOptionalNumber(),
  collisionCode: nullSafeOptionalString(),
  numberField: nullSafeNumberArray([]),
  stringField: nullSafeStringArray([]),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  expirationDate: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    userId: '',
    promotionId: '',
    expirationDate: { seconds: 0, nanoseconds: 0 },
    promotionName: '',
    promotionSource: PromotionType.invitation,
    numberField: [],
    stringField: [],
  })
)

export type FirestoreUserPromotion = z.infer<typeof schema>
export type FirestoreUserPromotionWrite = z.infer<typeof writeSchema>
