import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeString,
} from '../shared/schema'

// instructor / admin users stored in sub-collections
export enum OrganizationInvoiceStatus {
  noInvoices = 0,
  adminApproved = 1,
}

export enum OrganizationState {
  deleted = -1, // do not show to user
  inactive = 0, // no edits, can show if toggle archived view
  active = 1, // allow edits
}

export const schema = z.object({
  organizationName: nullSafeString(''),
  institutionId: nullSafeString(''),
  organizationInstitution: nullSafeString(''),
  organizationInvoiceStatus: z
    .nativeEnum(OrganizationInvoiceStatus)
    .default(OrganizationInvoiceStatus.noInvoices),
  organizationState: z
    .nativeEnum(OrganizationState)
    .default(OrganizationState.active),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    organizationName: '',
    organizationInstitution: '',
    institutionId: '',
  })
)

export interface FirestoreOrganization extends z.infer<typeof schema> {}
