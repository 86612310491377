import { z } from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeBoolean,
  nullSafeFirestoreTimestamp,
  nullSafeNumber,
  nullSafeOptionalString,
  nullSafeString,
  nullSafeStringArray,
} from '../../firestore/shared/schema'
import { FieldValue } from 'firebase/firestore'
import { SlideDeckState, SlideDeckType } from '../../types'

const slideDeckStateSchema = z.nativeEnum(SlideDeckState)
const slideDeckTypeSchema = z.nativeEnum(SlideDeckType)

// this is the dart definition
// required List<String> catalogIds, default: []
// required bool slideDeckFree, default: false
// required String slideDeckName, default: ''
// required int slideDeckPrice, default: 0
// required SlideDeckState slideDeckState, default: SlideDeckState.draft
// required String slideDeckTypeId, default: ''
// required String slideDeckVersion, default: 'unknown'
// String? slideDeckDescription,
// List<String>? slideDeckDisciplines, default: []
// bool? slideDeckFeatured, default: false
// String? slideDeckGoogleTemplateURL,
// String? slideDeckImageURL,
// List<String>? slideDeckIndustries, default: []
// List<String>? slideDeckLearningObjectives, default: []
// String? slideDeckParentId,
// String? slideDeckTeaser,
// int? updatedAt,
export const schema = z.object({
  catalogIds: nullSafeStringArray([]),
  slideDeckSlideCount: nullSafeNumber(2), //default to 2 to it doesn't collide with first slide logic (slide 0)
  slideDeckDisciplines: nullSafeStringArray([]),
  slideDeckFree: nullSafeBoolean(false),
  slideDeckIndustries: nullSafeStringArray([]),
  slideDeckKeyConcepts: nullSafeStringArray([]),
  slideDeckLearningObjectives: nullSafeStringArray([]),
  slideDeckName: nullSafeString(''),
  slideDeckPrice: nullSafeNumber(0),
  slideDeckState: z.preprocess(
    (val) => val ?? SlideDeckState.draft,
    slideDeckStateSchema
  ),
  slideDeckTags: nullSafeStringArray([]),
  slideDeckTeaser: nullSafeString(''),
  slideDeckType: z.preprocess(
    (val) => val ?? SlideDeckType.original,
    slideDeckTypeSchema
  ),
  slideDeckTypeId: nullSafeString(''),
  slideDeckVersion: nullSafeString('unknown'),
  slideDeckDescription: nullSafeOptionalString(),
  slideDeckFeatured: nullSafeBoolean(false),
  slideDeckGoogleTemplateURL: nullSafeOptionalString(),
  slideDeckImageURL: nullSafeOptionalString(),
  slideDeckParentId: nullSafeOptionalString(),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    catalogIds: [],
    slideDeckDisciplines: [],
    slideDeckFree: false,
    slideDeckIndustries: [],
    slideDeckKeyConcepts: [],
    slideDeckLearningObjectives: [],
    slideDeckName: '',
    slideDeckPrice: 0,
    slideDeckState: SlideDeckState.draft,
    SlideDeckType: SlideDeckType.original,
    slideDeckTypeId: '',
    slideDeckVersion: '',
    slideDeckTags: [],
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
  // allow sentinel values for catalogIds
  catalogIds: z
    .array(z.string())
    .default([])
    .or(
      z.custom((val) => val instanceof FieldValue, { message: 'FieldValue' })
    ),
})

export interface FirestoreSlideDeck extends z.infer<typeof schema> {}
