import { makeObservable } from 'mobx'
import { Slide } from './Slide'

export class InteractiveQuizSlide extends Slide {
  afterConstructor() {
    makeObservable(this, {
      questions: true,
    })
  }

  get questions() {
    return this.meeting.questions
      .filter((q) => q.slideId === this.meeting.currentSlide?.id && q.isQuiz)
      .sort((a, b) => a.question.localeCompare(b.question))
  }

  get titleIndex(): number {
    // loop over currentSlide.questions and find out if all are submitted
    for (const q of this.questions) {
      if (!q.isSubmitted) {
        return 0
      }
    }
    return 1
  }
}
