import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreOrganizationAdmin,
} from '../firestore/OrganizationAdmin/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class OrganizationAdmin extends ObservableModelWithDecorators<FirestoreOrganizationAdmin> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreOrganizationAdmin>
  ) {
    super(repository, doc)

    makeObservable(this)
  }
}
