import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  doc,
  documentId,
  query,
  serverTimestamp,
  where,
  writeBatch,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collectionSnapshots,
  CollectionSnapshotStreamCollector,
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
  partitionedQueryStream,
} from '../../firestore-mobx/stream'
import { Catalog } from '../../models/Catalog'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreCatalog } from './schema'
import { schema } from './schema'
import { asyncExpand } from '../../util/asyncExpand'
import { StreamController } from 'tricklejs'
import { fetchAppUsers } from '../AppUser'
import { getTAInstructorIDs } from '../UserProfile'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { type MobxDocument } from '../../firestore-mobx'
import { partition } from '../../util/arrays'

const converter: FirestoreDataConverter<FirestoreCatalog> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreCatalog> => {
  return collection(firestore, 'catalog').withConverter(converter)
}

export const getCatalogs = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  return modelListStream(repository, ref, Catalog)
}

export const getCatalogsByIds = (
  repository: FirebaseRepository,
  { catalogIds }: { catalogIds: string[] }
) => {
  return partitionedQueryStream({
    repository,
    ref: getColRef(repository.firestore),
    matchArray: catalogIds,
    model: Catalog,
  })
}

export const getCatalog = (
  repository: FirebaseRepository,
  {
    catalogId,
  }: {
    catalogId: string
  }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, catalogId)
  return modelItemStream(repository, docRef, Catalog)
}

export const getAllCatalogIds = (repository: FirebaseRepository) => {
  // get my instructor ids
  const stream = new StreamController<string[]>()
  let cancelled = false
  let instructorsStreamResults: string[] | undefined
  let myCatalogsStreamResult: string[] | undefined
  const emitIfBothResults = () => {
    if (!instructorsStreamResults || !myCatalogsStreamResult) return
    stream.add(
      Array.from(
        new Set([...instructorsStreamResults, ...myCatalogsStreamResult])
      )
    )
  }
  const instructorIdsStream = getTAInstructorIDs(repository).listen(
    async (instructorIds) => {
      if (cancelled) return
      if (!instructorIds.length || !repository.currentUser)
        instructorsStreamResults = []
      else {
        const catalogsAccessibleByUsers = await fetchCatalogsAccessibleByUsers(
          repository,
          {
            userIds: instructorIds,
          }
        )
        const uniqueCatalogIds = Array.from(
          new Set(Object.values(catalogsAccessibleByUsers).flat())
        )
        instructorsStreamResults = uniqueCatalogIds
      }
      emitIfBothResults()
    }
  )
  const myIdsStream = getCatalogIdsForCurrentUser(repository).listen(
    (catalogIds) => {
      if (cancelled) return
      myCatalogsStreamResult = catalogIds
      emitIfBothResults()
    }
  )

  stream.onCancel = () => {
    cancelled = true
    instructorIdsStream.cancel()
    myIdsStream.cancel()
    stream.close()
  }
  return stream.stream
}

export const getCatalogIdsForCurrentUser = (repository: FirebaseRepository) => {
  if (!repository.currentUser) throw new Error('User not logged in')
  const catalogCollectionRef = collection(
    repository.firestore,
    'user_profile',
    repository.currentUser.uid,
    'catalogs'
  )
  return collectionSnapshots(catalogCollectionRef).map((snapshot) =>
    snapshot.docs.map((doc) => doc.id)
  )
}

export const getMyCatalogs = (repository: FirebaseRepository) => {
  const controller = new StreamController<Catalog[]>()
  let cancelled = false
  const stream = getAllCatalogIds(repository).listen(async (catalogIds) => {
    if (cancelled) return
    if (!catalogIds.length) {
      controller.add([])
    } else {
      const colRef = getColRef(repository.firestore)
      const idPartitions = partition(catalogIds, 15)
      const queries = idPartitions.map((ids) =>
        getDocsWithError(
          query(colRef, where(documentId(), 'in', ids)),
          'FetchMyCatalogsError'
        )
      )
      const snapshots = await Promise.all(queries)
      const catalogs = snapshots.map(({ docs }) => {
        return docs.map((doc) =>
          convertDocumentSnapshotToModel(repository, doc, Catalog)
        )
      })
      controller.add(catalogs.flat())
    }
  })
  controller.onCancel = () => {
    cancelled = true
    stream.cancel()
    controller.close()
  }
  return controller.stream
}

export const getCatalogsForUser = (
  repository: FirebaseRepository,
  { userId }: { userId: string }
) => {
  /// simple extension so we can add the managing organization id
  class CatalogWithOrganizationId extends Catalog {
    _managingOrganizationId: string | null | undefined = undefined

    get managingOrganizationId() {
      if (this._managingOrganizationId === undefined) return null
      return this._managingOrganizationId
    }

    set managingOrganizationId(value: string | null) {
      if (this.managingOrganizationId)
        throw new Error(
          'Cannot set managingOrganizationId after initialization'
        )
      this._managingOrganizationId = value
    }

    constructor(
      repository: FirebaseRepository,
      doc: MobxDocument<FirestoreCatalog>
    ) {
      super(repository, doc)
    }
  }

  const catalogCollectionRef = collection(
    repository.firestore,
    'user_profile',
    userId,
    'catalogs'
  )
  //parse the ids via a stream, not a catalog just care about the ids
  const snapshots = collectionSnapshots(catalogCollectionRef)

  const stream = snapshots.map((snapshot) => {
    const catalogIdsWithOrgId = snapshot.docs.map((doc) => ({
      catalogId: doc.id,
      organizationId:
        'organizationId' in doc.data() ? doc.data().organizationId : null,
    }))

    const catalogToOrgMap = catalogIdsWithOrgId.reduce<{
      [key: string]: string | null
    }>((acc, item) => {
      acc[item.catalogId] = item.organizationId
      return acc
    }, {})

    if (!catalogIdsWithOrgId.length) {
      const controller = new StreamController<CatalogWithOrganizationId[]>()
      controller.add([])
      controller.close()
      return controller.stream
    }
    const catalogRef = getColRef(repository.firestore)

    const streamCollector =
      new CollectionSnapshotStreamCollector<CatalogWithOrganizationId>({
        resolveAfterAllLoaded: true,
      })

    const catalogIdPartitions = partition(
      catalogIdsWithOrgId.map((c) => c.catalogId),
      15
    )

    catalogIdPartitions.forEach((partition) => {
      const catalogQuery = query(
        catalogRef,
        where(documentId(), 'in', partition)
      )

      streamCollector.attachStream(
        collectionSnapshots(catalogQuery, { wrapped: true }).map((snapshot) => {
          return snapshot.docs.map((doc) => {
            const model = convertDocumentSnapshotToModel(
              repository,
              doc,
              CatalogWithOrganizationId
            )
            model.managingOrganizationId = catalogToOrgMap[doc.id]
            return model
          })
        })
      )
    })

    return streamCollector.stream
  })

  const expandedStream = asyncExpand(stream, (data) => data)

  return expandedStream
}

/**
 * returns an object with the keys being set to the userId and the array being a list of catalogIds
 */
export const fetchCatalogsAccessibleByUsers = async (
  repository: FirebaseRepository,
  { userIds }: { userIds: string[] }
) => {
  const catalogDocPromises = await Promise.all(
    userIds.map(async (userId) => {
      const catalogCollectionRef = collection(
        repository.firestore,
        'user_profile',
        userId,
        'catalogs'
      )
      const snapshot = await getDocsWithError(
        catalogCollectionRef,
        'FetchAccessCatalogsError'
      )
      return { id: userId, catalogIds: snapshot.docs.map((doc) => doc.id) }
    })
  )
  return Object.fromEntries(
    catalogDocPromises.map(({ id, catalogIds }) => [id, catalogIds])
  )
}

export const createCatalog = async (
  repository: FirebaseRepository,
  {
    catalogName,
    catalogDescription,
  }: {
    catalogName: string
    catalogDescription: string
  }
) => {
  const colRef = getColRef(repository.firestore)
  const catalogRef = await addDocWithError(
    colRef,
    {
      catalogName,
      catalogDescription,
      catalogSharedSectionIds: [],
      updatedAt: serverTimestamp(),
    },
    'CreateCatalogError'
  )
  return catalogRef.id
}

export const updateCatalog = async (
  repository: FirebaseRepository,
  catalogId: string,
  {
    catalogName,
    catalogDescription,
  }: {
    catalogName: string
    catalogDescription: string
  }
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, catalogId)
  return updateDocWithError(
    docRef,
    {
      catalogName,
      catalogDescription,
      updatedAt: serverTimestamp(),
    },
    'UpdateCatalogError'
  )
}

export const deleteCatalog = async (
  repository: FirebaseRepository,
  catalogId: string
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, catalogId)
  return deleteDocWithError(docRef, 'DeleteCatalogError')
}

export const getAllAppUserWithCatalogAccess = (
  repository: FirebaseRepository,
  catalogId: string
) => {
  const groupRef = collectionGroup(repository.firestore, 'catalogs')
  const queryRef = query(groupRef, where('catalogId', '==', catalogId))

  return collectionSnapshots(queryRef).asyncMap(async (snapshot) => {
    const userIds = snapshot.docs
      .map((doc) => doc.ref.parent.parent?.id)
      .filter((id): id is string => !!id)

    const appUsers = await fetchAppUsers(repository, { userIds })
    return appUsers
  })
}

export const addSectionToCatalog = (
  repository: FirebaseRepository,
  {
    catalogId,
    sectionId,
  }: {
    catalogId: string
    sectionId: string
  }
) => {
  const batch = writeBatch(repository.firestore)

  const sectionRef = doc(collection(repository.firestore, 'section'), sectionId)
  const catalogRef = doc(collection(repository.firestore, 'catalog'), catalogId)

  batch.update(sectionRef, {
    shareable: true,
    updatedAt: serverTimestamp(),
  })

  batch.update(catalogRef, {
    catalogSharedSectionIds: arrayUnion(sectionId),
    updatedAt: serverTimestamp(),
  })

  return batch.commit()
}

export const removeSectionFromCatalog = async (
  repository: FirebaseRepository,
  {
    catalogId,
    sectionId,
  }: {
    catalogId: string
    sectionId: string
  }
) => {
  const batch = writeBatch(repository.firestore)

  const sectionRef = doc(collection(repository.firestore, 'section'), sectionId)
  const catalogRef = doc(collection(repository.firestore, 'catalog'), catalogId)
  const catalogsRef = collection(repository.firestore, 'catalog')

  const catalogsWithSectionRef = query(
    catalogsRef,
    where('catalogSharedSectionIds', 'array-contains', sectionId)
  )
  const catalogs = await getDocsWithError(
    catalogsWithSectionRef,
    'FetchCatalogsForSectionError'
  )
  const otherCatalogs = catalogs.docs.filter((doc) => doc.id !== catalogId)

  // only remove shareable from section if not shared
  // as part of any other catalog
  if (otherCatalogs.length === 0) {
    batch.update(sectionRef, {
      shareable: false,
      updatedAt: serverTimestamp(),
    })
  }

  batch.update(catalogRef, {
    catalogSharedSectionIds: arrayRemove(sectionId),
    updatedAt: serverTimestamp(),
  })
  return batch.commit()
}
