import type { DocumentReference } from 'firebase/firestore'
import {
  collection,
  doc,
  type CollectionReference,
  type DocumentData,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreSettingsSlideDeckFields } from './schema'
import { settingsSlideDeckFieldsSchema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsSlideDeckFields } from '../../models/SettingsSlideDeckFields'
import { modelItemStream } from '../../firestore-mobx/stream'

const converter: FirestoreDataConverter<FirestoreSettingsSlideDeckFields> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })

    return settingsSlideDeckFieldsSchema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreSettingsSlideDeckFields, DocumentData> => {
  return collection(firestore, 'settings', 'slide_deck_fields').withConverter(
    converter
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore
): DocumentReference<FirestoreSettingsSlideDeckFields, DocumentData> => {
  return doc(firestore, 'settings', 'slide_deck_fields').withConverter(
    converter
  )
}

export const getSettingsSlideDeckFields = (repository: FirebaseRepository) => {
  const ref = getDocRef(repository.firestore)

  return modelItemStream(repository, ref, SettingsSlideDeckFields)
}
