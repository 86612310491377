import { z } from 'zod'
import { nullSafeOptionalFirestoreTimestamp } from '../shared/schema'

export enum OrganizationIntegrationType {
  canvas = 'canvas',
}

export const schema = z.object({
  integrationName: z.string(),
  integrationType: z.nativeEnum(OrganizationIntegrationType),
  integrationHostname: z.string(),
  integrationClientId: z.coerce.string(),
  organizationId: z.string(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    integrationName: '',
    integrationType: OrganizationIntegrationType.canvas,
    integrationHostname: '',
    integrationClientId: '0',
    organizationId: '',
    updatedAt: null,
  })
)

export type FirestoreOrganizationIntegration = z.infer<typeof schema>
