import type { IReactionPublic } from 'mobx'
import { reaction } from 'mobx'
import type { StreamInterface } from 'tricklejs/dist/types'
import type { StreamSubscriptionActions } from 'tricklejs/dist/stream_subscription'
import { WrappedFirestoreError } from '../firestore-mobx/errors'

export abstract class Cubit<T extends object = object> {
  id: string
  state: T
  isDisposed = false

  static callSuperDispose = Symbol('Calling super.dispose is mandatory')

  disposers: VoidFunction[] = []

  constructor() {
    this.id = Math.random().toString()
    this.state = {} as T
  }

  streamStore: {
    subscription: StreamSubscriptionActions
    stream: StreamInterface<unknown>
    namespace?: string
    name?: string
  }[] = []

  addStream<M>(
    stream: StreamInterface<M>,
    callback: (model: M) => void,
    options: {
      namespace?: string
      name?: string
      onError?: (error: Error) => void
      disableCaptureException?: boolean
    } = {}
  ) {
    const defaultError = (error: Error) => {
      const isWrappedFirestoreError = error instanceof WrappedFirestoreError
      if (options.disableCaptureException && isWrappedFirestoreError) {
        // mark the error as handled so it is not sent to Sentry
        error.isHandled = true
      }
      // call removeStream to remove the stream from the store
      if (options.name) this.removeStream(options.name)
      // call the passed in error handler if not null
      options.onError?.(error)
    }
    const subscription = stream.listen(callback, {
      onError: defaultError,
    })
    this.streamStore.push({
      stream,
      subscription,
      namespace: options.namespace,
      name: options.name,
    })
    return stream
  }

  hasStream(name: string) {
    return this.streamStore.some((entry) => entry.name === name)
  }

  removeStream(name: string) {
    this.streamStore.forEach((entry) => {
      if (entry.name === name) {
        entry.subscription.cancel()
      }
    })
    this.streamStore = this.streamStore.filter((entry) => entry.name !== name)
  }

  removeStreams(namespace: string) {
    this.streamStore.forEach((entry) => {
      if (entry.namespace === namespace) {
        entry.subscription.cancel()
      }
    })
    this.streamStore = this.streamStore.filter(
      (entry) => entry.namespace !== namespace
    )
  }

  closeAllStreams({
    namespace,
    name,
  }: { namespace?: string; name?: string } = {}) {
    this.streamStore.forEach((entry) => {
      if (
        (!namespace || entry.namespace === namespace) &&
        (!name || entry.name === name)
      ) {
        entry.subscription.cancel()
      }
    })
  }

  addReaction<T>({
    whenThisChanges,
    thenRunThisCode,
  }: {
    whenThisChanges: () => T
    thenRunThisCode: (value: T, prevValue: T, r: IReactionPublic) => void
  }) {
    this.disposers.push(reaction(whenThisChanges, thenRunThisCode))
  }

  initialize() {}

  dispose() {
    this.disposers.forEach((dispose) => dispose())
    this.closeAllStreams()
    this.isDisposed = true
    return Cubit.callSuperDispose
  }
}
