export function similarity(list: boolean[]) {
  const trueCount = list.filter((b) => b).length
  const falseCount = list.length - trueCount

  let count = list.length

  if (count === 0) count = 1

  // Compute ratio of majority over the total length of the list
  const ratio = Math.max(trueCount, falseCount) / count

  // Re-scale the ratio from [-1,1] to [0,1] for having 0.0 for an even split
  return Math.abs(2 * (ratio - 0.5))
}

export function unique<T>(list: T[]): T[] {
  return Array.from(new Set(list))
}

export function partition<T>(list: T[], size: number): T[][] {
  return list.reduce(
    (acc, _, i) => (i % size ? acc : [...acc, list.slice(i, i + size)]),
    [] as T[][]
  )
}
