import type { DocumentData, DocumentReference } from 'firebase/firestore'
import {
  CollectionReference,
  collection,
  deleteField,
  doc,
  orderBy,
  query,
  serverTimestamp,
  writeBatch,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { FetchedCollection } from '../../firestore-mobx'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideDeckReference } from '../../models/SlideDeckReference'
import type {
  FirestoreSlideDeckReference,
  FirestoreSlideDeckReferenceWrite,
} from './schema'
import { schema } from './schema'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

export * from './schema'

export type ReferenceFields = Omit<
  FirestoreSlideDeckReferenceWrite,
  'updatedAt' | 'referenceOrder'
>

const converter: FirestoreDataConverter<FirestoreSlideDeckReference> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  slideDeckId: string
): CollectionReference<FirestoreSlideDeckReference, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'reference'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  {
    slideDeckId,
    slideReferenceId,
  }: { slideDeckId: string; slideReferenceId: string }
): DocumentReference<FirestoreSlideDeckReference, DocumentData> => {
  return doc(getColRef(firestore, slideDeckId), slideReferenceId)
}

export const buildFirestoreSlideDeckReferencesFetchedCollection = (
  firestore: Firestore,
  slideDeckId: string
): FetchedCollection<FirestoreSlideDeckReference> => {
  const ref = getColRef(firestore, slideDeckId)

  return new FetchedCollection<FirestoreSlideDeckReference>(ref)
}

export const fetchSlideDeckReferences = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
): Promise<SlideDeckReference[]> => {
  const ref = getColRef(repository.firestore, params.slideDeckId)
  const snapshot = await getDocsWithError(ref, 'FetchSlideDeckReferencesError')
  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeckReference)
  })
}

/**
 * sorted by reference order
 */
export const getSlideDeckReferences = (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const ref = getColRef(repository.firestore, params.slideDeckId)
  // order by referenceOrder
  const sortProp: keyof FirestoreSlideDeckReference = 'referenceOrder'
  const q = query(ref, orderBy(sortProp))
  return modelListStream(repository, q, SlideDeckReference)
}

export const saveSlideDeckReference = async (
  repository: FirebaseRepository,
  {
    referenceFields,
    slideDeckId,
    referenceId,
  }: {
    slideDeckId: string
    referenceFields: ReferenceFields
    referenceId?: string
  }
) => {
  type WriteKey = keyof FirestoreSlideDeckReferenceWrite

  const dataToWrite: Partial<Record<WriteKey, unknown>> = {
    ...referenceFields,
    updatedAt: serverTimestamp(),
  }

  // if new doc then set the order to 999
  if (!referenceId) dataToWrite.referenceOrder = 999

  if (!dataToWrite.referenceCitation) {
    if (referenceId) dataToWrite.referenceCitation = deleteField()
    else delete dataToWrite.referenceCitation
  }

  const ref = referenceId
    ? doc(getColRef(repository.firestore, slideDeckId), referenceId)
    : getColRef(repository.firestore, slideDeckId)

  if (ref instanceof CollectionReference) {
    const newReference = await addDocWithError(
      ref,
      dataToWrite,
      'CreateSlideDeckReferenceError'
    )
    reorderReferences(repository, slideDeckId)
    return newReference
  }

  return await updateDocWithError(
    ref,
    dataToWrite,
    'UpdateSlideDeckReferenceError'
  )
}

const reorderReferences = async (
  repository: FirebaseRepository,
  slideDeckId: string
) => {
  const colRef = getColRef(repository.firestore, slideDeckId)

  // Query to order by referenceOrder
  const q = query(colRef, orderBy('referenceOrder'))
  const docs = await getDocsWithError(q, 'FetchForReorderReferencesError')
  const batch = writeBatch(repository.firestore)

  // Sort documents locally by referenceOrder and then by updatedAt
  const sortedDocs = docs.docs.sort((a, b) => {
    const aData = a.data()
    const bData = b.data()

    const aReferenceOrder =
      aData.referenceOrder !== undefined ? aData.referenceOrder : 0
    const bReferenceOrder =
      bData.referenceOrder !== undefined ? bData.referenceOrder : 0

    if (aReferenceOrder === bReferenceOrder) {
      const aUpdatedAt = aData.updatedAt
        ? new Date(aData.updatedAt).getTime()
        : 0
      const bUpdatedAt = bData.updatedAt
        ? new Date(bData.updatedAt).getTime()
        : 0
      return aUpdatedAt - bUpdatedAt
    } else {
      return aReferenceOrder - bReferenceOrder
    }
  })

  // RereferenceOrder based on the locally sorted documents
  sortedDocs.forEach((doc, index) => {
    if (doc.data().referenceOrder === index) return
    batch.update(doc.ref, { referenceOrder: index })
  })

  await batch.commit()
}

export const deleteSlideDeckReference = async (
  repository: FirebaseRepository,
  {
    slideReferenceId,
    slideDeckId,
  }: { slideReferenceId: string; slideDeckId: string }
) => {
  await deleteDocWithError(
    getDocRef(repository.firestore, { slideReferenceId, slideDeckId }),
    'DeleteSlideDeckReferenceError'
  )
}

/**
 * Sort the references in the slide_deck by the order field and update
 * the order field with the index of the reference in the list so that
 * the order is always correct and the references are always sorted by
 * the order field when the slide_deck is loaded from Firestore.
 *
 * if **oldIndex** and **newIndex** are supplied then move element at **oldIndex** to **newIndex**
 */

export const sortSlideDeckReferences = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    slideDeckId,
  }: {
    slideDeckId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const referenceIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = referenceIds.splice(oldIndex, 1)[0]

    //insert at new index
    referenceIds.splice(newIndex, 0, targetId)
  }
  const batch = writeBatch(repository.firestore)
  referenceIds.forEach((referenceId, index) => {
    // get the reference doc ref
    const referenceRef = doc(
      getColRef(repository.firestore, slideDeckId),
      referenceId
    )
    batch.update(referenceRef, { referenceOrder: index })
  })

  // perform the batch write
  await batch.commit()
}
