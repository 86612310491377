export const formatCurrency = (
  value: number,
  currency: 'USD',
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    ...options,
  }).format(value)
}
