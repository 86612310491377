import {
  nullSafeBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
  nullSafeString,
} from '../../firestore/shared/schema'
import { z } from 'zod'

// Define the schema for SlideDeckMaterialType
export enum SlideDeckMaterialType {
  url = 0,
  pdf = 1,
  mp3 = 2,
  webm = 3,
  featuredLarge = 4,
  jpg = 5,
  mp4 = 6,
}

// Define the schema for SlideDeckMaterialWorkType
export enum SlideDeckMaterialWorkType {
  neither = 0,
  preWork = 1,
  postWork = 2,
}

const SlideDeckMaterialTypeSchema = z.nativeEnum(SlideDeckMaterialType)
const SlideDeckMaterialWorkTypeSchema = z.nativeEnum(SlideDeckMaterialWorkType)

// Define the main SlideDeckMaterial schema
// dart definition
// required this.materialName, default: ''
// required this.materialLink, default: ''
// this.materialDescription,
// this.materialOrder,
// this.updatedAt,
// this.viewableByInstructor, default: true,
// this.viewableByPublic, default: false
// this.viewableByStudent, default: true,
// this.materialWorkType, default: SlideDeckMaterialWorkType.neither
export const schema = z.object({
  materialName: nullSafeString(''),
  materialLink: nullSafeString(''),
  materialDescription: nullSafeOptionalString(),
  materialOrder: nullSafeOptionalNumber(),
  materialType: SlideDeckMaterialTypeSchema,
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  viewableByInstructor: nullSafeBoolean(true),
  viewableByPublic: nullSafeBoolean(false),
  viewableByStudent: nullSafeBoolean(true),
  materialMediaFilename: nullSafeOptionalString(),
  materialWorkType: z.preprocess(
    (val) => val ?? SlideDeckMaterialWorkType.neither,
    SlideDeckMaterialWorkTypeSchema
  ),
  // only on podcasts
  imageUrl: nullSafeOptionalString(),
})

export const empty = schema.parse({
  materialDescription: '',
  materialOrder: 0,
  materialType: SlideDeckMaterialType.url,
  updatedAt: { seconds: 0, nanoseconds: 0 },
})

export type FirestoreSlideDeckMaterial = z.infer<typeof schema>
