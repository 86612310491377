import type { WithFieldValue } from 'firebase/firestore'

export function unsupportedConverter(): never {
  throw new Error('not supported')
}

export function buildUnsupportedConverter<T>() {
  return {
    toFirestore: (): WithFieldValue<T> => {
      throw new Error('not supported')
    },
    fromFirestore: (): T => {
      throw new Error('not supported')
    },
  }
}
