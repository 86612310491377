import type { FirebaseRepository } from './FirebaseRepository'
import type { MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreUserProfile,
} from '../firestore/UserProfile/schema'
import {
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithPopup,
  verifyBeforeUpdateEmail,
} from 'firebase/auth'
import { noTryAsync } from '../util/no-try'
import { z } from 'zod'
import { updateEmail } from '../firestore/UserProfile'
export class UserProfile extends ObservableModelWithDecorators<FirestoreUserProfile> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreUserProfile>
  ) {
    super(repository, doc)
  }

  get isAnonymous() {
    return this.data.anonymous || false
  }

  /**
   * Initiates the email update verification process for the currently authenticated user.
   *
   * This function performs the following steps:
   * 1. Checks if a user is currently logged in. If not, it returns an error.
   * 2. Determines the authentication provider (Microsoft or Google) based on the user's provider data.
   * 3. Re-authenticates the user using a popup to ensure they have recently authenticated.
   * 4. Validates the base URL of the application to ensure it's a valid URL.
   * 5. Sends a verification email to the new email address provided, including a link that the user must click to confirm the email change.
   *
   * @param {string} email - The new email address to which the verification email will be sent.
   * @returns {Promise<[true]> | Promise<[false, string]>} A promise that resolves to:
   * - `[true]` if the verification email was sent successfully.
   * - `[false, errorCode]` if an error occurred, where `errorCode` is one of:
   *   - `'user_not_logged_in'`: No user is currently authenticated.
   *   - `'failed_to_reauthenticate'`: The user could not be re-authenticated.
   *   - `'failed_to_verify_email'`: Verification email could not be sent or base URL is invalid.
   */
  async startEmailUpdateVerification(
    email: string
  ): Promise<[false, string] | [true]> {
    if (!this.repository.auth.currentUser) {
      // The user is not logged in, so we can't update their email
      return [false, 'user_not_logged_in']
    }
    const microsoftProvider = new OAuthProvider('microsoft.com')
    const provider =
      this.repository.auth.currentUser.providerData[0].providerId ===
      microsoftProvider.providerId
        ? microsoftProvider
        : new GoogleAuthProvider()

    const [result, reAuthenticateError] = await noTryAsync(() =>
      reauthenticateWithPopup(this.repository.auth.currentUser!, provider)
    )
    if (reAuthenticateError || !result) {
      return [false, 'failed_to_reauthenticate']
    }

    const urlSchema = z.string().url()
    const baseUrl = window.location.origin
    const { success: isValidUrl } = urlSchema.safeParse(baseUrl)

    if (!isValidUrl) {
      console.error('invalid base URL: ', baseUrl)
      return [false, 'failed_to_verify_email']
    }

    // Send verification email with dynamic URL
    const actionCodeSettings = {
      url: `${window.location.origin}/profile?completeEmailUpdate=true`,
    }

    const [, verifyError] = await noTryAsync(() =>
      verifyBeforeUpdateEmail(
        this.repository.auth.currentUser!,
        email,
        actionCodeSettings
      )
    )

    if (verifyError) {
      console.error('failed to verify email: ', verifyError)
      return [false, 'failed_to_verify_email']
    }

    return [true]
  }

  /**
   * This should only be called after the user has verified their email.
   * See `startEmailUpdateVerification` for the full process.
   *
   * @param email
   * @returns true if the email was updated, false if nothing was changed
   */
  async updateEmail(): Promise<string | void> {
    if (!this.repository.auth.currentUser?.email) {
      // No current email
      return 'user_not_logged_in'
    }

    if (this.repository.auth.currentUser?.email === this.data.emailAddress) {
      // Nothing to change
      return 'failed_to_verify_email'
    }

    if (!this.repository.auth.currentUser?.emailVerified) {
      // Current email is not verified.
      return 'failed_to_verify_email'
    }

    await updateEmail(
      this.repository,
      this.id,
      this.repository.auth.currentUser.email
    )
  }
}
