/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-types */

export class EventEmitter {
  private listeners: { [key: string]: Function[] } = {}

  addListener = (event: string, callback: Function) => {
    if (!this.listeners.hasOwnProperty(event)) {
      this.listeners[event] = []
    }

    if (this.listeners[event]) {
      this.listeners[event]!.push(callback)
    }

    return this
  }

  removeListener = (event: string, callback: Function) => {
    if (!this.listeners.hasOwnProperty(event)) {
      return this
    }

    const collection = this.listeners[event]!

    for (let i = 0; i < collection.length; i++) {
      if (collection[i] === callback) {
        collection.splice(i, 1)
        break
      }
    }

    return this
  }

  // aliases
  on = this.addListener
  off = this.removeListener

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emit(event: string, ...data: any) {
    if (!this.listeners.hasOwnProperty(event)) {
      return null
    }

    const collection = this.listeners[event]!

    for (let i = 0; i < collection.length; i++) {
      const callback = collection[i]

      if (callback) callback.call(this, ...data)
    }
  }
}
