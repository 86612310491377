import type { FirestoreSlideQuestion } from '../firestore/SlideQuestion/schema'
import type { SlideQuestion } from './SlideQuestion'
import { SlideQuestionType } from './SlideQuestionType'

// deprecated - use SlideQuestion instead
export class Question {
  id: string
  data: FirestoreSlideQuestion
  worstPossibleScore: number
  answers: string[]
  slideId: string | undefined
  question: string
  groupSlideId: string | undefined
  questionType: SlideQuestionType
  model: SlideQuestion

  constructor(model: SlideQuestion) {
    this.id = model.id
    this.model = model
    const data = model.data
    this.data = data
    this.answers = data.answers
    this.slideId = data.slideId ?? undefined
    this.groupSlideId = data.groupSlideId || undefined
    this.question = data.question || ''
    this.questionType = data.questionType
    this.worstPossibleScore = this.getWorstPossibleScore()
  }

  getWorstPossibleScore() {
    // if not sorting, it's 0
    if (this.data.questionType !== 4) return 0

    // sorting
    const n = this.data.answers.length
    const halfN = Math.floor(n / 2)
    return 2 * (halfN * (halfN + 1) - halfN * (1 - (n % 2)))
  }

  /// test element is of type quiz
  get isQuiz(): boolean {
    return (
      this.questionType === SlideQuestionType.multipleChoice ||
      this.questionType === SlideQuestionType.sorting
    )
  }

  /// test element is of type poll
  get isPoll(): boolean {
    return (
      this.questionType === SlideQuestionType.poll ||
      this.questionType === SlideQuestionType.customPoll
    )
  }
}
