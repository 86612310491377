import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  collection,
  doc,
  serverTimestamp,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { type FirestoreInstitution, schema } from './schema'
import { Institution } from '../../models/Institution'
import {
  addDocWithError,
  deleteDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreInstitution> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreInstitution> => {
  return collection(firestore, 'institution').withConverter(converter)
}

export const getInstitutions = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  return modelListStream(repository, ref, Institution)
}

export const getInstitution = (
  repository: FirebaseRepository,
  { institutionId }: { institutionId: string }
) => {
  const ref = doc(getColRef(repository.firestore), institutionId)
  return modelItemStream(repository, ref, Institution)
}

export const createInstitution = async (
  repository: FirebaseRepository,
  { institutionName }: { institutionName: string }
) => {
  await addDocWithError(getColRef(repository.firestore), {
    institutionName,
    updatedAt: serverTimestamp(),
  })
}

export const updateInstitution = async (
  repository: FirebaseRepository,
  {
    institutionId,
    institutionName,
  }: { institutionId: string; institutionName: string }
) => {
  const ref = doc(getColRef(repository.firestore), institutionId)
  await updateDocWithError(ref, {
    institutionName,
    updatedAt: serverTimestamp(),
  })
}

export const deleteInstitution = async (
  repository: FirebaseRepository,
  { institutionId }: { institutionId: string }
) => {
  const ref = doc(getColRef(repository.firestore), institutionId)
  await deleteDocWithError(ref)
}
