import z from 'zod'
import { nullSafeStringArray } from '../shared/schema'

// This does not have a corresponding dart file
export const schema = z.object({
  protected: nullSafeStringArray([]),
})

export const empty = Object.freeze(
  schema.parse({
    protected: [],
  })
)

export type FirestoreSettingsCatalogs = z.infer<typeof schema>
