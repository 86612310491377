import type { MobxDocument } from '../firestore-mobx'
import { empty, type FirestoreSlideRubric } from '../firestore/SlideRubric'
import type { FirebaseRepository } from './FirebaseRepository'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { computed, makeObservable } from 'mobx'

export class SlideRubric extends ObservableModelWithDecorators<FirestoreSlideRubric> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlideRubric>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  @computed
  get rubricType(): RubricType | undefined {
    if (!this.hasData) return undefined
    // return index of the rubric type enum
    switch (this.data.rubricType) {
      case 0:
        return RubricType.blooms
      case 1:
        return RubricType.passFail
      default:
        return undefined
    }
  }
}

/// Rubric Types
export class RubricType {
  /// Blooms
  static readonly blooms = 'blooms'
  /// Pass/Fail
  static readonly passFail = 'passFail'

  /// get rubricWeightingExponent
  /// the exponent used to weight the rubric score
  static getRubricWeightingExponent(type: RubricType): number {
    switch (type) {
      case RubricType.blooms:
        return 1.5

      case RubricType.passFail:
        return 1.5

      default:
        throw new Error('Unknown RubricType')
    }
  }

  /// get rubricExpectedGroupScore
  /// the minimum score expected for this rubric type
  static getRubricExpectedGroupScore(type: RubricType): number {
    switch (type) {
      case RubricType.blooms:
        return 4

      case RubricType.passFail:
        return 1

      default:
        throw new Error('Unknown RubricType')
    }
  }

  /// get rubricTypeNormalizationFactor
  /// the rubric type normalization factor
  /// used to allow for different rubric types to be compared
  /// to each other
  static getRubricTypeNormalizationFactor(type: RubricType): number {
    switch (type) {
      case RubricType.blooms:
        return 1

      case RubricType.passFail:
        return 4

      default:
        throw new Error('Unknown RubricType')
    }
  }
}
