type NoTryResult<T, E = Error> = [T, undefined] | [undefined, E]

function noop(): void {
  return undefined
}

// eslint-disable-next-line import/no-unused-modules
export function noTry<T, E = Error>(
  fn: () => T,
  handleErr: (error: E) => void = noop
): NoTryResult<T, E> {
  try {
    const res = fn()
    return [res, undefined]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    handleErr(err)
    return [undefined, err]
  }
}

export async function noTryAsync<T, E = Error>(
  fn: () => Promise<T>,
  handleErr: (error: E) => void = noop
): Promise<NoTryResult<T, E>> {
  try {
    const res = await fn()
    return [res, undefined]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    handleErr(err)
    return [undefined, err]
  }
}
