import { makeObservable, observable } from 'mobx'
import type { StaticModelCollection } from '../firestore-mobx/model'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { UserPromotion } from '../models/UserPromotion'
import { getUserPromotions } from '../firestore/UserPromotion'
import { getUserPromotionRedemptions } from '../firestore/UserPromotionRedemption'
import type { UserPromotionRedemption } from '../models/UserPromotionRedemption'

export class UserPromotionCubit extends Cubit {
  repository: FirebaseRepository

  userId: string
  userPromotions: StaticModelCollection<UserPromotion>
  userPromotionRedemptions = observable.map<string, UserPromotionRedemption[]>()

  constructor(repository: FirebaseRepository, userId: string) {
    super()
    this.userId = userId
    this.repository = repository

    this.userPromotions = UserPromotion.emptyCollection(repository)

    makeObservable(this)
  }

  initialize(): void {
    this.addStream(
      getUserPromotions(this.repository, {
        userId: this.userId,
      }),
      (promotions) => {
        this.userPromotions.replaceModels(promotions)
        this.startRedemptionStreams(promotions)
      }
    )
  }

  startRedemptionStreams(promotions: UserPromotion[]): void {
    for (const promotion of promotions) {
      this.addStream(
        getUserPromotionRedemptions(this.repository, {
          userId: this.userId,
          userPromotionId: promotion.id,
        }),
        (redemptions) => {
          this.userPromotionRedemptions.set(promotion.id, redemptions)
        }
      )
    }
  }
}
