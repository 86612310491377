import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsPricing } from '../../models/SettingsPricing'
import type { FirestoreSettingsPricing } from './schema'
import { schema } from './schema'
import { getDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreSettingsPricing> = {
  toFirestore: (user: FirestoreSettingsPricing) => user,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return schema.parse(snapshot.data())
  },
}

export const getDocRef = (firestore: Firestore) => {
  return doc(
    collection(firestore, 'settings').withConverter(converter),
    'pricing'
  )
}

export const fetchSettingsPricing = async (repository: FirebaseRepository) => {
  const docRef = getDocRef(repository.firestore)

  const doc = await getDocWithError(
    docRef,
    'FetchFirestoreSettingsPricingError'
  )

  return convertDocumentSnapshotToModel(repository, doc, SettingsPricing)
}

export const getSettingsPricing = (repository: FirebaseRepository) => {
  const docRef = getDocRef(repository.firestore)

  return modelItemStream(repository, docRef, SettingsPricing)
}
