import { z } from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
} from '../shared/schema'

// dart definition
// required String authorBio,
// required String authorInstitution,
// required String authorName,
// required String authorTitle,
// String? authorImageURL,
// int? order,
// int? updatedAt,
//
// no default values
export const slideDeckAuthorSchema = z.object({
  authorBio: z.string(),
  authorImageURL: nullSafeOptionalString(),
  authorInstitution: z.string(),
  authorName: z.string(),
  authorTitle: z.string(),
  authorImageFilename: nullSafeOptionalString(),
  order: nullSafeOptionalNumber(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const emptySlideDeckAuthor = slideDeckAuthorSchema.parse({
  authorBio: '',
  authorInstitution: '',
  authorName: '',
  authorTitle: '',
})

export type FirestoreSlideDeckAuthor = z.infer<typeof slideDeckAuthorSchema>
