import { action, computed, makeObservable, observable } from 'mobx'

import { TeachingPlan } from '../models/TeachingPlan'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { getTeachingPlan } from '../firestore/TeachingPlan'
import { TeachingPlanAuthor } from '../models/TeachingPlanAuthor'
import type { StaticModelCollection } from '../types'
import { getTeachingPlanAuthors } from '../firestore/TeachingPlanAuthor'
import { getTeachingPlanModules } from '../firestore/TeachingPlanModule'
import { TeachingPlanModule } from '../models/TeachingPlanModule'
import { getTeachingPlanModuleSlideDecks } from '../firestore/TeachingPlanModuleSlideDeck'
import { getSlideDeck } from '../firestore/SlideDeck'
import { fetchSlideDeckMaterialsForInstructor } from '../firestore/SlideDeckMaterial'
import type { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import type { TeachingPlanModuleSlideDeck } from '../models/TeachingPlanModuleSlideDeck'
import type { SlideDeck } from '../models/SlideDeck'
import { filterSlideDeck } from '../util/filtering'
import type { SlideDeckAuthor } from '../models/SlideDeckAuthor'
import { getSlideDeckAuthors } from '../firestore/SlideDeckAuthor'
import type { SlideDeckReference } from '../models/SlideDeckReference'
import { getSlideDeckReferences } from '../firestore/SlideDeckReference'

export type SlideDeckPack = {
  catalogId: string
  slideDeck: SlideDeck
}

export class InstructorTeachingPlanCubit extends Cubit {
  repository: FirebaseRepository

  catalogId: string
  teachingPlanId: string

  teachingPlan: TeachingPlan
  teachingPlanAuthors: StaticModelCollection<TeachingPlanAuthor>
  teachingPlanModules: StaticModelCollection<TeachingPlanModule>

  teachingModuleSlideDecksByModuleId = observable.map<
    string,
    TeachingPlanModuleSlideDeck[]
  >()

  slideDeckMaterialsBySlideDeckId = observable.map<
    string,
    SlideDeckMaterial[]
  >()

  slideDeckReferencesBySlideId = observable.map<string, SlideDeckReference[]>()
  slideDeckAuthorsBySlideDeckId = observable.map<string, SlideDeckAuthor[]>()

  slideDecksById = observable.map<string, SlideDeck>()

  @observable
  searchFilter: string = ''

  constructor(
    repository: FirebaseRepository,
    catalogId: string,
    teachingPlanId: string
  ) {
    super()
    this.catalogId = catalogId
    this.teachingPlanId = teachingPlanId
    makeObservable(this)

    this.repository = repository
    this.teachingPlan = TeachingPlan.empty(repository)
    this.teachingPlanAuthors = TeachingPlanAuthor.emptyCollection(repository)
    this.teachingPlanModules = TeachingPlanModule.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getTeachingPlan(this.repository, {
        teachingPlanId: this.teachingPlanId,
        catalogId: this.catalogId,
      }),
      (teachingPlan) => {
        this.teachingPlan.replaceModel(teachingPlan)
      }
    )

    this.addStream(
      getTeachingPlanAuthors(this.repository, {
        teachingPlanId: this.teachingPlanId,
        catalogId: this.catalogId,
      }),
      (authors) => {
        this.teachingPlanAuthors.replaceModels(authors)
      }
    )

    this.addStream(
      getTeachingPlanModules(this.repository, {
        teachingPlanId: this.teachingPlanId,
        catalogId: this.catalogId,
      }),
      (modules) => {
        this.teachingPlanModules.replaceModels(modules)

        this.restartModuleSlideDeckStreams()
      }
    )
  }

  restartModuleSlideDeckStreams() {
    const namespace = 'module-slide-decks'

    this.removeStreams(namespace)

    for (const module of this.teachingPlanModules.models) {
      this.addStream(
        getTeachingPlanModuleSlideDecks(this.repository, {
          teachingPlanId: this.teachingPlanId,
          catalogId: this.catalogId,
          moduleId: module.id,
        }),
        (slideDecks) => {
          const slideDeckIds = slideDecks.map(
            (slideDeck) => slideDeck.data.slideDeckId
          )
          this.teachingModuleSlideDecksByModuleId.set(module.id, slideDecks)
          this.addSlideDeckStreams(slideDeckIds)
        },
        {
          namespace,
        }
      )
    }
  }

  addSlideDeckStreams(slideDeckIds: string[]) {
    for (const slideDeckId of slideDeckIds) {
      const name = `slide-deck-${slideDeckId}`

      if (this.hasStream(name)) continue

      this.addStream(
        getSlideDeck(this.repository, {
          slideDeckId,
        }),
        (slideDeck) => {
          this.slideDecksById.set(slideDeckId, slideDeck)
        },
        {
          name,
        }
      )
    }
    this.fetchMaterials(slideDeckIds)
    this.getAuthors(slideDeckIds)
    this.getReferences(slideDeckIds)
  }

  fetchMaterials(slideDecksIds: string[]) {
    slideDecksIds.forEach(async (id) => {
      const streamName = `slide-deck-materials-${id}`
      if (this.hasStream(streamName)) return

      const materials = await fetchSlideDeckMaterialsForInstructor(
        this.repository,
        {
          slideDeckId: id,
        }
      )

      this.slideDeckMaterialsBySlideDeckId.set(id, materials)
    })
  }

  getAuthors(slideDecksIds: string[]) {
    slideDecksIds.forEach(async (id) => {
      const streamName = `slide-deck-authors-${id}`
      if (this.hasStream(streamName)) return

      this.addStream(
        getSlideDeckAuthors(this.repository, {
          slideDeckId: id,
        }),
        (materials) => {
          this.slideDeckAuthorsBySlideDeckId.set(id, materials)
        }
      )
    })
  }

  getReferences(slideDecksIds: string[]) {
    slideDecksIds.forEach(async (id) => {
      const streamName = `slide-deck-reference-${id}`
      if (this.hasStream(streamName)) return

      this.addStream(
        getSlideDeckReferences(this.repository, {
          slideDeckId: id,
        }),
        (reference) => {
          this.slideDeckReferencesBySlideId.set(id, reference)
        }
      )
    })
  }

  @action
  setSearchFilter(filter: string) {
    this.searchFilter = filter
  }

  @computed
  get hasSearchFilter() {
    return this.searchFilter.length > 0
  }

  @computed
  get slideDeckPacks(): Map<string, SlideDeckPack[]> {
    const map = new Map<string, SlideDeckPack[]>()
    for (const module of this.teachingPlanModules.models) {
      const moduleSlideDecks =
        this.teachingModuleSlideDecksByModuleId.get(module.id) ?? []
      const packs = moduleSlideDecks.map((moduleSlideDeck) => {
        const slideDeck = this.slideDecksById.get(
          moduleSlideDeck.data.slideDeckId
        )
        return {
          catalogId: moduleSlideDeck.data.catalogId,
          slideDeck,
        }
      })
      const validPacks = packs.filter(
        (pack): pack is SlideDeckPack => !!pack.slideDeck
      )

      map.set(module.id, validPacks)
    }
    return map
  }

  get filteredSlideDecks(): SlideDeckPack[] {
    const slideDecks = Array.from(this.slideDeckPacks.values()).flat()

    if (this.hasSearchFilter) {
      return slideDecks.filter(({ slideDeck }) => {
        return filterSlideDeck(this.searchFilter, slideDeck)
      })
    }

    return slideDecks
  }
}
