import type { ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import type { TranscriptMessage } from '../../types'
import type { MeetingCubit } from '../MeetingCubit'

function sortTranscriptMessages(a: TranscriptMessage, b: TranscriptMessage) {
  const aTime = a.final && a.finalSort ? a.finalSort : a.id
  const bTime = b.final && b.finalSort ? b.finalSort : b.id
  return aTime > bTime ? 1 : -1
}

export class TranscriptController {
  meeting: MeetingCubit
  _messages: ObservableMap<number, TranscriptMessage> = observable.map({})
  userSeen = false
  userHeard = false
  verified = false
  speechDuration = 0
  finalMessageVerificationTimeouts: Record<string, NodeJS.Timeout> = {}

  constructor(meeting: MeetingCubit) {
    this.meeting = meeting

    makeObservable(this, {
      userSeen: observable,
      userHeard: observable,
      speechDuration: observable,
      verified: observable,
      messages: computed,
      handleMessage: action,
      handleSpeakerChange: action,
      addSpeechDuration: action,
      restartVerification: action,
    })
  }

  handleMessage(message: TranscriptMessage) {
    if (message.final) {
      this.meeting.logEvent('meeting_transcript_received_final', {
        message_id: message.id,
        final_sort: message.finalSort,
      })
      // if message is final, remove the old by id
      // and store the new one by finalSort
      this._messages.delete(message.id)
      this._messages.set(message.finalSort || message.id, message)
    } else {
      this.meeting.logEvent('meeting_transcript_received', {
        message_id: message.id,
      })
      this._messages.set(message.id, message)
    }

    // mark user as seen if we get a message about them
    if (message.identity === this.meeting.repository.uid) {
      this.userSeen = true
      this.startFinalMessageVerificationTimeout(message)
    }

    // remove old messages
    if (this._messages.size > 10) {
      const array = Array.from(this._messages.values())
      const sorted = array.sort(sortTranscriptMessages)

      // find all message before last 10
      const toRemove = sorted.slice(0, sorted.length - 10)

      for (const message of toRemove) {
        this._messages.delete(message.id)
      }
    }
  }

  // When a message is received, we keep a progressively increasing timeout to verify that the final message is received
  // The goal is that we expect it to be final within 60 seconds of the first message
  startFinalMessageVerificationTimeout(message: TranscriptMessage) {
    // remove the old timeout
    if (this.finalMessageVerificationTimeouts[message.identity]) {
      clearTimeout(this.finalMessageVerificationTimeouts[message.identity])
    }

    // if the message is final, all is good, we don't need to verify
    if (message.final) return

    // wait 60 seconds with verification - this will be pushed back by any update on the message
    const timeout = setTimeout(() => {
      this.verifyFinalMessage(message.id)
    }, 60_000)

    this.finalMessageVerificationTimeouts[message.identity] = timeout
  }

  verifyFinalMessage(messageId: number) {
    const message = this._messages.get(messageId)

    if (!message) return // message is gone, we can't verify it

    if (!message.final) {
      this.meeting.logEvent('meeting_final_message_verification_failed', {
        message,
      })
    }
  }

  handleSpeakerChange(identity: string) {
    if (identity === this.meeting.repository.uid) {
      this.userHeard = true
    }
  }

  transcriptVerificationTimeout: NodeJS.Timeout | null = null
  transcriptVerificationFailed = false
  addSpeechDuration(duration: number) {
    this.speechDuration += duration

    // if we already failed, don't re-check
    if (this.transcriptVerificationFailed) return

    if (this.speechDuration > 10_000) {
      this.userHeard = true
      if (this.transcriptVerificationTimeout) return

      this.transcriptVerificationTimeout = setTimeout(() => {
        runInAction(() => {
          if (!this.userSeen) {
            // mark as failed - prevents more runs
            this.transcriptVerificationFailed = true
            this.meeting.logEvent('meeting_transcript_problem', {
              duration: this.speechDuration,
            })
            console.error('Transcript verification failed', {
              duration: this.speechDuration,
            })
          }
          this.verified = true
          this.restartVerification()
        })
      }, 10_000)
    }
  }

  restartVerification() {
    this.speechDuration = 0
    this.userHeard = false
    this.userSeen = false
    this.transcriptVerificationFailed = false
    this.transcriptVerificationTimeout = null
  }

  get messages() {
    const array = Array.from(this._messages.values())

    return array.sort(sortTranscriptMessages)
  }
}
