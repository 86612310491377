import type { FirebaseRepository } from './FirebaseRepository'
import type { MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreUserProfilePurchase,
} from '../firestore/UserProfilePurchase/schema'

export class UserProfilePurchase extends ObservableModelWithDecorators<FirestoreUserProfilePurchase> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreUserProfilePurchase>
  ) {
    super(repository, doc)
  }
}
