import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
  nullSafeOptionalStringArray,
  nullSafeStringArray,
} from '../shared/schema'
import { RoomStateVideoMethod } from './types'

// this is the dart definition
// required List<String> groupLeaderUserIds, default: []
// required List<String> hiddenUserIds, default: []
// required String slideDeckId,
// required List<String> userIds, default: []
// String? activeExhibitId,
// int? activeSlide,
// int? activeSlideChangedAt,
// String? assignmentId,
// int? pumpSlide, // perform the action associated with the slide change again
// String? roomStateName,
// int? scheduledAt,
// String? sectionId,
// int? updatedAt,
// String? videoMethod,
export const schema = z.object({
  absentUserIds: nullSafeOptionalStringArray([]),
  groupLeaderUserIds: nullSafeStringArray([]),
  hiddenUserIds: nullSafeStringArray([]),
  userIds: nullSafeStringArray([]),
  slideDeckId: z.string(),
  activeExhibitId: nullSafeOptionalString(),
  activeSlide: nullSafeOptionalNumber().nullable(),
  activeSlideChangedAt: nullSafeOptionalFirestoreTimestamp().nullable(),
  assignmentId: nullSafeOptionalString(),
  pumpSlide: nullSafeOptionalNumber(),
  roomStateName: nullSafeOptionalString(),
  isDemo: nullSafeOptionalBoolean(),
  scheduledAt: nullSafeOptionalFirestoreTimestamp(),
  roomStartedAt: nullSafeOptionalFirestoreTimestamp(),
  tokenRegenerationRequestedAt: nullSafeOptionalFirestoreTimestamp(),
  sectionId: nullSafeOptionalString(),
  videoMethod: z.preprocess(
    (val) => (val as RoomStateVideoMethod) ?? RoomStateVideoMethod.broadcast,
    z.nativeEnum(RoomStateVideoMethod)
  ),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    absentUserIds: [],
    groupLeaderUserIds: [],
    hiddenUserIds: [],
    slideDeckId: '',
    userIds: [],
    videoMethod: RoomStateVideoMethod.broadcast,
  })
)

export const writeSchema = schema.extend({
  activeSlide: z.number().nullable(),
  activeExhibitId: nullSafeOptionalString().nullable(),
  activeSlideChangedAt: firestoreWriteTimestamp.nullable(),
  roomStartedAt: firestoreWriteTimestamp,
  scheduledAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateInferred = z.infer<typeof schema>
export type FirestoreRoomState = FirestoreRoomStateInferred
export type FirestoreRoomStateWrite = z.infer<typeof writeSchema>
