import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
  nullSafeNumber,
  nullSafeOptionalString,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'
import { DetailType } from './types'

export const schema = z.object({
  assignmentId: nullSafeOptionalString(),
  content: z.string(),
  detailType: z.nativeEnum(DetailType),
  arguments: nullSafeStringArray([]),
  eventId: z.string(),
  eventTime: nullSafeFirestoreTimestamp(),
  roomId: z.string(),
  rubricId: z.string(),
  match: nullSafeNumber(0),
  score: nullSafeNumber(0),
  sectionId: nullSafeString(''),
  slideId: nullSafeString(''),
  userId: nullSafeString(''),
  transcript: nullSafeOptionalString(),
})

export const empty = Object.freeze(
  schema.parse({
    assignmentId: null,
    content: '',
    detailType: DetailType.argument,
    arguments: [],
    eventId: '',
    eventTimestamp: null,
    roomId: '',
    rubricId: '',
    match: 0,
    score: 0,
    sectionId: '',
    slideId: '',
    userId: '',
    transcript: null,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateRubricResultDetailInferred = z.infer<typeof schema>
export interface FirestoreRoomStateRubricResultDetail
  extends FirestoreRoomStateRubricResultDetailInferred {}
