import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

export const schema = z.object({
  url: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(schema.parse({}))

export interface FirestoreSectionAssignmentSlides
  extends z.infer<typeof schema> {}
