import { computed } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreRoomMessage } from '../firestore/RoomMessage/schema'
import { empty } from '../firestore/RoomMessage/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class RoomMessage extends ObservableModelWithDecorators<FirestoreRoomMessage> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomMessage>
  ) {
    super(repository, doc)
  }

  @computed get author() {
    return this.repository.userStore.getUser(this.data.authorId)
  }
}
