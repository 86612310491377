import { MeetingCubit } from './MeetingCubit'
import { makeObservable, action, observable } from 'mobx'
import { PreviewDataFetcher } from './meeting/preview/PreviewDataFetcher'
import type { MeetingDataFetcher } from './meeting/MeetingDataFetcher'
import type { SlideTimerController } from './meeting/SlideTimerController'
import { PreviewSlideTimerController } from './meeting/preview/PreviewSlideTimerController'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { BreakoutUser } from '../models/BreakoutUser'
import { PreviewQuestion } from './meeting/preview/PreviewQuestion'
import { RoomStateAnswer } from '../models/RoomStateAnswer'
import { PublicUser } from '../models/PublicUser'
import { doc } from 'firebase/firestore'
import type { RoomState } from '../models/RoomState'

export class PreviewMeetingCubit extends MeetingCubit {
  isPreview: boolean = true
  _activeSlide: number = 0
  _activeSlideChangedAt: number = Date.now()
  _activeExhibitId: string | null = null
  _slideDeckId: string | undefined
  private _seedUsers: PublicUser[] | undefined

  // we want either slideDeckId or roomState ID
  // not both
  constructor({
    currentUser,
    repository,
    slideDeckId,
    roomId,
    seedData,
  }:
    | {
        currentUser: BreakoutUser
        repository: FirebaseRepository
        slideDeckId: string
        roomId?: never
        /**
         * additional data to seed the cubit if need be,
         * this is used for the meeting results dialog as the results cubit has
         * a dependency on meeting cubit, so we must seed the data
         */
        seedData?: {
          roomStateAnswersForGroup: RoomStateAnswer[]
          roomStateAnswersPerUser: Map<string, RoomStateAnswer[]>
          roomState: RoomState
          users: PublicUser[]
        }
      }
    | {
        currentUser: BreakoutUser
        repository: FirebaseRepository
        /**
         * if room ID is supplied the data fetcher will init the stream to fetch all relevant slide deck data
         */
        roomId: string
        slideDeckId?: never
        seedData?: never
      }) {
    super(currentUser, repository, roomId || '')
    this.useMockResults = roomId === undefined && seedData === undefined
    this._slideDeckId = slideDeckId
    this.chatController = undefined

    this.sidebarTab = 'agenda'

    this.roomState.replaceData({
      ...this.roomState.data,
      userIds: [currentUser.uid],
    })

    this.roomStateSummary.replaceData({
      ...this.roomStateSummary.data,
      roomId: this.roomId,
      updatedAt: new Date(),
      summary: `### Executive Summary\n- **John Doe**:\n  - Discussed the
     functionality and user interface of the platform.\n  - Raised questions
     about the AI transcript functionality.\n  - Provided feedback on the
     interactive elements of the platform.\n- **Jane Austin**:\n  -
     Clarified the role of the group leader and the different options available.
     \n  - Discussed the purpose of the platform, highlighting its potential for
     improving education.\n  - Addressed technical issues and explained the
     platform's features.\n  - Gave feedback on the case study content and its
     suitability for different audiences.\n### Conclusion\nThe discussion
     primarily revolved around the functionality and potential of the platform,
     with John Doe providing user feedback and Jane Austin explaining
     the features and purpose of the platform. The topic of AI transcript
     functionality led to a significant discussion. The students also discussed
     the content of the case studies, with Jane Austin providing insights
     into their suitability for different audiences. The conversation
     highlighted the platform's potential for improving education through
     interactive group discussions.`,
    })

    this.roomStateAnswersPerUser.set(
      currentUser.uid,
      RoomStateAnswer.emptyCollection(repository)
    )

    if (seedData) {
      this.roomState.replaceModel(seedData.roomState)
      this.roomStateAnswersForGroup.replaceModels(
        seedData.roomStateAnswersForGroup
      )
      for (const [userId, answers] of seedData.roomStateAnswersPerUser) {
        const answersCol = RoomStateAnswer.emptyCollection(repository)
        answersCol.replaceModels(answers)
        this.roomStateAnswersPerUser.set(userId, answersCol)
      }
      this._seedUsers = seedData.users
    }

    makeObservable(this, {
      showExhibitPreview: action,
      _activeSlide: observable,
      _activeSlideChangedAt: observable,
      _activeExhibitId: observable,
    })
  }

  constructDataFetcher(): MeetingDataFetcher {
    return new PreviewDataFetcher(this)
  }

  constructSlideTimerController(): SlideTimerController {
    return new PreviewSlideTimerController(this)
  }

  showExhibitPreview(exhibitId: string | null) {
    this._activeExhibitId = exhibitId
  }

  roomStateRef() {
    if (this instanceof PreviewMeetingCubit)
      throw new Error('Method not implemented')
    return super.roomStateRef()
  }

  setSlide(index: number) {
    const newSlideIsDifferent = this._activeSlide !== index

    if (!newSlideIsDifferent) return

    this._activeSlide = index
    this._activeSlideChangedAt = Date.now()
    this._activeExhibitId = null

    const data = this.roomState.data
    this.roomState.replaceData({
      ...data,
      activeSlide: index,
    })

    this.resetSlideTimer()
  }

  shareExhibit(exhibitId: string): void {
    this._activeExhibitId = exhibitId
  }

  stopSharingExhibit(): void {
    this._activeExhibitId = null
  }

  get activeSlide() {
    return this._activeSlide
  }

  // @computed
  get questions(): PreviewQuestion[] {
    return this.slideDeckQuestions.models.map((model) => {
      return new PreviewQuestion(this, model)
    })
  }

  get activeExhibitId(): string | null {
    return this._activeExhibitId
  }

  get chat() {
    if (this.isPreview) throw new Error('cannot access chat in preview meeting')
    return super.chat
  }

  get users() {
    const emptyRef = doc(this.repository.firestore, 'empty/empty')
    // mock some public users
    if (this.useMockResults) {
      return [
        new PublicUser(this.repository, {
          id: '1',
          data: {
            firstName: 'John',
            lastName: 'Doe',
            imageUrl: 'https://robohash.org/set_set1/bgset_bg1/1',
          },
          ref: emptyRef,
          fromCache: false,
          hasPendingWrites: false,
        }),
        new PublicUser(this.repository, {
          id: '2',
          data: {
            firstName: 'Jane',
            lastName: 'Austin',
            imageUrl: 'https://robohash.org/set_set1/bgset_bg1/2',
          },
          ref: emptyRef,
          fromCache: false,
          hasPendingWrites: false,
        }),
        new PublicUser(this.repository, {
          id: '3',
          data: {
            firstName: 'Tim',
            lastName: 'Anderson',
            imageUrl: 'https://robohash.org/set_set1/bgset_bg1/3',
          },
          ref: emptyRef,
          fromCache: false,
          hasPendingWrites: false,
        }),
      ]
    }
    if (this._seedUsers) {
      return this._seedUsers
    }
    return super.users
  }

  get isSharingExhibit(): boolean {
    return this.activeExhibitId !== null
  }

  async sendFeedback(): Promise<void> {
    throw new Error('Method not implemented')
  }

  get currentUserIsGroupLeader() {
    return true
  }

  get sessionResults(): {
    pollAgreement: number
    quizPerformance: number
  } {
    // return some dummy percentage values
    return {
      pollAgreement: 0.875,
      quizPerformance: 0.74,
    }
  }

  get currentSlideExhibits() {
    const slide = this.currentSlide
    if (!slide) return []

    return this.slideDeckExhibits.models.sort((a, b) => {
      return a.data.exhibitName.localeCompare(b.data.exhibitName)
    })
  }
}
