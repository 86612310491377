export class UnsubscribeManager {
  protected unsubscribers: (() => void)[] = []

  add(subscriber: () => void) {
    this.unsubscribers.push(subscriber)
  }

  remove(subscriber: () => void) {
    const index = this.unsubscribers.indexOf(subscriber)
    if (index !== -1) {
      this.unsubscribers.splice(index, 1)
    }
  }

  runAll() {
    this.unsubscribers.forEach((sub) => sub())
  }

  dispose() {
    this.runAll()
    this.unsubscribers = []
  }
}
