import type { CollectionReference, DocumentData } from 'firebase/firestore'
import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { FetchedCollection } from '../../firestore-mobx'
import { ObservableCollection } from '../../firestore-mobx/collection'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreUserProfilePurchase } from './schema'
import { schema } from './schema'
import { unsupportedConverter } from '../shared/noops'
import { UserProfilePurchase } from '../../models/UserProfilePurchase'
import { modelListStream } from '../../firestore-mobx/stream'
import type { StreamInterface } from 'tricklejs/dist/types'

const converter: FirestoreDataConverter<FirestoreUserProfilePurchase> = {
  toFirestore: unsupportedConverter,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  userId: string
): CollectionReference<FirestoreUserProfilePurchase, DocumentData> => {
  return collection(
    firestore,
    'user_profile',
    userId,
    'purchase'
  ).withConverter(converter)
}

export const buildFirestoreUserProfilePurchaseObservableCollection = (
  repository: FirebaseRepository,
  userId: string
): ObservableCollection<FirestoreUserProfilePurchase> => {
  return new ObservableCollection(getColRef(repository.firestore, userId))
}

export const buildFirestoreUserProfilePurchaseFetchedCollection = (
  firestore: Firestore,
  userId: string
): FetchedCollection<FirestoreUserProfilePurchase> => {
  return new FetchedCollection(getColRef(firestore, userId))
}

export const getUserProfilePurchases = (
  repository: FirebaseRepository,
  params: { userId: string }
): StreamInterface<UserProfilePurchase[]> => {
  const ref = getColRef(repository.firestore, params.userId)

  return modelListStream(repository, ref, UserProfilePurchase)
}
