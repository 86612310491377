import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  doc,
  query,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { DateTime } from 'luxon'
import type { StreamInterface } from 'tricklejs/dist/types'
import {
  ObservableModelCollection,
  ObservableModelDocument,
  StaticModelCollection,
  StaticModelDocument,
  type ObservableModel,
} from '../../firestore-mobx/model'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type {
  AssignmentGroupingType,
  AssignmentType,
} from '../../models/SectionAssignment'
import { SectionAssignment } from '../../models/SectionAssignment'
import { SectionState } from '../../types'
import { setSectionState, touchSection } from '../Section'
import type {
  FirestoreSectionAssignment,
  FirestoreSectionAssignmentWrite,
} from './schema'
import { AssignmentState, empty, schema, writeSchema } from './schema'
import {
  addDocWithError,
  deleteDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { RoomState } from '../../models/RoomState'

export * from './schema'

export interface SectionAssignmentObservableModel
  extends ObservableModel<FirestoreSectionAssignment> {}

export interface SectionAssignmentObservableModelCollection
  extends ObservableModelCollection<
    SectionAssignmentObservableModel,
    FirestoreSectionAssignment
  > {}

const converter: FirestoreDataConverter<FirestoreSectionAssignment> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSectionAssignment>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  { sectionId }: { sectionId: string }
): CollectionReference<FirestoreSectionAssignment> => {
  return collection(
    firestore,
    'section',
    sectionId,
    'assignment'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
): DocumentReference<FirestoreSectionAssignment, DocumentData> => {
  return doc(
    firestore,
    'section',
    sectionId,
    'assignment',
    assignmentId
  ).withConverter(converter)
}

export const getSectionAssignments = (
  repository: FirebaseRepository,
  params: { sectionId: string }
): StreamInterface<SectionAssignment[]> => {
  const ref = getColRef(repository.firestore, params)

  return modelListStream(repository, ref, SectionAssignment)
}

export const getSectionAssignment = (
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
): StreamInterface<SectionAssignment> => {
  const ref = getDocRef(repository.firestore, params)

  return modelItemStream(repository, ref, SectionAssignment)
}

// deprecated

export const buildEmptySectionAssignmentModel = (
  repository: FirebaseRepository
) => {
  return new StaticModelDocument({
    repository,
    model: SectionAssignment,
    empty: empty,
  }).model
}

export const buildEmptySectionAssignmentCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: SectionAssignment,
    empty: empty,
  })
}

export const buildSectionAssignmentObservableModelDocument = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const ref = getDocRef(repository.firestore, {
    sectionId: sectionId,
    assignmentId: assignmentId,
  })

  return new ObservableModelDocument({
    ref,
    repository,
    model: SectionAssignment,
    empty: empty,
  })
}

export const buildSectionAssignmentObservableModelCollection = (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  const ref = getColRef(repository.firestore, {
    sectionId: sectionId,
  })
  const q = query(ref)

  return new ObservableModelCollection({
    ref,
    repository,
    model: SectionAssignment,
    query: () => q,
    empty: empty,
  })
}

export const updateSectionAssignment: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    expiresAt: DateTime
    assignedAt: DateTime
    groupingType?: AssignmentGroupingType
    groupingSize?: number
  }
) => Promise<void> = async (
  repository,
  { sectionId, assignmentId, expiresAt, assignedAt, groupingSize, ...rest }
) => {
  const ref = getDocRef(repository.firestore, { sectionId, assignmentId })

  await updateDocWithError(
    ref,
    {
      expiresAt: expiresAt.toJSDate(),
      assignedAt: assignedAt.toJSDate(),
      ...rest,
      updatedAt: serverTimestamp(),
      groupingSizeMinimum: groupingSize ? 2 : undefined,
      groupingSizeMaximum: groupingSize ? RoomState.maxAllowedUsers : undefined,
    },
    'UpdateSectionAssignmentExpirationAndAssignedAtError'
  )
  await touchSection(repository, sectionId)
}

export const updateSectionAssignmentGrouping: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    groupingType: AssignmentGroupingType
    groupingSize?: number
  }
) => Promise<void> = async (
  repository,
  { sectionId, assignmentId, groupingType, groupingSize }
) => {
  const ref = getDocRef(repository.firestore, { sectionId, assignmentId })

  await updateDocWithError(
    ref,
    {
      groupingSize,
      groupingType,
      updatedAt: serverTimestamp(),
    },
    'updateSectionAssignmentGroupingTypeError'
  )
  await touchSection(repository, sectionId)
}

export const updateSectionAssignmentState: (
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentId: string
    state: AssignmentState
  }
) => Promise<void> = async (repository, { sectionId, assignmentId, state }) => {
  const ref = getDocRef(repository.firestore, { sectionId, assignmentId })

  await updateDocWithError(
    ref,
    {
      assignmentState: state,
      updatedAt: serverTimestamp(),
    },
    'UpdateSectionAssignmentStateError'
  )
  await touchSection(repository, sectionId)
}

export const sendTestInstructorEmail: (
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
) => Promise<void> = async (repository, { sectionId, assignmentId }) => {
  const col = collection(
    repository.firestore,
    'section',
    sectionId,
    'assignment',
    assignmentId,
    'email'
  )

  // didn't bother instantiating a model for this since it's a one off
  await addDocWithError(
    col,
    {
      assignmentId: assignmentId,
      emailAddress: repository.currentUser?.email,
      sectionId: sectionId,
      startedAt: serverTimestamp(),
    },
    'SendTestInstructorEmailError'
  )
}

export async function createSectionAssignment(
  repository: FirebaseRepository,
  params: {
    sectionId: string
    assignmentType: AssignmentType
    assignedAt: DateTime
    expiresAt: DateTime
    groupingType: AssignmentGroupingType
    slideDeckId: string
    catalogId?: string
    groupingSize?: number
  }
) {
  const ref = getColRef(repository.firestore, {
    sectionId: params.sectionId,
  })

  const data: FirestoreSectionAssignmentWrite = {
    assignedAt: params.assignedAt.toJSDate(),
    assignmentType: params.assignmentType,
    assignmentState: AssignmentState.active,
    expiresAt: params.expiresAt.toJSDate(),
    groupingType: params.groupingType,
    slideDeckId: params.slideDeckId,
    sectionId: params.sectionId,
    updatedAt: serverTimestamp(),
  }

  if (params.groupingSize) {
    data.groupingSize = params.groupingSize
    data.groupingSizeMinimum = 2
    data.groupingSizeMaximum = RoomState.maxAllowedUsers
  }

  if (params.catalogId) {
    data.catalogId = params.catalogId
  }

  const docRef = await addDocWithError(
    ref,
    data,
    'CreateSectionAssignmentError'
  )

  await setSectionState(repository, {
    sectionId: params.sectionId,
    sectionState: SectionState.inProgress,
  })

  return docRef.id
}

export async function deleteSectionAssignment(
  repository: FirebaseRepository,
  params: { sectionId: string; assignmentId: string }
) {
  const ref = getDocRef(repository.firestore, params)

  await deleteDocWithError(ref, 'DeleteSectionAssignmentError')
}
