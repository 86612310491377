import { z } from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
} from '../shared/schema'

export const teachingPlanAuthorSchema = z.object({
  authorBio: z.string(),
  authorImageURL: nullSafeOptionalString(),
  authorInstitution: z.string(),
  authorName: z.string(),
  authorTitle: z.string(),
  order: nullSafeOptionalNumber(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const emptyTeachingPlanAuthor = teachingPlanAuthorSchema.parse({
  authorBio: '',
  authorInstitution: '',
  authorName: '',
  authorTitle: '',
})

export type FirestoreTeachingPlanAuthor = z.infer<
  typeof teachingPlanAuthorSchema
>
