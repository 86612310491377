import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumberArray,
  nullSafeOptionalString,
} from '../shared/schema'

// this is the dart definition
// required int answer,
// required String roomId,
// required String slideQuestionId,
// required String userId,
// List<int>? answerList,
// String assignmentId,
// String sectionId,
// bool? submitted,
// int? updatedAt,
// there are no defaults
export const schema = z.object({
  answer: z.number(),
  roomId: z.string(),
  slideQuestionId: z.string(),
  userId: z.string(),
  answerList: nullSafeOptionalNumberArray().nullable(),
  assignmentId: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  submitted: nullSafeOptionalBoolean(false),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    answer: 0,
    roomId: '',
    slideQuestionId: '',
    userId: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreRoomStateAnswer = z.infer<typeof schema>
