import type { CollectionReference, DocumentData } from 'firebase/firestore'
import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestorePromotion } from './schema'
import { schema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { StreamInterface } from 'tricklejs/dist/types'
import { modelListStream } from '../../firestore-mobx/stream'
import { Promotion } from '../../models/Promotion'

export * from './schema'

const converter: FirestoreDataConverter<FirestorePromotion> = {
  toFirestore: () => {
    throw new Error('Not supported')
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore
): CollectionReference<FirestorePromotion, DocumentData> => {
  return collection(firestore, 'promotion').withConverter(converter)
}

export const getPromotions = (
  repository: FirebaseRepository
): StreamInterface<Promotion[]> => {
  const ref = getColRef(repository.firestore)

  return modelListStream(repository, ref, Promotion)
}
