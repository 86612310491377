import type { MobxDocument } from '../firestore-mobx'

import type { FirebaseRepository } from './FirebaseRepository'
import { makeObservable } from 'mobx'
import {
  ObservableModelWithDecorators,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreSettingsSlideDeckFields } from '../firestore/SettingsSlideDeckFields/schema'
import { emptySettingsSlideDeckFields } from '../firestore/SettingsSlideDeckFields/schema'

export class SettingsSlideDeckFields extends ObservableModelWithDecorators<FirestoreSettingsSlideDeckFields> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, emptySettingsSlideDeckFields)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSettingsSlideDeckFields>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get slideDeckIndustries() {
    return this.doc.data.slideDeckIndustries
  }

  get slideDeckDisciplines() {
    return this.doc.data.slideDeckDisciplines
  }
}
