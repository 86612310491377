import { computed } from 'mobx'
import type { MobxDocument } from '../firestore-mobx/document'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreTeachingPlan,
} from '../firestore/TeachingPlan/schema'
import type { FirebaseRepository } from './FirebaseRepository'
import { TeachingPlanStatus } from '../firestore/TeachingPlan/types'

export class TeachingPlan extends ObservableModelWithDecorators<FirestoreTeachingPlan> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreTeachingPlan>
  ) {
    super(repository, doc)
  }

  @computed
  get readOnly() {
    return this.data.teachingPlanStatus === TeachingPlanStatus.published
  }
}
