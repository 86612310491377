import { computed, makeObservable } from 'mobx'
import { GroupQuizSlide } from './slides/GroupQuizSlide'
import {
  submitUserAnswer,
  upsertUserAnswer,
} from '../../firestore/RoomStateAnswer'
import type { MeetingCubit } from '../MeetingCubit'
import { Question } from '../../models/Question'
import type { SlideQuestion } from '../../models/SlideQuestion'

/**
 * The MeetingQuestion a Question that is associated with a Meeting.
 *
 * It's goal is to house all logic required for interacting with a Question in the context of a Meeting.
 *
 */
export class MeetingQuestion extends Question {
  meeting: MeetingCubit
  constructor(meeting: MeetingCubit, model: SlideQuestion) {
    super(model)
    this.meeting = meeting
    makeObservable(this, {
      userAnswer: computed,
      groupAnswer: computed,
      allAnswers: computed,
      allSubmittedAnswers: computed,
      isAnswered: computed,
      isSubmitted: computed,
    })
  }

  get slideQuestion() {
    return this.model
  }

  get userAnswer() {
    const user = this.meeting.currentUser
    const collection = this.meeting.roomStateAnswersPerUser.get(user.uid)
    if (!collection) return undefined
    return collection.models.find((ua) => ua.data.slideQuestionId === this.id)
  }

  get groupAnswer() {
    return this.meeting.roomStateAnswersForGroup.models.find(
      (ua) => ua.data.slideQuestionId === this.id
    )
  }

  get isAnswered() {
    if (this.meeting.currentSlide instanceof GroupQuizSlide) {
      return !!this.groupAnswer
    }
    return !!this.userAnswer
  }

  get isSubmitted() {
    if (this.meeting.currentSlide instanceof GroupQuizSlide) {
      return !!this.groupAnswer?.data.submitted
    }
    return !!this.userAnswer?.data.submitted
  }

  get allAnswers() {
    return this.meeting.questionAnswers.get(this.id) || []
  }

  get allSubmittedAnswers() {
    return this.allAnswers.filter((a) => a.data.submitted)
  }

  answer(answer: number, answerList?: number[]) {
    return this.sendUserAnswer({
      answer,
      answerList,
      isGroupAnswer: false,
    })
  }

  answerForGroup(answer: number, answerList?: number[]) {
    return this.sendUserAnswer({
      answer,
      answerList,
      isGroupAnswer: true,
    })
  }

  protected sendUserAnswer({
    answer,
    answerList,
    isGroupAnswer,
  }: {
    isGroupAnswer: boolean
    answer: number
    answerList?: number[]
  }) {
    const meeting = this.meeting
    if (!meeting.roomId) return Promise.resolve()

    const answerId = isGroupAnswer ? this.groupAnswer?.id : this.userAnswer?.id

    return upsertUserAnswer(meeting.repository, this.id, {
      userId: meeting.currentUser.uid,
      roomId: meeting.roomId,
      assignmentId: meeting.assignmentId || undefined,
      sectionId: meeting.sectionId || undefined,
      answerId: answerId,
      answer,
      answerList,
      isGroupAnswer,
    })
  }

  submitAnswer() {
    this.submitUserAnswer({ isGroupAnswer: false })
  }

  submitGroupAnswer() {
    this.submitUserAnswer({ isGroupAnswer: true })
  }

  protected async submitUserAnswer({
    isGroupAnswer,
  }: {
    isGroupAnswer: boolean
  }) {
    const meeting = this.meeting
    if (!meeting.roomId) return

    const answerId = isGroupAnswer ? this.groupAnswer?.id : this.userAnswer?.id

    if (!answerId) return

    submitUserAnswer(meeting.firestore, {
      roomId: meeting.roomId,
      userId: meeting.currentUser.uid,
      answerId,
      isGroupAnswer,
    })
  }
}
