import { z } from 'zod'
import { nullSafeOptionalFirestoreTimestamp } from '../shared/schema'

export const schema = z.object({
  organizationId: z.string(),
  catalogId: z.string(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  defaultCatalogId: z.boolean(),
})

export const empty = Object.freeze(
  schema.parse({
    organizationId: '',
    catalogId: '',
    defaultCatalogId: false,
  })
)

export type FirestoreOrganizationCatalog = z.infer<typeof schema>
