import { makeObservable } from 'mobx'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { isSectionInvitation } from '../firestore/Invitation'

export class InvitationTokenCubit extends Cubit {
  repository: FirebaseRepository

  constructor(repository: FirebaseRepository) {
    super()
    this.repository = repository
    makeObservable(this)
  }

  /**
   * only ever consume as admin role
   */
  async isSectionInvitation(invitationId: string): Promise<boolean> {
    return await isSectionInvitation(this.repository, { invitationId })
  }
}
