import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreTeachingPlanModuleSlideDeck } from '../firestore/TeachingPlanModuleSlideDeck/schema'
import { emptyTeachingPlanModuleSlideDeck } from '../firestore/TeachingPlanModuleSlideDeck/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class TeachingPlanModuleSlideDeck extends ObservableModelWithDecorators<FirestoreTeachingPlanModuleSlideDeck> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, emptyTeachingPlanModuleSlideDeck)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, emptyTeachingPlanModuleSlideDeck)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreTeachingPlanModuleSlideDeck>
  ) {
    super(repository, doc)
  }
}
