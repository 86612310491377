import * as Sentry from '@sentry/core'
import { action, computed, makeObservable, observable } from 'mobx'

import type { DateTime } from 'luxon'
import type { StaticModelCollection } from '../firestore-mobx/model'
import {
  createRoomState,
  getRoomStates,
  leaveRoom,
  roomStateAddUser,
} from '../firestore/RoomState'
import { getRoomStateAnswersForInstructor } from '../firestore/RoomStateAnswer'
import { getRoomStateEngagementStreamForAssignment } from '../firestore/RoomStateEngagement'
import { getRoomStateProfessorFeedbackStreamForAssignment } from '../firestore/RoomStateProfessorFeedback'
import { getRoomStateRubricResultStreamForAssignment } from '../firestore/RoomStateRubricResult'
import { getRoomStateSummaryStreamForAssignment } from '../firestore/RoomStateSummary'
import { getSection } from '../firestore/Section'
import type { AssignmentGroupingType } from '../firestore/SectionAssignment'
import {
  AssignmentState,
  deleteSectionAssignment,
  getSectionAssignment,
  sendTestInstructorEmail,
  updateSectionAssignment,
  updateSectionAssignmentGrouping,
  updateSectionAssignmentState,
} from '../firestore/SectionAssignment'
import { getSectionAssignmentSlidesStream } from '../firestore/SectionAssignmentSlides'
import { fetchSlides } from '../firestore/Slide'
import { getSlideDeck } from '../firestore/SlideDeck'
import { fetchSlideDeckMaterialsForInstructor } from '../firestore/SlideDeckMaterial'
import { getSlideQuestions } from '../firestore/SlideQuestion'
import { getSlideRubrics } from '../firestore/SlideRubric'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { PublicUser } from '../models/PublicUser'
import { RoomState, RoomStateStatus } from '../models/RoomState'
import { RoomStateAnswer } from '../models/RoomStateAnswer'
import { RoomStateEngagement } from '../models/RoomStateEngagement'
import { RoomStateProfessorFeedback } from '../models/RoomStateProfessorFeedback'
import { RoomStateRubricResult } from '../models/RoomStateRubricResult'
import { RoomStateSummary } from '../models/RoomStateSummary'
import { Section } from '../models/Section'
import { AssignmentType, SectionAssignment } from '../models/SectionAssignment'
import { SectionAssignmentSlides } from '../models/SectionAssignmentSlides'
import { SlideDeck } from '../models/SlideDeck'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { SlideModel } from '../models/SlideModel'
import { SlideQuestion } from '../models/SlideQuestion'
import { SlideQuestionType } from '../models/SlideQuestionType'
import { RubricType, SlideRubric } from '../models/SlideRubric'
import { observeRoom } from '../util/observe'
import { getQuizAnswerScore } from '../util/scoring'
import { Cubit } from './core'
import { getRoomStateRubricResultDetailsForAssignment } from '../firestore/RoomStateRubricResultDetail'
import { RoomStateRubricResultDetail } from '../models/RoomStateRubricResultDetail'
import { resetRoomState, resetRoomStates } from '../firestore/RoomStateReset'

export class InstructorAssignmentCubit extends Cubit {
  repository: FirebaseRepository
  sectionId: string
  assignmentId: string

  @observable selectedTab: 'summary' | 'results' | 'students' | 'materials'

  @observable resultsTab: 'questions' | 'rubric' | 'polls' | 'quiz' =
    'questions'

  section: Section
  assignment: SectionAssignment
  sectionAssignmentSlides: StaticModelCollection<SectionAssignmentSlides>
  roomStates: StaticModelCollection<RoomState>
  roomStateAnswers: StaticModelCollection<RoomStateAnswer>
  roomStateSummaries: StaticModelCollection<RoomStateSummary>
  roomStateRubricResults: StaticModelCollection<RoomStateRubricResult>
  roomStateEngagements: StaticModelCollection<RoomStateEngagement>
  roomStateProfessorFeedbacksCollection: StaticModelCollection<RoomStateProfessorFeedback>
  roomStateRubricResultDetails: StaticModelCollection<RoomStateRubricResultDetail>
  slideDeck: SlideDeck
  slideDeckSlides: StaticModelCollection<SlideModel>
  slideRubrics: StaticModelCollection<SlideRubric>
  questionsCollection: StaticModelCollection<SlideQuestion>
  materials: StaticModelCollection<SlideDeckMaterial>

  startSectionStream = true
  startAssignmentStream = true

  questionFilters = observable.array<string>([])
  userFilters = observable.array<string>([])

  constructor(
    repository: FirebaseRepository,
    params: {
      sectionId: string
      assignmentId: string
      initialSection?: Section
      initialSectionAssignment?: SectionAssignment
      // from query param
      selectedTab?: string | number | boolean
    }
  ) {
    super()
    makeObservable(this)

    this.repository = repository
    this.sectionId = params.sectionId
    this.assignmentId = params.assignmentId

    this.sectionAssignmentSlides =
      SectionAssignmentSlides.emptyCollection(repository)
    this.roomStateAnswers = RoomStateAnswer.emptyCollection(repository)
    this.questionsCollection = SlideQuestion.emptyCollection(repository)
    this.roomStates = RoomState.emptyCollection(repository)
    this.roomStateSummaries = RoomStateSummary.emptyCollection(repository)

    this.roomStateRubricResults =
      RoomStateRubricResult.emptyCollection(repository)
    this.roomStateRubricResultDetails =
      RoomStateRubricResultDetail.emptyCollection(repository)
    this.slideDeck = SlideDeck.empty(repository)
    this.slideDeckSlides = SlideModel.emptyCollection(repository)
    this.slideRubrics = SlideRubric.emptyCollection(repository)
    this.roomStateEngagements = RoomStateEngagement.emptyCollection(repository)
    this.roomStateProfessorFeedbacksCollection =
      RoomStateProfessorFeedback.emptyCollection(repository)
    this.materials = SlideDeckMaterial.emptyCollection(repository)

    if (params.initialSection) {
      this.section = params.initialSection
    } else {
      this.section = Section.empty(repository)
    }

    if (
      params.initialSectionAssignment &&
      params.initialSectionAssignment.isLoaded
    ) {
      this.assignment = params.initialSectionAssignment
    } else {
      this.assignment = SectionAssignment.empty(repository)
    }

    // set default tab with query param if present
    if (
      params.selectedTab &&
      typeof params.selectedTab === 'string' &&
      ['summary', 'results', 'students', 'materials'].includes(
        params.selectedTab
      )
    ) {
      this.selectedTab = params.selectedTab as typeof this.selectedTab
    } else {
      this.selectedTab = 'summary'
    }
  }

  initialize() {
    this.addStream(
      getSection(this.repository, { sectionId: this.sectionId }),
      (section) => this.section.replaceModel(section)
    )

    this.addStream(
      getSectionAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (assignment) => {
        this.assignment.replaceModel(assignment)

        this.initializeAssignmentStreams()
      }
    )

    this.addStream(
      getSectionAssignmentSlidesStream(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (slides) => {
        this.sectionAssignmentSlides.replaceModels(slides)
      }
    )

    this.addStream(
      getRoomStates(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (roomStates) => {
        this.roomStates.replaceModels(roomStates)
      }
    )

    this.addStream(
      getRoomStateAnswersForInstructor(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (roomStateAnswers) => {
        this.roomStateAnswers.replaceModels(roomStateAnswers)
      }
    )

    this.addStream(
      getRoomStateSummaryStreamForAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (summaries) => {
        this.roomStateSummaries.replaceModels(summaries)
      }
    )

    this.addStream(
      getRoomStateRubricResultStreamForAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (rubricResults) => {
        this.roomStateRubricResults.replaceModels(rubricResults)
      }
    )

    this.addStream(
      getRoomStateEngagementStreamForAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (roomStateEngagements) => {
        this.roomStateEngagements.replaceModels(roomStateEngagements)
      }
    )

    this.addStream(
      getRoomStateProfessorFeedbackStreamForAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (feedbacks) => {
        this.roomStateProfessorFeedbacksCollection.replaceModels(feedbacks)
      }
    )

    this.addStream(
      getRoomStateRubricResultDetailsForAssignment(this.repository, {
        sectionId: this.sectionId,
        assignmentId: this.assignmentId,
      }),
      (rubricResultDetails) => {
        this.roomStateRubricResultDetails.replaceModels(rubricResultDetails)
      }
    )

    if (this.assignment.isLoaded) {
      this.initializeAssignmentStreams()
    }
  }

  // this is guarded, because the slideDeckId cannot change on the assignment
  private initialized = false
  initializeAssignmentStreams() {
    if (this.initialized) return
    this.initialized = true

    const slideDeckId = this.assignment.data.slideDeckId

    this.addStream(
      getSlideQuestions(this.repository, { slideDeckId }),
      (questions) => this.questionsCollection.replaceModels(questions)
    )

    this.addStream(
      getSlideRubrics(this.repository, { slideDeckId }),
      (slideRubrics) => this.slideRubrics.replaceModels(slideRubrics)
    )

    this.addStream(
      getSlideDeck(this.repository, { slideDeckId }),
      (slideDeck) => this.slideDeck.replaceModel(slideDeck)
    )

    fetchSlides(this.repository, {
      slideDeckId: slideDeckId,
    })
      .then((slides) => {
        this.slideDeckSlides.replaceModels(slides)
      })
      .catch((e) => {
        Sentry.captureException(e)
      })

    try {
      fetchSlideDeckMaterialsForInstructor(this.repository, {
        slideDeckId: slideDeckId,
      })
        .then((materials) => {
          this.materials.replaceModels(materials)
        })
        .catch((e) => {
          Sentry.captureException(e)
        })
    } catch (e) {
      console.error(e)
    }
  }

  deleteSectionAssignment = async () => {
    return await deleteSectionAssignment(this.repository, {
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
    })
  }

  @action
  async createRoomState() {
    const roomCount = this.roomStates.models.length
    return await createRoomState(this.repository.firestore, {
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
      slideDeckId: this.assignment.data.slideDeckId,
      name: `Group ${roomCount + 1}`,
    })
  }

  @action
  changeTab(
    tab: typeof this.selectedTab,
    updateQueryParams: (q: { tab: string }) => void
  ) {
    this.userFilters.clear()
    this.selectedTab = tab
    // Update the 'tab' query parameter in the URL
    updateQueryParams({ tab })
  }

  @action
  changeResultsTab(tab: typeof this.resultsTab) {
    this.resultsTab = tab
  }

  @action
  addQuestionFilter = (filter: string) => {
    this.questionFilters.push(filter)
  }

  resetAllGroups = () => {
    return resetRoomStates(this.repository, {
      roomIds: this.roomStates.models.map((roomState) => roomState.id),
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
    })
  }

  resetGroup = async (roomId: string) => {
    await resetRoomState(this.repository, {
      roomId,
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
    })
  }

  @action
  removeQuestionFilter = (filter: string) => {
    this.questionFilters.remove(filter)
  }

  addUserFilter = (filter: string) => {
    this.userFilters.push(filter)
  }

  @action
  removeUserFilter = (filter: string) => {
    this.userFilters.remove(filter)
  }

  @computed
  get hasBeenStarted() {
    return this.roomStates.models.some(
      (roomState) => typeof roomState.data.activeSlide === 'number'
    )
  }

  @computed
  get hasBeenScheduled() {
    return this.roomStates.models.some(
      (roomState) => !!roomState.data.scheduledAt
    )
  }

  @computed
  get allDataLoaded(): boolean {
    return (
      this.section.isLoaded &&
      this.section.studentsLoaded &&
      this.sectionAssignmentSlides.isLoaded &&
      this.assignment.isLoaded &&
      this.roomStates.isLoaded &&
      this.roomStateAnswers.isLoaded &&
      this.roomStateSummaries.isLoaded &&
      this.roomStateEngagements.isLoaded &&
      this.roomStateRubricResults.isLoaded &&
      this.slideRubrics.isLoaded &&
      this.questionsCollection.isLoaded
    )
  }

  @computed
  get assignmentsRubricsRoomStatesDataLoaded(): boolean {
    return (
      this.assignment.isLoaded &&
      this.roomStates.isLoaded &&
      this.roomStateRubricResults.isLoaded &&
      this.slideRubrics.isLoaded
    )
  }

  @computed
  get isReadOnly() {
    return (
      this.repository.currentUser?.uid !== this.section.instructor.id &&
      this.section.data.shareable
    )
  }

  @computed
  get userCount(): number {
    return this.roomStates.models.reduce(
      (acc, roomState) => acc + roomState.data.userIds.length,
      0
    )
  }

  @computed
  get sectionAssignmentSlidesURL() {
    if (this.sectionAssignmentSlides.models.length > 0) {
      return this.sectionAssignmentSlides.models[0].data.url
    }
  }

  @computed
  get groupCount(): number {
    return this.roomStates.models.length
  }

  @computed
  get completedGroupCount(): number {
    return this.roomStatesStatuses.filter(
      (status) => status === RoomStateStatus.completed
    ).length
  }

  @computed
  get roomStatesStatuses() {
    return this.assignmentGroupData.map((group) => group.roomStateStatus)
  }

  @computed
  get rubricResultsDataIsLoading() {
    return !(this.slideRubrics.isLoaded && this.roomStateRubricResults.isLoaded)
  }

  @computed
  get questionsAnswerDataIsLoading() {
    return (
      !this.roomStateAnswers.isLoaded ||
      !this.questionsCollection.isLoaded ||
      !this.slideDeckSlides.isLoaded
    )
  }

  @computed
  get slideRubricsSorted() {
    if (this.slideDeckSlides.isLoading || this.slideRubrics.isLoading) {
      return this.slideRubrics.models
    }
    const slides = this.slideDeckSlides.models
    const slideIds = slides.map((e) => e.id)
    const sortedRubrics = [...this.slideRubrics.models]
    sortedRubrics.sort((a, b) => {
      const aIndex = slideIds.indexOf(a.data.slideId ?? '')

      const bIndex = slideIds.indexOf(b.data.slideId ?? '')

      if (aIndex === bIndex) {
        return a.data.rubric.localeCompare(b.data.rubric)
      }

      return aIndex - bIndex
    })
    return sortedRubrics
  }

  /**
   * Returns a map of the slideRubrics and their results
   */
  @computed
  get rubrics() {
    /// loop over questions and get all those with the type poll
    const rubrics: Map<SlideRubric, RubricResultWithUser[]> = new Map()
    for (const rubric of this.slideRubricsSorted) {
      const rubricResults = this.roomStateRubricResults.models
        .filter((rubricResult) => rubricResult.rubricId === rubric.id)
        .sort((a, b) => b.data.score - a.data.score)
      // get users
      if (rubricResults.length) {
        const resultsWithUsers: RubricResultWithUser[] = rubricResults.map(
          (result) => {
            const user = this.section.students.find(
              (element) => element.id === result.data.userId
            )
            const resultWithUser = result as RubricResultWithUser
            resultWithUser.user = user || PublicUser.empty(this.repository)
            return resultWithUser
          }
        )
        rubrics.set(rubric, resultsWithUsers)
      } else {
        rubrics.set(rubric, [])
      }
    }
    return rubrics
  }

  @computed
  get assignmentProgress() {
    // count unique users
    const userIds = new Set<string>()

    this.roomStates.models.forEach((roomState) => {
      roomState.data.userIds.forEach((userId) => {
        userIds.add(userId)
      })
    })

    const studentCount = userIds.size

    // filter out empty groups
    const validGroups = this.roomStates.models.filter(
      (roomState) => roomState.userIds.length > 0
    )

    const totalGroups: number = validGroups.length

    const completedGroups: number = validGroups.reduce(
      (previousValue: number, roomState: RoomState) => {
        if (
          this.roomStateSummaries.models.some(
            (roomStateSummary: RoomStateSummary) =>
              roomStateSummary.data.roomId === roomState.id
          )
        ) {
          return previousValue + 1
        } else {
          return previousValue
        }
      },
      0
    )

    // round to 2 decimal places
    const percentageCompleted: number =
      Math.round((completedGroups / Math.max(totalGroups, 1)) * 10000) / 100

    return {
      completedGroups,
      percentageCompleted,
      studentCount,
      totalGroups,
    }
  }

  @computed
  get assignmentGroupData(): AssignmentGroupData[] {
    const groupData: AssignmentGroupData[] = []

    if (!this.allDataLoaded) return groupData

    const quizQuestions = this.quizQuestions

    const quizQuestionsByRoomState: Record<
      string,
      Map<SlideQuestion, RoomStateAnswer[]>
    > = {}

    quizQuestions.forEach((answers, question) => {
      answers.forEach((answer) => {
        const data = answer.data
        if (quizQuestionsByRoomState[data.roomId]) {
          if (quizQuestionsByRoomState[data.roomId].has(question)) {
            quizQuestionsByRoomState[data.roomId].get(question)?.push(answer)
          } else {
            quizQuestionsByRoomState[data.roomId].set(question, [answer])
          }
        } else {
          quizQuestionsByRoomState[data.roomId] = new Map([
            [question, [answer]],
          ])
        }
      })
    })

    const sortingQuestions = new Map<string, SlideQuestion>()

    this.roomStates.models.forEach((roomState) => {
      const roomStateStatus = roomState.getRoomStateStatus(
        this.assignment.expiresAt,
        this.section.data.sectionState,
        this.slideDeckSlides.length
      )

      const answerScores = new Map<string, number>()
      const sortingAnswerScores = new Map<string, Map<string, number>>()
      const groupSortingAnswerScores = new Map<string, number>()

      if (quizQuestionsByRoomState[roomState.id]) {
        quizQuestionsByRoomState[roomState.id].forEach((answers, question) => {
          answers.forEach((answer) => {
            const userId = answer.data.userId
            const theScore = getQuizAnswerScore({ answer, question })
            if (question.questionType === SlideQuestionType.sorting) {
              sortingQuestions.set(question.id, question)
              const sortingScore =
                question.getWorstSortingScore -
                theScore * question.getWorstSortingScore

              if (userId === answer.data.roomId) {
                groupSortingAnswerScores.set(
                  question.id,
                  Math.round(sortingScore)
                )
              } else {
                if (!sortingAnswerScores.has(userId)) {
                  sortingAnswerScores.set(userId, new Map())
                }

                sortingAnswerScores.get(userId)?.set(question.id, sortingScore)
              }
            } else {
              answerScores.set(
                userId,
                (answerScores.get(userId) ?? 0) + theScore
              )
            }
          })
        })
      }

      const totalMultipleChoiceQuestions = Array.from(
        quizQuestions.entries()
      ).filter(
        ([question]) =>
          question.questionType === SlideQuestionType.multipleChoice
      ).length

      const quizScore = new Map<string, number>()
      const sortingQuizScores = new Map<string, Map<string, number>>()

      roomState.data.userIds.forEach((userId) => {
        const answered = answerScores.get(userId) ?? 0
        const total = totalMultipleChoiceQuestions
        const score = answered / total
        quizScore.set(userId, score)
        sortingQuizScores.set(
          userId,
          sortingAnswerScores.get(userId) ?? new Map()
        )
      })

      const users: PublicUser[] = []
      const absentUsers: PublicUser[] = []

      // collect all the users in the roomState
      // from state.usersMap
      // final users = <PublicUser>[];
      for (const userId of roomState.data.userIds) {
        const user = this.section.students.find(
          (element) => element.id === userId
        )
        if (user) users.push(user)
      }

      for (const userId of roomState.data.absentUserIds || []) {
        const user = this.section.students.find(
          (element) => element.id === userId
        )
        if (user) absentUsers.push(user)
      }

      // sort the users arr
      users.sort((a, b) => {
        // sort by user.lastName then user.firstName
        // if lastName is not equal then sort by lastName
        if (a.data.lastName !== b.data.lastName) {
          return a.data.lastName.localeCompare(b.data.lastName)
        }
        // if lastName is equal then sort by firstName
        if (a.data.firstName !== b.data.firstName) {
          return a.data.firstName.localeCompare(b.data.firstName!)
        }
        return 0
      })

      // sort the users arr
      absentUsers.sort((a, b) => {
        // sort by user.lastName then user.firstName
        // if lastName is not equal then sort by lastName
        if (a.data.lastName !== b.data.lastName) {
          return a.data.lastName.localeCompare(b.data.lastName)
        }
        // if lastName is equal then sort by firstName
        if (a.data.firstName !== b.data.firstName) {
          return a.data.firstName.localeCompare(b.data.firstName!)
        }
        return 0
      })

      const userIds = users.map((user) => user.id)

      const summary =
        this.roomStateSummaries.models.find(
          (element) => element.data.roomId === roomState.id
        ) || RoomStateSummary.empty(this.repository)

      const rubricResults = new Map<
        string,
        Map<SlideRubric, RoomStateRubricResult[]>
      >()
      this.roomStateRubricResults.models.forEach((rubricResult) => {
        if (rubricResult.data.roomId === roomState.id) {
          const slideRubric =
            this.slideRubrics.models.find(
              (element) => element.id === rubricResult.rubricId
            ) || SlideRubric.empty(this.repository)
          const userId = rubricResult.data.userId
          if (!rubricResults.has(userId)) rubricResults.set(userId, new Map())
          const results = rubricResults.get(userId)?.get(slideRubric) || []
          results.push(rubricResult)
          rubricResults.get(userId)?.set(slideRubric, results)
        }
      })

      const engagementData = new Map<string, RoomStateEngagement>()
      this.roomStateEngagements.models.forEach((engagement) => {
        if (engagement.data.roomId === roomState.id) {
          engagementData.set(engagement.data.userId, engagement)
        }
      })

      if (
        users.length === 0 &&
        this.assignment.data.assignmentType === AssignmentType.studentLed
      ) {
        return
      }

      groupData.push(
        new AssignmentGroupData({
          absentUsers,
          groupName: roomState.roomStateName,
          groupLeader: roomState.groupLeaderUserId,
          groupMembers: userIds,
          groupSortingAnswerScores,
          roomState,
          roomStateStatus,
          engagementData,
          quizScore,
          sortingQuizScores,
          sortingQuestions,
          summary,
          rubricResults,
          users: users,
        })
      )
    })

    return groupData
  }

  @computed
  get assignmentGroupDataSorted(): AssignmentGroupData[] {
    return this.assignmentGroupData
      .concat()
      .sort((a, b) => this.sortAssignmentGroupData(a, b))
  }

  @computed
  get assignmentGroupDataForRoomsWithNoUsers(): AssignmentGroupData[] {
    const groupData: AssignmentGroupData[] = []
    this.roomStates.models.forEach((roomState) => {
      if (roomState.data.userIds.length === 0) {
        const summary =
          this.roomStateSummaries.models.find(
            (element) => element.data.roomId === roomState.id
          ) || RoomStateSummary.empty(this.repository)

        groupData.push(
          new AssignmentGroupData({
            groupName: roomState.roomStateName,
            groupLeader: roomState.groupLeaderUserId,
            groupMembers: [],
            roomState,
            roomStateStatus: roomState.getRoomStateStatus(
              this.assignment.expiresAt,
              this.section.data.sectionState,
              this.slideDeckSlides.length
            ),
            engagementData: new Map(),
            quizScore: new Map(),
            sortingQuizScores: new Map(),
            sortingQuestions: new Map(),
            summary,
            rubricResults: new Map(),
            users: [],
          })
        )
      }
    })
    return groupData.sort((a, b) => this.sortAssignmentGroupData(a, b))
  }

  @computed
  get roomStateProfessorFeedbacks() {
    return this.roomStateProfessorFeedbacksCollection.models.sort((a, b) => {
      // sort by updatedAt ascending
      if (a.data.updatedAt && b.data.updatedAt) {
        return a.data.updatedAt.getTime() - b.data.updatedAt.getTime()
      }
      return 0
    })
  }

  // get summary of ProfessorFeedback
  // this is the top 5 feedbacks sorted by length after filtering
  // on sentiment value if any have a sentiment value
  @computed
  get professorFeedbackSummaryList(): RoomStateProfessorFeedback[] {
    // get the top 5 feedbacks sorted by length
    const feedbacks = this.roomStateProfessorFeedbacks
    const output: RoomStateProfessorFeedback[] = feedbacks.filter(
      (feedback) =>
        feedback.data.professorFeedback &&
        feedback.data.professorFeedback.length > 0
    )
    // add all feedbacks to output
    // sort by length of feedback.data.professorFeedback[0]
    const feedbacksSorted = output.sort((a, b) => {
      return (
        b.data.professorFeedback![0].length -
        a.data.professorFeedback![0].length
      )
    })

    return feedbacksSorted.slice(0, 4)
  }

  // count the number of ProfessorFeedback questions
  @computed
  get professorFeedbackQuestionsCount() {
    return this.roomStateProfessorFeedbacks.reduce(
      (acc, feedback) => acc + (feedback.data.professorFeedback?.length ?? 0),
      0
    )
  }

  @computed
  get professorFeedbackDataLoaded() {
    return (
      this.roomStateProfessorFeedbacksCollection.isLoaded &&
      this.section.studentsLoaded
    )
  }

  getSectionUser = (userId: string): PublicUser => {
    const user = this.section.students.find((u) => u.id === userId)
    if (!user) {
      return PublicUser.empty(this.repository)
    }
    return user
  }

  @computed
  get questions() {
    type Question = {
      group: string
      isGroupLeader: boolean
      questions: string[]
      user: PublicUser
    }
    const questions: Question[] = []

    for (const feedback of this.roomStateProfessorFeedbacks) {
      if (!feedback.data.professorFeedback) continue

      const roomState = this.roomStates.models.find(
        (r) => r.id === feedback.data.roomId
      )

      if (!roomState) continue

      const groupName = roomStateName({
        roomState: roomState,
        section: this.section,
        usersMap: Object.fromEntries(
          this.section.students.map((u) => [u.id, u])
        ),
      })

      // confirm entry.key is in section.users
      const user = this.section.students.find(
        (u) => u.id === feedback.data.userId
      )

      if (!user) continue

      questions.push({
        group: groupName,
        isGroupLeader: roomState.groupLeaderUserIds.includes(
          feedback.data.userId
        ),
        questions: feedback.data.professorFeedback,
        user,
      })
    }

    // sort by lastName, firstName, groupName
    questions.sort((a, b) => {
      // if lastName is not equal then sort by lastName
      if (
        a.user.data.lastName !== b.user.data.lastName &&
        a.user.data.lastName !== null &&
        b.user.data.lastName !== null
      ) {
        return a.user.data.lastName.localeCompare(b.user.data.lastName)
      }
      // if lastName is equal then sort by firstName
      if (
        a.user.data.firstName !== b.user.data.firstName &&
        a.user.data.firstName !== null &&
        b.user.data.firstName !== null
      ) {
        return a.user.data.firstName.localeCompare(b.user.data.firstName)
      }
      // if lastName and firstName are equal then sort by groupName
      return a.group.localeCompare(b.group)
    })
    return questions
  }

  get filteredQuestions() {
    if (!this.questionFilters.length) return this.questions
    return this.questions.filter((q) => {
      const user = q.user
      const fullName = user.fullName.toLowerCase()
      const group = q.group.toLowerCase()
      const questions = q.questions
      return this.questionFilters.some((filter) => {
        filter = filter.toLowerCase()
        return (
          fullName.includes(filter) ||
          group.includes(filter) ||
          questions.some((q) => q.toLowerCase().includes(filter))
        )
      })
    })
  }

  @computed
  get polls() {
    /// loop over questions and get all those with the type poll
    const polls: Map<SlideQuestion, Array<RoomStateAnswer>> = new Map()
    for (const question of this.questionsCollection.models) {
      if (
        question.questionType === SlideQuestionType.poll ||
        question.questionType === SlideQuestionType.customPoll
      ) {
        const answers = this.roomStateAnswers.models.filter(
          (ans) => ans.data.slideQuestionId === question.id
        )
        if (answers.length) {
          polls.set(question, answers)
        }
      }
    }
    return this.sortQuestions(polls)
  }

  // count all the answers for each question
  @computed
  get uniquePollAnswerCount(): number {
    const polls = this.polls
    const counts: Map<string, number> = new Map()
    polls.forEach((answers) => {
      for (const answer of answers) {
        counts.set(
          answer.data.userId,
          (counts.get(answer.data.userId) || 0) + 1
        )
      }
    })

    // return the sum of the counts
    return Array.from(counts.values()).reduce((acc, count) => acc + count, 0)
  }

  @computed
  get assignmentGroupDataSortedWithNotInGroup(): AssignmentGroupData[] {
    const groupDataSorted = this.assignmentGroupDataSorted.concat()
    const userIdsInGroups: string[] = []

    for (const roomState of this.roomStates.models) {
      userIdsInGroups.push(...roomState.data.userIds)
    }

    const usersIdNotInGroups = this.section.data.userIds.filter(
      (userId) => !userIdsInGroups.includes(userId)
    )

    for (const userId of usersIdNotInGroups) {
      const user = this.repository.userStore.getUser(userId)
      if (user) {
        groupDataSorted.push(
          new AssignmentGroupData({
            groupName: '',
            groupMembers: [userId],
            summary: RoomStateSummary.empty(this.repository),
            roomState: RoomState.empty(this.repository),
            roomStateStatus: RoomStateStatus.completed,

            engagementData: new Map(),
            quizScore: new Map(),
            sortingQuizScores: new Map(),

            sortingQuestions: new Map(),
            rubricResults: new Map(),
            users: [user],
          })
        )
      }
    }

    if (this.userFilters.length > 0) {
      return groupDataSorted.filter((groupData) => {
        return groupData.users.some((user) =>
          this.userFilters.every((filter) =>
            user.fullName.toLowerCase().includes(filter.toLowerCase())
          )
        )
      })
    }

    return groupDataSorted
  }

  @computed
  get usersNotInGroup(): PublicUser[] {
    const userIdsInGroups: string[] = []

    // Get all the userIds in groups.
    for (const roomState of this.roomStates.models) {
      userIdsInGroups.push(...roomState.data.userIds)
    }

    return (
      this.section.students
        // Filter out the users that are in groups.
        .filter((student) => !userIdsInGroups.includes(student.id))
        // Sort the users by first name and last name.
        .sort((a, b) => {
          if (a.data.firstName !== b.data.firstName) {
            return a.data.firstName.localeCompare(b.data.firstName)
          }
          return a.data.lastName.localeCompare(b.data.lastName)
        })
    )
  }

  @computed
  get emptyGroupWithAllUsersNotInGroup(): AssignmentGroupData {
    return new AssignmentGroupData({
      groupName: '',
      groupMembers: this.usersNotInGroup.map((user) => user.id),
      summary: RoomStateSummary.empty(this.repository),
      roomState: RoomState.empty(this.repository),
      roomStateStatus: RoomStateStatus.completed,
      engagementData: new Map(),
      quizScore: new Map(),
      sortingQuizScores: new Map(),
      sortingQuestions: new Map(),
      rubricResults: new Map(),
      users: this.usersNotInGroup,
    })
  }

  @computed
  get usersMap() {
    const usersMap: Map<string, PublicUser> = new Map()
    for (const user of this.section.students) {
      usersMap.set(user.id, user)
    }
    usersMap.set(this.section.instructor.id, this.section.instructor)
    return usersMap
  }

  get quizQuestions(): Map<SlideQuestion, RoomStateAnswer[]> {
    const quizQuestions: Map<SlideQuestion, RoomStateAnswer[]> = new Map()

    this.questionsCollection.models.forEach((question) => {
      if (
        question.questionType === SlideQuestionType.multipleChoice ||
        question.questionType === SlideQuestionType.sorting
      ) {
        const answers = this.roomStateAnswers.models.filter(
          (element) => element.data.slideQuestionId === question.id
        )
        if (answers.length > 0) {
          quizQuestions.set(question, answers)
        }
      }
    })

    return this.sortQuestions(quizQuestions)
  }

  async observeRoom(roomState: RoomState) {
    return observeRoom(this.repository, roomState)
  }

  // get multiple choice questions only
  @computed
  get multipleChoiceQuestions(): Map<SlideQuestion, RoomStateAnswer[]> {
    const quizQuestions: Map<SlideQuestion, RoomStateAnswer[]> = new Map()

    this.questionsCollection.models.forEach((question) => {
      if (question.questionType === SlideQuestionType.multipleChoice) {
        const answers = this.roomStateAnswers.models.filter(
          (element) => element.data.slideQuestionId === question.id
        )
        if (answers.length > 0) {
          quizQuestions.set(question, answers)
        }
      }
    })

    return this.sortQuestions(quizQuestions)
  }

  // get the number of answers for each question and unique user
  @computed
  get uniqueMultipleChoiceAnswerCount(): number {
    const multipleChoice = this.multipleChoiceQuestions
    const multipleChoiceValues = Array.from(multipleChoice.values())

    const count = multipleChoiceValues.reduce<number>((acc, answers) => {
      const usersAnswered = new Set(answers.map((a) => a.data.userId)).size
      return acc + usersAnswered
    }, 0)

    return count
  }

  // get question with the most wrong answers
  // this is defined as the answer.data.answer !== question.data.correct
  @computed
  get multipleChoiceQuestionWithMostWrongAnswers(): SlideQuestion | undefined {
    let questionWithMostWrongAnswers: SlideQuestion | undefined
    let maxWrongAnswers = 0

    this.multipleChoiceQuestions.forEach((answers, question) => {
      let wrongAnswers = 0
      for (const answer of answers) {
        if (answer.data.answer !== question.data.correct) {
          wrongAnswers++
        }
      }
      if (wrongAnswers > maxWrongAnswers || !questionWithMostWrongAnswers) {
        maxWrongAnswers = wrongAnswers
        questionWithMostWrongAnswers = question
      }
    })

    return questionWithMostWrongAnswers
  }

  // get the most common wrong answer for the above question
  @computed
  get mostCommonWrongAnswer(): number {
    const multipleChoice = this.multipleChoiceQuestions
    const question = this.multipleChoiceQuestionWithMostWrongAnswers

    if (!question) return 0

    const wrongAnswers: Map<number, number> = new Map()

    multipleChoice.get(question)?.forEach((answer) => {
      if (answer.data.answer !== question.data.correct) {
        wrongAnswers.set(
          answer.data.answer,
          (wrongAnswers.get(answer.data.answer) || 0) + 1
        )
      }
    })

    let mostCommonAnswer = 0
    let maxCount = 0

    wrongAnswers.forEach((count, answer) => {
      if (count > maxCount) {
        maxCount = count
        mostCommonAnswer = answer
      }
    })

    return mostCommonAnswer
  }

  @computed
  get multipleChoicePerformance(): Map<string, number> {
    const counts: Map<string, number> = new Map()
    const performance: Map<string, number> = new Map()
    const multipleChoice = this.multipleChoiceQuestions

    multipleChoice.forEach((answers, question) => {
      for (const answer of answers) {
        if (answer.data.answer === question.data.correct) {
          counts.set(
            answer.data.userId,
            (counts.get(answer.data.userId) || 0) + 1
          )
        } else {
          if (!counts.has(answer.data.userId)) {
            counts.set(answer.data.userId, 0)
          }
        }
      }
    })

    // Calculate the percentage of correct answers
    counts.forEach((count, userId) => {
      performance.set(userId, count / multipleChoice.size)
    })

    return performance
  }

  @computed
  get multipleChoiceHistogram(): Record<string, number> {
    const histogram: { [key: string]: number } = {
      '20 %': 0,
      '40 %': 0,
      '60 %': 0,
      '80 %': 0,
      '100 %': 0,
    }

    const performance = this.multipleChoicePerformance

    performance.forEach((percentage) => {
      if (percentage < 0.2) {
        histogram['20 %'] += 1
      } else if (percentage < 0.4) {
        histogram['40 %'] += 1
      } else if (percentage < 0.6) {
        histogram['60 %'] += 1
      } else if (percentage < 0.8) {
        histogram['80 %'] += 1
      } else {
        histogram['100 %'] += 1
      }
    })

    return histogram
  }

  /**
   * Returns a map for roomState and the rubric scores.
   * We will calculate the room's score using the same
   * method as getRubricScore but the numerator will be
   * the max score for each rubric within this room
   * and the denominator will be the total number of rubrics.
   */
  @computed
  get rubricScores(): Map<RoomState, number> {
    const roomRubricScores: Map<RoomState, number> = new Map()
    const rubricScoresByRoom: Map<
      string,
      Map<SlideRubric, number[]>
    > = new Map()

    this.rubrics.forEach((rubricResults, rubric) => {
      const thisRubricScoreByRoom: Map<string, number> = new Map()

      rubricResults.forEach((rubricResult) => {
        // if rubricResult.score.index is larger than the rubricScoresByRoom
        // then add it to the map

        if (
          !thisRubricScoreByRoom.has(rubricResult.data.roomId) ||
          thisRubricScoreByRoom.get(rubricResult.data.roomId)! <
            rubricResult.data.score
        ) {
          thisRubricScoreByRoom.set(
            rubricResult.data.roomId,
            rubricResult.data.score
          )
        }
      })

      // append the thisRubricScoreByRoom value to the respective
      // rubricScoresByRoom value array

      thisRubricScoreByRoom.forEach((score, roomId) => {
        if (!rubricScoresByRoom.has(roomId)) {
          rubricScoresByRoom.set(roomId, new Map([[rubric, [score]]]))
        } else if (!rubricScoresByRoom.get(roomId)!.has(rubric)) {
          rubricScoresByRoom.get(roomId)!.set(rubric, [score])
        } else {
          rubricScoresByRoom.get(roomId)!.get(rubric)!.push(score)
        }
      })
    })

    // calculate the score for each room
    // the numerator is the sum of the max scores for each rubric
    // the denominator is the number of rubrics

    rubricScoresByRoom.forEach((rubrics, roomId) => {
      let numerator = 0
      let denominator = 0

      rubrics.forEach((scores, rubric) => {
        scores.forEach((score) => {
          numerator += Math.pow(
            score *
              RubricType.getRubricTypeNormalizationFactor(rubric.rubricType!),

            RubricType.getRubricWeightingExponent(rubric.rubricType!)
          )

          denominator += Math.pow(
            RubricType.getRubricExpectedGroupScore(rubric.rubricType!) *
              RubricType.getRubricTypeNormalizationFactor(rubric.rubricType!),

            RubricType.getRubricWeightingExponent(rubric.rubricType!)
          )
        })
      })

      // if denominator is less than 1 then set it to 1

      if (denominator < 1) {
        denominator = 1
      }

      const roomState = this.roomStates.models.find(
        (element) => element.id === roomId
      )

      if (roomState) {
        roomRubricScores.set(roomState, Math.min(numerator / denominator, 1))
      }
    })

    return roomRubricScores
  }

  @computed
  get groupRubricHistogramWithRoomStates(): Record<string, RoomState[]> {
    const histogram: { [key: string]: RoomState[] } = {
      '20 %': [],
      '40 %': [],
      '60 %': [],
      '80 %': [],
      '100 %': [],
    }

    this.rubricScores.forEach((percentage, roomState) => {
      if (percentage < 0.2) {
        histogram['20 %'].push(roomState)
      } else if (percentage < 0.4) {
        histogram['40 %'].push(roomState)
      } else if (percentage < 0.6) {
        histogram['60 %'].push(roomState)
      } else if (percentage < 0.8) {
        histogram['80 %'].push(roomState)
      } else {
        histogram['100 %'].push(roomState)
      }
    })

    // Sort the lists by values descending

    Object.entries(histogram).forEach(([, values]) => {
      values.sort((a, b) => {
        const aIndex = this.rubricScores.get(a) ?? 0

        const bIndex = this.rubricScores.get(b) ?? 0

        return bIndex - aIndex
      })
    })

    return histogram
  }

  // create a map just like the one above but with just the length of
  // the value array and the same key
  @computed
  get groupRubricHistogram(): Record<string, number> {
    const histogram: { [key: string]: number } = {}
    // loop over groupRubricHistogramWithRoomStates and put length of value array in histogram
    Object.entries(this.groupRubricHistogramWithRoomStates).forEach(
      ([key, value]) => {
        histogram[key] = value.length
      }
    )
    return histogram
  }

  // count the number of rubricResponses we have
  @computed
  get rubricResponseCount(): number {
    return this.roomStateRubricResults.length
  }

  getRubricResultsForRoom = (roomId: string) => {
    const results: Map<SlideRubric, Array<RoomStateRubricResult>> = new Map()
    for (const [key, value] of this.rubrics.entries()) {
      const rubricResults = value.filter(
        (result) => result.data.roomId === roomId
      )
      if (rubricResults.length > 0) results.set(key, rubricResults)
    }
    return results
  }

  getRubricResultDetailsForRoom = (roomId: string) => {
    return this.roomStateRubricResultDetails.models.filter((result) => {
      return result.data.roomId === roomId
    })
  }

  async updateAssignment({
    expiresAt,
    assignedAt,
    groupingType,
    groupingSize,
  }: {
    expiresAt: DateTime
    assignedAt: DateTime
    groupingType?: AssignmentGroupingType
    groupingSize?: number
  }) {
    const payload: {
      sectionId: string
      assignmentId: string
      expiresAt: DateTime
      assignedAt: DateTime
      groupingType?: AssignmentGroupingType
      groupingSize?: number
    } = {
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
      expiresAt,
      assignedAt,
    }
    if (typeof groupingType === 'number') {
      payload.groupingType = groupingType
    }
    if (typeof groupingSize === 'number') {
      payload.groupingSize = groupingSize
    }
    await updateSectionAssignment(this.repository, payload)
  }

  async updateAssignmentGrouping({
    groupingType,
    groupingSize,
  }: {
    groupingType: AssignmentGroupingType
    groupingSize?: number
  }) {
    await updateSectionAssignmentGrouping(this.repository, {
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
      groupingType,
      groupingSize,
    })
  }

  async markAsActive() {
    return updateSectionAssignmentState(this.repository, {
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
      state: AssignmentState.active,
    })
  }

  addUserToGroup = async (params: {
    userId: string
    roomId: string
    isGroupLeader: boolean
  }) => {
    await roomStateAddUser(this.repository.firestore, params)
  }

  async removeUserFromGroup(params: { userId: string; roomId: string }) {
    await leaveRoom(this.repository.firestore, params)
  }

  async sendTestEmail() {
    await sendTestInstructorEmail(this.repository, {
      sectionId: this.sectionId,
      assignmentId: this.assignmentId,
    })
  }

  sortQuestions(questions: Map<SlideQuestion, Array<RoomStateAnswer>>) {
    const sortedQuestions: typeof questions = new Map()
    const slideQuestions = Array.from(questions.keys())
    const slideIds = this.slideDeckSlides.models.map((s) => s.id)
    slideQuestions.sort((a, b) => {
      const aIndex = slideIds.indexOf(a.data.slideId ?? '')
      const bIndex = slideIds.indexOf(b.data.slideId ?? '')
      // if they are equal then sort by question field
      if (aIndex === bIndex) {
        return a.data.question.localeCompare(b.data.question)
      }
      return aIndex - bIndex
    })
    for (const question of slideQuestions) {
      const answers = questions.get(question) ?? []
      sortedQuestions.set(question, answers)
    }
    return sortedQuestions
  }

  /**
   * to be consumed by the Array.sort function
   */
  private sortAssignmentGroupData(
    a: AssignmentGroupData,
    b: AssignmentGroupData
  ) {
    if (!a.roomState || a.roomState.isEmpty) return 1
    if (!b.roomState || b.roomState.isEmpty) return -1

    // if room state has no users, sort it last
    // if both room states have no users, continue
    if (a.roomState.userCount !== b.roomState.userCount) {
      if (a.roomState.data.userIds.length === 0) return 1
      if (b.roomState.data.userIds.length === 0) return -1
    }

    if (a.roomStateStatus !== b.roomStateStatus) {
      return a.roomStateStatus - b.roomStateStatus
    }
    if (
      a.roomState?.data.activeSlideChangedAt &&
      b.roomState?.data.activeSlideChangedAt
    ) {
      return (
        a.roomState.data.activeSlideChangedAt.getTime() -
        b.roomState.data.activeSlideChangedAt.getTime()
      )
    }
    if (a.roomState && b.roomState) {
      return a.roomState.roomStateName.localeCompare(
        b.roomState.roomStateName,
        undefined,
        {
          numeric: true,
          sensitivity: 'base',
        }
      )
    }
    return 0
  }
}

export class AssignmentGroupData {
  public absentUsers: PublicUser[]
  public engagementData: Map<string, RoomStateEngagement>
  public groupLeader?: string
  public groupName?: string // this should render as `not in a group` if undefined
  public groupMembers: string[]
  public groupSortingAnswerScores: Map<string, number>
  public roomStateStatus: RoomStateStatus
  public roomState?: RoomState
  public quizScore: Map<string, number>
  public rubricResults: Map<string, Map<SlideRubric, RoomStateRubricResult[]>>
  public summary?: RoomStateSummary
  public sortingQuizScores: Map<string, Map<string, number>>
  public sortingQuestions: Map<string, SlideQuestion>
  public users: PublicUser[]

  constructor({
    absentUsers = [],
    groupName,
    groupMembers,
    roomState,
    summary,
    engagementData = new Map(),
    groupLeader,
    groupSortingAnswerScores = new Map(),
    quizScore = new Map(),
    sortingQuizScores = new Map(),
    sortingQuestions = new Map(),
    roomStateStatus,
    rubricResults = new Map(),
    users = [],
  }: {
    absentUsers?: PublicUser[]
    groupName: string
    groupMembers: string[]
    roomState: RoomState
    summary: RoomStateSummary
    engagementData?: Map<string, RoomStateEngagement>
    groupLeader?: string
    groupSortingAnswerScores?: Map<string, number>
    quizScore?: Map<string, number>
    sortingQuizScores?: Map<string, Map<string, number>>
    sortingQuestions?: Map<string, SlideQuestion>
    roomStateStatus: RoomStateStatus
    rubricResults?: Map<string, Map<SlideRubric, RoomStateRubricResult[]>>
    users: PublicUser[]
  }) {
    this.absentUsers = absentUsers
    this.groupName = groupName
    this.groupMembers = groupMembers
    this.roomState = roomState
    this.summary = summary
    this.engagementData = engagementData
    this.groupLeader = groupLeader
    this.groupSortingAnswerScores = groupSortingAnswerScores
    this.quizScore = quizScore
    this.sortingQuizScores = sortingQuizScores
    this.sortingQuestions = sortingQuestions
    this.roomStateStatus = roomStateStatus
    this.rubricResults = rubricResults
    this.users = users
  }

  getResultsByUserIdForRubric(rubric: SlideRubric) {
    const resultsPerUser = new Map<string, RoomStateRubricResult>()
    for (const [userId, rubricResults] of this.rubricResults.entries()) {
      const rubricResult = rubricResults.get(rubric)
      if (rubricResult && rubricResult.length > 0) {
        resultsPerUser.set(userId, rubricResult[0])
      }
    }
    return resultsPerUser
  }
}

function roomStateName({
  roomState,
  section,
  usersMap,
}: {
  roomState: RoomState
  section: Section
  usersMap: Record<string, PublicUser>
}) {
  let groupName = roomState.roomStateName
  if (!groupName) {
    // set group name to the name of the first group leader
    // is the first groupLeaderUserIds in the usersMap?
    // if not check the instructor's userId and get the full name
    if (roomState.groupLeaderUserIds.length) {
      const groupLeaderId = roomState.groupLeaderUserIds[0]
      const groupLeader = usersMap[groupLeaderId]
      if (groupLeader) {
        groupName = groupLeader.fullName
      } else if (roomState.groupLeaderUserIds.includes(section.instructor.id)) {
        groupName = section.instructor.fullName
      }
    }
  }
  return groupName ?? 'Group has no name'
}

export type RubricResultWithUser = RoomStateRubricResult & { user: PublicUser }
