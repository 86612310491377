import { FieldValue, Timestamp } from 'firebase/firestore'
import z from 'zod'

export const firestoreTimestamp = z
  .object({
    seconds: z.number(),
    nanoseconds: z.number(),
  })
  .transform((val) => new Timestamp(val.seconds, val.nanoseconds).toDate())

export const nullSafeOptionalFirestoreTimestamp = () => {
  return z.preprocess((val) => val ?? undefined, firestoreTimestamp.optional())
}

export const nullSafeFirestoreTimestamp = (defaultValue: Date = new Date()) => {
  return z.preprocess(
    (val) =>
      (val as {
        seconds: number
        nanoseconds: number
      }) ?? {
        seconds: defaultValue.getTime() / 1000,
        nanoseconds: 0,
      },
    firestoreTimestamp
  )
}

export const firestoreWriteTimestamp = z
  .date()
  .or(
    z.object({
      _methodName: z.enum(['serverTimestamp']),
    })
  )
  .or(z.custom((val) => val instanceof FieldValue, { message: 'FieldValue' }))
  .transform((val) => {
    return val as FieldValue | Date
  })

export const nullSafeString = (defaultValue: string) => {
  return z
    .string()
    .nullish()
    .optional()
    .transform((val) => (val as string) ?? defaultValue)
}

export const nullSafeNumber = (defaultValue: number) => {
  return z
    .number()
    .nullish()
    .optional()
    .transform((val) => (val as number) ?? defaultValue)
}

export const nullSafeBoolean = (defaultValue: boolean) => {
  return z
    .boolean()
    .nullish()
    .optional()
    .transform((val) => (val as boolean) ?? defaultValue)
}

export const nullSafeStringArray = (defaultValue: string[]) => {
  return z
    .array(z.string())
    .nullish()
    .optional()
    .transform((val) => (val as string[]) ?? defaultValue)
}

export const nullSafeNumberArray = (defaultValue: string[]) => {
  return z
    .array(z.number())
    .nullish()
    .optional()
    .transform((val) => (val as number[]) ?? defaultValue)
}

export const nullSafeOptionalString = (defaultValue?: string) => {
  const context = z.string().optional()
  return z.preprocess(
    (val) => val ?? undefined,
    defaultValue !== undefined ? context.default(defaultValue) : context
  )
}

export const nullSafeOptionalBoolean = (defaultValue?: boolean) => {
  const context = z.boolean().optional()
  return z.preprocess(
    (val) => val ?? undefined,
    defaultValue !== undefined ? context.default(defaultValue) : context
  )
}

export const nullSafeOptionalNumber = (defaultValue?: number) => {
  const context = z.number().optional()
  return z.preprocess(
    (val) => val ?? undefined,
    defaultValue !== undefined ? context.default(defaultValue) : context
  )
}

export const nullSafeOptionalNumberArray = (defaultValue?: number[]) => {
  const context = z.array(z.number()).optional()
  return z.preprocess(
    (val) => val ?? undefined,
    defaultValue !== undefined ? context.default(defaultValue) : context
  )
}

export const nullSafeOptionalStringArray = (defaultValue?: string[]) => {
  const context = z.array(z.string()).optional()
  return z.preprocess(
    (val) => val ?? undefined,
    defaultValue !== undefined ? context.default(defaultValue) : context
  )
}
