import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  PartialWithFieldValue,
  Query,
} from 'firebase/firestore'
import {
  and,
  collection,
  collectionGroup,
  doc,
  query,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { StaticModelCollection } from '../../firestore-mobx/model'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreRoomStateProfessorFeedback } from './schema'
import { empty, schema, writeSchema } from './schema'
import { RoomStateProfessorFeedback } from '../../models/RoomStateProfessorFeedback'

const converter: FirestoreDataConverter<FirestoreRoomStateProfessorFeedback> = {
  toFirestore: (
    data: PartialWithFieldValue<FirestoreRoomStateProfessorFeedback>
  ) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: { roomId: string }
): CollectionReference<FirestoreRoomStateProfessorFeedback> => {
  return collection(
    firestore,
    'room_state',
    params.roomId,
    'professor_feedback'
  ).withConverter(converter)
}

// @ts-expect-error - unused for now
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDocRef = (
  firestore: Firestore,
  roomId: string,
  userId: string
): DocumentReference<FirestoreRoomStateProfessorFeedback, DocumentData> => {
  const collection = getColRef(firestore, { roomId })
  return doc(collection, userId)
}

function getColGroupRef(
  firestore: Firestore
): Query<FirestoreRoomStateProfessorFeedback> {
  return collectionGroup(firestore, 'professor_feedback').withConverter(
    converter
  )
}

export const buildEmptyRoomStateProfessorFeedbackCollection = (
  repository: FirebaseRepository
) => {
  return new StaticModelCollection({
    repository,
    model: RoomStateProfessorFeedback,
    empty: empty,
  })
}

export const getRoomStateProfessorFeedbackStreamForAssignment = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  return modelListStream(repository, q, RoomStateProfessorFeedback)
}
