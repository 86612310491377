import { z } from 'zod'
import { nullSafeOptionalFirestoreTimestamp } from '../shared/schema'

export const schema = z.object({
  organizationId: z.string(),
  userId: z.string(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    organizationId: '',
    userId: '',
  })
)

export type FirestoreOrganizationAdmin = z.infer<typeof schema>
