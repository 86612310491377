import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'
import { SlideDeckReferenceType } from '../../types'

const slideDeckReferenceTypeSchema = z.nativeEnum(SlideDeckReferenceType)

export const schema = z.object({
  referenceAuthor: z.string(),
  referenceOrder: z.number(),
  referencePublisher: z.string(),
  referenceTitle: z.string(),
  referenceType: z.preprocess(
    (val) => val ?? SlideDeckReferenceType.alternativeTo,
    slideDeckReferenceTypeSchema
  ),
  referenceURL: z.string(),
  publishedAt: nullSafeFirestoreTimestamp(new Date()),
  referenceCitation: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    referenceAuthor: '',
    referencePublisher: '',
    referenceTitle: '',
    referenceOrder: 999,
    referenceType: SlideDeckReferenceType.alternativeTo,
    referenceURL: '',
    publishedAt: { seconds: 0, nanoseconds: 0 },
  })
)

export type FirestoreSlideDeckReference = z.infer<typeof schema>
export type FirestoreSlideDeckReferenceWrite = z.infer<typeof writeSchema>
