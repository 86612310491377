import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirebaseRepository } from './FirebaseRepository'
import {
  defaultTokenPrice,
  empty,
  type FirestoreSettingsPricing,
} from '../firestore/SettingsPricing/schema'

export class SettingsPricing extends ObservableModelWithDecorators<FirestoreSettingsPricing> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSettingsPricing>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get tokenPriceInt() {
    const priceStr = this.data?.tokenPrice ?? defaultTokenPrice
    return parseInt(priceStr)
  }
}
