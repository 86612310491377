import type { DocumentReference } from 'firebase/firestore'
import {
  collection,
  doc,
  query,
  serverTimestamp,
  where,
  writeBatch,
  type CollectionReference,
  type DocumentData,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  type FirestoreSettingsSlideDeckWrite,
  SettingsSlideDeckTagStatus,
  schema,
  type FirestoreSettingsSlideDeckTag,
} from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import { SettingsSlideDeckTag } from '../../models/SettingsSlideDeckTag'
import { setDocWithError, updateDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreSettingsSlideDeckTag> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreSettingsSlideDeckTag, DocumentData> => {
  return collection(
    firestore,
    'settings',
    'slide_deck_fields',
    'tags'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  id: string
): DocumentReference<FirestoreSettingsSlideDeckTag, DocumentData> => {
  return doc(
    firestore,
    'settings',
    'slide_deck_fields',
    'tags',
    id
  ).withConverter(converter)
}

export const getSettingsSlideDeckTags = (
  repository: FirebaseRepository,
  options?: { showHidden?: boolean }
) => {
  const showHidden = options?.showHidden ?? false
  const ref = getColRef(repository.firestore)
  const q = showHidden ? ref : query(ref, where('tagStatus', '==', 0))
  return modelListStream(repository, q, SettingsSlideDeckTag)
}

export const getSettingsSlideDeckTag = (
  repository: FirebaseRepository,
  { id }: { id: string }
) => {
  const ref = getDocRef(repository.firestore, id)
  return modelItemStream(repository, ref, SettingsSlideDeckTag)
}

export const hideSettingsSlideDeckTag = async (
  repository: FirebaseRepository,
  { id }: { id: string }
) => {
  const ref = getDocRef(repository.firestore, id)
  await updateDocWithError(
    ref,
    { tagStatus: SettingsSlideDeckTagStatus.hidden },
    'HideSettingsSlideDeckTagError'
  )
}

export const saveSettingsSlideDeckTag = async (
  repository: FirebaseRepository,
  {
    id,
    tagName,
    batch,
  }: {
    id?: string
    batch?: ReturnType<typeof writeBatch>
    tagName: string
  }
) => {
  const ref = id
    ? getDocRef(repository.firestore, id)
    : doc(getColRef(repository.firestore))
  const updateData: Partial<FirestoreSettingsSlideDeckWrite> = {
    tagName,
    updatedAt: serverTimestamp(),
  }
  if (!id) updateData.tagStatus = SettingsSlideDeckTagStatus.active
  if (batch) return batch.set(ref, updateData, { merge: true })
  await setDocWithError(ref, updateData, {
    merge: true,
    errorName: 'SaveSettingsSlideDeckTagError',
  })
}

export const saveSettingsSlideDeckTags = async (
  repository: FirebaseRepository,
  { tags }: { tags: { tagName: string; id?: string }[] }
) => {
  const batch = writeBatch(repository.firestore)
  tags.forEach(({ tagName, id }) => {
    saveSettingsSlideDeckTag(repository, { id, tagName, batch })
  })
  await batch.commit()
}
