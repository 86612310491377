import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreTeachingPlanModule } from '../firestore/TeachingPlanModule/schema'
import { emptyTeachingPlanModule } from '../firestore/TeachingPlanModule/schema'
import { fetchTeachingPlanModuleSlideCount } from '../firestore/TeachingPlanModuleSlideDeck'
import type { FirebaseRepository } from './FirebaseRepository'

export class TeachingPlanModule extends ObservableModelWithDecorators<FirestoreTeachingPlanModule> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, emptyTeachingPlanModule)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, emptyTeachingPlanModule)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreTeachingPlanModule>
  ) {
    super(repository, doc)
  }

  async fetchExperienceCount() {
    const count = await fetchTeachingPlanModuleSlideCount(this.repository, {
      catalogId: this.data.catalogId,
      teachingPlanId: this.data.teachingPlanId,
      moduleId: this.id,
    })

    return count
  }
}
