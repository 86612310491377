import { collectionGroup, orderBy, query, where } from 'firebase/firestore'
import {
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreSlideDeckRubricTestResult } from './schema'
import { schema } from './schema'
import { SlideDeckRubricTestResult } from '../../models/SlideDeckRubricTestResult'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSlideDeckRubricTestResult> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (firestore: Firestore) => {
  return collectionGroup(firestore, 'rubric_test_result').withConverter(
    converter
  )
}

export const getSlideDeckRubricTestResults = (
  repository: FirebaseRepository,
  rubricId: string
) => {
  const ref = getColRef(repository.firestore)

  const queryRef = query(
    ref,
    where('rubricId', '==', rubricId),
    where('currentTest', '==', true),
    orderBy('updatedAt', 'desc')
  )

  return modelListStream(repository, queryRef, SlideDeckRubricTestResult)
}
