import z from 'zod'
import {
  firestoreTimestamp,
  // firestoreTimestamp,
  firestoreWriteTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

// - `userPromotionRedemptionId` is UUID
// - `userId` string
// - `promotionId` string
// - `userPromotionId` string
// - `sectionId` optional string
// - `assignmentId` optional string
// - `roomId` optional string
// - `updatedAt` timestamp
export const schema = z.object({
  userId: z.string(),
  promotionId: z.string(),
  userPromotionId: z.string(),
  sectionId: nullSafeOptionalString(),
  assignmentId: nullSafeOptionalString(),
  roomId: nullSafeOptionalString(),
  // the sample seed fails to add this field
  updatedAt: z.preprocess(
    (val) =>
      val ?? {
        seconds: 0,
        nanoseconds: 0,
      },
    firestoreTimestamp
  ),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    userId: '',
    promotionId: '',
    userPromotionId: '',
    sectionId: null,
    assignmentId: null,
    roomId: null,
  })
)

export type FirestoreUserPromotionRedemption = z.infer<typeof schema>
