import { RoomStateVideoMethod } from '../../../types'
import type { PreviewMeetingCubit } from '../../PreviewMeetingCubit'
import { MeetingDataFetcher } from '../MeetingDataFetcher'

export class PreviewDataFetcher extends MeetingDataFetcher {
  previewMeeting: PreviewMeetingCubit
  constructor(meeting: PreviewMeetingCubit) {
    super(meeting)
    this.previewMeeting = meeting
  }

  async start() {
    const slideDeckId = this.previewMeeting._slideDeckId
    // if we don't have a slide deck id we must have a room ID
    // so call start on the parent data fetcher to get all the room
    // state data
    if (slideDeckId === undefined) {
      return super.start()
    }
    if (this.meeting.useMockResults) {
      this.meeting.roomState.replaceData({
        //...this.meeting.roomState.data,
        activeExhibitId: undefined,
        activeSlide: 0,
        activeSlideChangedAt: new Date('Thu May 23 2024 15:18:31 GMT'),
        roomStartedAt: new Date('Thu May 23 2024 14:18:31 GMT'),
        groupLeaderUserIds: ['1'],
        userIds: ['1', '2', '3'],
        slideDeckId: slideDeckId,
        scheduledAt: new Date('Thu May 23 2024 14:00:00 GMT'),
        sectionId: '',
        updatedAt: new Date(),
        hiddenUserIds: [],
        videoMethod: RoomStateVideoMethod.streaming,
      })
    }

    this.fetchSlideDeck(slideDeckId)
    this.fetchExhibits(slideDeckId)
    this.fetchSlides(slideDeckId)
    this.fetchQuestions(slideDeckId)
    this.fetchSettingsProcessingVideo()
  }

  fetchUsers(/*userIds: string[]*/): Promise<void> {
    return Promise.resolve()
  }

  listenToQuestionAnswers(/*questionId: string*/) {
    return Promise.resolve()
  }

  getUserAnswers(): Promise<void> {
    return Promise.resolve()
  }

  getGroupAnswers(): Promise<void> {
    return Promise.resolve()
  }
}
