import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
} from 'firebase/firestore'
import {
  and,
  collection,
  collectionGroup,
  doc,
  query,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { RoomStateSummary } from '../../models/RoomStateSummary'
import type { FirestoreRoomStateSummary } from './schema'
import { schema } from './schema'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreRoomStateSummary> = {
  toFirestore: () => {
    throw new Error('Client cannot write FirestoreRoomStateSummary')
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: { roomId: string }
): CollectionReference<FirestoreRoomStateSummary> => {
  return collection(
    firestore,
    'room_state',
    params.roomId,
    'summary'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  roomId: string
): DocumentReference<FirestoreRoomStateSummary, DocumentData> => {
  return doc(getColRef(firestore, { roomId }), roomId)
}

function getColGroupRef(firestore: Firestore) {
  return collectionGroup(firestore, 'summary').withConverter(converter)
}

export const getRoomStateSummaryStream = (
  repository: FirebaseRepository,
  params: { roomId: string }
) => {
  const ref = getColRef(repository.firestore, params)

  return modelListStream(repository, ref, RoomStateSummary)
}

export const getRoomStateSummary = (
  repository: FirebaseRepository,
  params: { roomId: string }
) => {
  const ref = getDocRef(repository.firestore, params.roomId)

  return modelItemStream(repository, ref, RoomStateSummary)
}

export const getRoomStateSummaryStreamForAssignment = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const q = query(
    getColGroupRef(repository.firestore),
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  return modelListStream(repository, q, RoomStateSummary)
}
