import type {
  CollectionReference,
  DocumentData,
  PartialWithFieldValue,
} from 'firebase/firestore'
import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { FirestoreUserPromotionRedemption } from './schema'
import { schema, writeSchema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { UserPromotionRedemption } from '../../models/UserPromotionRedemption'
import type { StreamInterface } from 'tricklejs/dist/types'
import { modelListStream } from '../../firestore-mobx/stream'
import { addDocWithError } from '../../firestore-mobx/fetch'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreUserPromotionRedemption> = {
  toFirestore: (
    user: PartialWithFieldValue<FirestoreUserPromotionRedemption>
  ) => {
    return writeSchema.partial().parse(user)
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  userId: string,
  userPromotionId: string
): CollectionReference<FirestoreUserPromotionRedemption, DocumentData> => {
  return collection(
    firestore,
    'user_profile',
    userId,
    'user_promotion',
    userPromotionId,
    'user_promotion_redemption'
  ).withConverter(converter)
}

export const getUserPromotionRedemptions = (
  repository: FirebaseRepository,
  params: { userId: string; userPromotionId: string }
): StreamInterface<UserPromotionRedemption[]> => {
  const ref = getColRef(
    repository.firestore,
    params.userId,
    params.userPromotionId
  )

  return modelListStream(repository, ref, UserPromotionRedemption)
}

// - `userId` string
// - `promotionId` string
// - `userPromotionId` string
// - `sectionId` optional string
// - `assignmentId` optional string
// - `roomId` optional string
// - `updatedAt` timestamp

export const redeemPromotions = async (
  repository: FirebaseRepository,
  params: {
    userId: string
    promotionId: string
    userPromotionId: string
    sectionId?: string
    assignmentId?: string
    roomId?: string
  }
) => {
  const ref = getColRef(
    repository.firestore,
    params.userId,
    params.userPromotionId
  )

  return addDocWithError(
    ref,
    {
      userId: params.userId,
      promotionId: params.promotionId,
      userPromotionId: params.userPromotionId,
      sectionId: params.sectionId,
      assignmentId: params.assignmentId,
      roomId: params.roomId,
      updatedAt: new Date(),
    },
    'RedeemPromotionsError'
  )
}
