import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { PublicUser } from '../models/PublicUser'
import type { UserProfile } from '../models/UserProfile'

export class AppUser {
  uid: string
  repository: FirebaseRepository

  publicUser: PublicUser
  userProfile: UserProfile

  constructor(
    repository: FirebaseRepository,
    user: PublicUser,
    profile: UserProfile
  ) {
    this.uid = user.id
    this.repository = repository

    this.publicUser = user
    this.userProfile = profile
  }
}
