import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsFeatureFlags } from '../../models/SettingsFeatureFlags'
import type { FirestoreSettingsFeatureFlags } from './schema'
import { schema } from './schema'
import { getDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreSettingsFeatureFlags> = {
  toFirestore: (user: FirestoreSettingsFeatureFlags) => user,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return schema.parse(snapshot.data())
  },
}

export const getDocRef = (firestore: Firestore) => {
  return doc(
    collection(firestore, 'settings').withConverter(converter),
    'feature_flags'
  )
}

export const fetchSettingsFeatureFlags = async (
  repository: FirebaseRepository
) => {
  const docRef = getDocRef(repository.firestore)

  const doc = await getDocWithError(docRef, 'FetchSettingsFeatureFlagsError')

  return convertDocumentSnapshotToModel(repository, doc, SettingsFeatureFlags)
}

export const getSettingsFeatureFlags = (repository: FirebaseRepository) => {
  const docRef = getDocRef(repository.firestore)

  return modelItemStream(repository, docRef, SettingsFeatureFlags)
}
