import type {
  FieldValue,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  CollectionReference,
  collection,
  deleteField,
  doc,
  orderBy,
  query,
  serverTimestamp,
  writeBatch,
  type Firestore,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { TeachingPlanAuthor } from '../../models/TeachingPlanAuthor'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'
import type { FirestoreTeachingPlanAuthor } from './schema'
import { teachingPlanAuthorSchema } from './schema'

const converter: FirestoreDataConverter<FirestoreTeachingPlanAuthor> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return teachingPlanAuthorSchema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: {
    catalogId: string
    teachingPlanId: string
  }
): CollectionReference<FirestoreTeachingPlanAuthor> => {
  return collection(
    firestore,
    'catalog',
    params.catalogId,
    'teaching_plan',
    params.teachingPlanId,
    'author'
  ).withConverter(converter)
}

export const getTeachingPlanAuthors = (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId: params.catalogId,
    teachingPlanId: params.teachingPlanId,
  })
  const q = query(colRef, orderBy('order'))
  return modelListStream(repository, q, TeachingPlanAuthor)
}

export type AuthorFieldsForUpload = Omit<
  FirestoreTeachingPlanAuthor,
  'updatedAt' | 'order'
>

export const saveTeachingPlanAuthor = async (
  repository: FirebaseRepository,
  {
    authorFields,
    catalogId,
    teachingPlanId,
    authorId,
  }: {
    catalogId: string
    teachingPlanId: string
    authorFields: AuthorFieldsForUpload
    authorId?: string
  }
) => {
  interface WriteInterface
    extends Omit<FirestoreTeachingPlanAuthor, 'updatedAt'> {
    updatedAt: FieldValue
  }
  const dataToWrite: WriteInterface = {
    ...authorFields,
    updatedAt: serverTimestamp(),
  }

  if (authorId === undefined) {
    dataToWrite.order = 999
  }

  const ref = authorId
    ? doc(
        getColRef(repository.firestore, { catalogId, teachingPlanId }),
        authorId
      )
    : getColRef(repository.firestore, { catalogId, teachingPlanId })

  if (ref instanceof CollectionReference) {
    const teachingPlanAuthor = await addDocWithError(
      ref,
      dataToWrite,
      'CreateTeachingPlanAuthorError'
    )
    reorderTeachingPlanAuthors(repository, {
      catalogId,
      teachingPlanId,
    })
    return teachingPlanAuthor
  }

  return await updateDocWithError(
    ref,
    //@ts-expect-error - todo: not sure why the type
    dataToWrite,
    'UpdateTeachingPlanAuthorError'
  )
}

const reorderTeachingPlanAuthors = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId,
    teachingPlanId,
  })

  // Query to order by order
  const q = query(colRef, orderBy('order'))
  const docs = await getDocsWithError(q, 'FetchForTeachingPlanAuthorsError')
  const batch = writeBatch(repository.firestore)

  // Sort documents locally by order and then by updatedAt
  const sortedDocs = docs.docs.sort((a, b) => {
    const aData = a.data()
    const bData = b.data()

    const aOrder = aData.order !== undefined ? aData.order : 0
    const bOrder = bData.order !== undefined ? bData.order : 0

    if (aOrder === bOrder) {
      const aUpdatedAt = aData.updatedAt
        ? new Date(aData.updatedAt).getTime()
        : 0
      const bUpdatedAt = bData.updatedAt
        ? new Date(bData.updatedAt).getTime()
        : 0
      return aUpdatedAt - bUpdatedAt
    } else {
      return aOrder - bOrder
    }
  })

  // Reorder based on the locally sorted documents
  sortedDocs.forEach((doc, index) => {
    if (doc.data().order === index) return
    batch.update(doc.ref, { order: index })
  })

  await batch.commit()
}

export const uploadTeachingPlanAuthorImage = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    authorId,
    file,
  }: {
    catalogId: string
    teachingPlanId: string
    authorId: string
    file: File
  }
) => {
  const fileMimeType = file.type

  const storageRef = ref(
    repository.storage,
    `catalogs/teaching_plan/${teachingPlanId}/authors/${authorId}.jpg`
  )

  await uploadBytes(storageRef, file, {
    contentType: fileMimeType,
  })

  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  const authorRef = doc(
    getColRef(repository.firestore, { catalogId, teachingPlanId }),
    authorId
  )

  const authorUpdatePayload = {
    authorImageURL: urlWithoutToken,
  }

  await updateDocWithError(
    authorRef,
    authorUpdatePayload,
    'UploadTeachingPlanAuthorImageError'
  )

  return urlWithoutToken
}

export const deleteTeachingPlanAuthor = async (
  repository: FirebaseRepository,
  {
    authorId,
    catalogId,
    teachingPlanId,
  }: {
    authorId: string
    catalogId: string
    teachingPlanId: string
  }
) => {
  const deletePromises = [
    deleteTeachingPlanAuthorImage(repository, {
      catalogId,
      teachingPlanId,
      authorId,
    }),
    deleteDocWithError(
      doc(
        getColRef(repository.firestore, { catalogId, teachingPlanId }),
        authorId
      ),
      'DeleteTeachingPlanAuthorError'
    ),
  ]

  await Promise.all(deletePromises)
}

export const deleteTeachingPlanAuthorImage = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    authorId,
    skipFieldUpdates = false,
  }: {
    catalogId: string
    teachingPlanId: string
    authorId: string
    skipFieldUpdates?: boolean
  }
) => {
  const storageRef = ref(
    repository.storage,
    `catalogs/teaching_plan/${teachingPlanId}/authors/${authorId}.jpg`
  )

  await safeDeleteStorageObject(storageRef)

  if (skipFieldUpdates) return

  // update the material doc, delete the fields
  const deleteFieldValues = {
    authorImageURL: deleteField(),
  }

  const authorRef = doc(
    getColRef(repository.firestore, { catalogId, teachingPlanId }),
    authorId
  )

  await updateDocWithError(
    authorRef,
    deleteFieldValues,
    'DeleteTeachingPlanAuthorImageError'
  )
}

/**
 * Sort the authors in the teaching_plan by the order field and update
 * the order field with the index of the author in the list so that
 * the order is always correct and the authors are always sorted by
 * the order field when the teaching_plan is loaded from Firestore.
 *
 * if **oldIndex** and **newIndex** are supplied then move element at **oldIndex** to **newIndex**
 */

export const sortTeachingPlanAuthors = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const authorIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = authorIds.splice(oldIndex, 1)[0]

    //insert at new index
    authorIds.splice(newIndex, 0, targetId)
  }

  const batch = writeBatch(repository.firestore)
  authorIds.forEach((authorId, index) => {
    // get the author doc ref
    const authorRef = doc(
      getColRef(repository.firestore, { catalogId, teachingPlanId }),
      authorId
    )
    batch.update(authorRef, { order: index })
  })

  // perform the batch write
  await batch.commit()
}
