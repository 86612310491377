import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreOrganization,
} from '../firestore/Organization/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class Organization extends ObservableModelWithDecorators<FirestoreOrganization> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreOrganization>
  ) {
    super(repository, doc)

    makeObservable(this)
  }
}
