import z from 'zod'
import {
  firestoreTimestamp,
  nullSafeOptionalFirestoreTimestamp,
} from '../shared/schema'

export const schema = z.object({
  rubricId: z.string(),
  startedAt: nullSafeOptionalFirestoreTimestamp(),
  endedAt: firestoreTimestamp.nullable(),
  rubricTestId: z.string().nullable(),
})

export const empty = Object.freeze(
  schema.parse({
    rubricId: '',
    endedAt: null,
    rubricTestId: null,
  })
)

export type FirestoreSlideDeckRubricAI = z.infer<typeof schema>
