import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeString,
} from '../shared/schema'

// this is the dart definition
// required String id,
// int? createdAt,
// String? firstName,
// String? imageUrl,
// String? lastName,
// int? lastSeen,
// Map<String, dynamic>? metadata,
// Role? role,
// int? updatedAt,
export const schema = z.object({
  firstName: nullSafeString(''),
  lastName: nullSafeString(''),
  imageUrl: nullSafeOptionalString(''),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    firstName: '',
    lastName: '',
    updatedAt: { seconds: 0, nanoseconds: 0 },
  })
)

type FirestorePublicUserType = z.infer<typeof schema>
export interface FirestorePublicUser extends FirestorePublicUserType {}
