import z from 'zod'
import { nullSafeOptionalFirestoreTimestamp } from '../shared/schema'

// this is the dart definition
// required String token,
// required String url,
// int? createdAt,
export const schema = z.object({
  token: z.string(),
  url: z.string(),
  createdAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    token: '',
    url: '',
    createdAt: { seconds: 0, nanoseconds: 0 },
  })
)

export interface FirestoreUserProfileRoomToken extends z.infer<typeof schema> {}
