import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { AppUser } from '../../stores/AppUser'
import { fetchPublicUsers } from '../PublicUser'
import { fetchUserProfiles } from '../UserProfile'

export const fetchAppUsers = async (
  repository: FirebaseRepository,
  params: {
    userIds: string[]
  }
): Promise<AppUser[]> => {
  const publicUsers = await fetchPublicUsers(repository, params)
  const userProfiles = await fetchUserProfiles(repository, params)
  const publicUserLookup = new Map(publicUsers.map((user) => [user.id, user]))
  const userProfilesLookup = new Map(
    userProfiles.map((profile) => [profile.id, profile])
  )

  return params.userIds
    .map((userId) => {
      const publicUser = publicUserLookup.get(userId)
      const userProfile = userProfilesLookup.get(userId)

      if (!publicUser || !userProfile) {
        return null
      }

      return new AppUser(repository, publicUser, userProfile)
    })
    .filter((user): user is AppUser => user !== null)
}
