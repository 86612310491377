import type { FirebaseRepository } from './FirebaseRepository'
import type { MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  type FirestoreSectionAssignmentSlides,
  empty,
} from '../firestore/SectionAssignmentSlides/schema'

export class SectionAssignmentSlides extends ObservableModelWithDecorators<FirestoreSectionAssignmentSlides> {
  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSectionAssignmentSlides>
  ) {
    super(repository, doc)
  }

  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }
}
