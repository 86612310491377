import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsFeatureFlags } from '../../models/SettingsFeatureFlags'
import type { FirestoreSettingsFeatureFlags } from '../SettingsFeatureFlags/schema'
import { schema } from '../SettingsFeatureFlags/schema'
import { getDocWithError } from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreSettingsFeatureFlags> = {
  toFirestore: (user: FirestoreSettingsFeatureFlags) => user,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    return schema.parse(snapshot.data())
  },
}

const getDocRef = (firestore: Firestore) => {
  return doc(
    collection(firestore, 'settings').withConverter(converter),
    'feature_flags_beta'
  )
}

export const fetchSettingsFeatureFlagsBeta = async (
  repository: FirebaseRepository
) => {
  const docRef = getDocRef(repository.firestore)

  const doc = await getDocWithError(
    docRef,
    'FetchSettingsFeatureFlagsBetaError'
  )

  return convertDocumentSnapshotToModel(repository, doc, SettingsFeatureFlags)
}

export const getSettingsFeatureFlagsBeta = (repository: FirebaseRepository) => {
  const docRef = getDocRef(repository.firestore)

  return modelItemStream(repository, docRef, SettingsFeatureFlags)
}
