import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreRoomStateReset,
} from '../firestore/RoomStateReset/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class RoomStateReset extends ObservableModelWithDecorators<FirestoreRoomStateReset> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateReset>
  ) {
    super(repository, doc)
    makeObservable(this)
  }
}
