import type { DocumentData } from 'firebase/firestore'
import {
  CollectionReference,
  collection,
  deleteField,
  doc,
  serverTimestamp,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { FetchedCollection } from '../../firestore-mobx'
import {
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SlideDeckExhibit } from '../../models/SlideDeckExhibit'
import type {
  FirestoreSlideDeckExhibit,
  FirestoreSlideDeckExhibitWrite,
} from './schema'
import { schema } from './schema'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { safeDeleteStorageObject } from '../../util/safeDeleteStorageObject'

export * from './schema'

export type ExhibitFieldsForUpload = Omit<
  FirestoreSlideDeckExhibitWrite,
  'updatedAt' | 'exhibitHasThumbnail'
>

const converter: FirestoreDataConverter<FirestoreSlideDeckExhibit> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  slideDeckId: string
): CollectionReference<FirestoreSlideDeckExhibit, DocumentData> => {
  return collection(
    firestore,
    'slide_deck',
    slideDeckId,
    'exhibit'
  ).withConverter(converter)
}

export const buildFirestoreSlideDeckExhibitsFetchedCollection = (
  firestore: Firestore,
  slideDeckId: string
): FetchedCollection<FirestoreSlideDeckExhibit> => {
  const ref = getColRef(firestore, slideDeckId)

  return new FetchedCollection<FirestoreSlideDeckExhibit>(ref)
}

export const fetchSlideDeckExhibits = async (
  repository: FirebaseRepository,
  params: { slideDeckId: string }
): Promise<SlideDeckExhibit[]> => {
  const ref = getColRef(repository.firestore, params.slideDeckId)
  const snapshot = await getDocsWithError(ref, 'FetchSlideDeckExhibitsError')
  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, SlideDeckExhibit)
  })
}

export const getSlideDeckExhibits = (
  repository: FirebaseRepository,
  params: {
    slideDeckId: string
  }
) => {
  const ref = getColRef(repository.firestore, params.slideDeckId)
  return modelListStream(repository, ref, SlideDeckExhibit)
}

export const saveSlideDeckExhibit = async (
  repository: FirebaseRepository,
  {
    exhibitFields,
    slideDeckId,
    exhibitId,
  }: {
    slideDeckId: string
    exhibitFields: ExhibitFieldsForUpload
    exhibitId?: string
  }
) => {
  const dataToWrite: FirestoreSlideDeckExhibitWrite = {
    ...exhibitFields,
    updatedAt: serverTimestamp(),
  }

  const ref = exhibitId
    ? doc(getColRef(repository.firestore, slideDeckId), exhibitId)
    : getColRef(repository.firestore, slideDeckId)

  if (ref instanceof CollectionReference) {
    return await addDocWithError(
      ref,
      dataToWrite,
      'CreateSlideDeckExhibitError'
    )
  }

  return await updateDocWithError(
    ref,
    dataToWrite,
    'UpdateSlideDeckExhibitError'
  )
}

export const uploadSlideDeckExhibitImage = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    exhibitId,
    file,
  }: {
    slideDeckId: string
    exhibitId: string
    file: File
  }
) => {
  const fileMimeType = file.type

  const storageRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/exhibit/${exhibitId}`
  )

  await uploadBytes(storageRef, file, {
    contentType: fileMimeType,
  })

  const urlWithoutToken = (await getDownloadURL(storageRef)).replaceAll(
    /&token=[a-z0-9-]{36}/g,
    ''
  )

  const exhibitRef = doc(
    getColRef(repository.firestore, slideDeckId),
    exhibitId
  )

  const exhibitUpdatePayload = {
    exhibitURL: urlWithoutToken,
    exhibitHasThumbnail: false,
    exhibitImageFilename: file.name,
    updatedAt: serverTimestamp(),
  }

  await updateDocWithError(
    exhibitRef,
    exhibitUpdatePayload,
    'UploadSlideDeckExhibitImageError'
  )

  return urlWithoutToken
}

export const deleteSlideDeckExhibit = async (
  repository: FirebaseRepository,
  {
    exhibitId,
    slideDeckId,
  }: {
    exhibitId: string
    slideDeckId: string
  }
) => {
  const deletePromises = [
    deleteSlideDeckExhibitImage(repository, {
      slideDeckId,
      exhibitId,
      skipFieldUpdates: true,
    }),
    deleteDocWithError(
      doc(getColRef(repository.firestore, slideDeckId), exhibitId),
      'DeleteSlideDeckExhibitError'
    ),
  ]

  await Promise.all(deletePromises)
}

export const deleteSlideDeckExhibitImage = async (
  repository: FirebaseRepository,
  {
    slideDeckId,
    exhibitId,
    skipFieldUpdates = false,
  }: {
    slideDeckId: string
    exhibitId: string
    skipFieldUpdates?: boolean
  }
) => {
  const storageRef = ref(
    repository.storage,
    `slide_deck/${slideDeckId}/exhibit/${exhibitId}`
  )

  await safeDeleteStorageObject(storageRef)

  if (skipFieldUpdates) return

  // update the material doc, delete the fields
  const deleteFieldValues = {
    exhibitURL: deleteField(),
    exhibitHasThumbnail: deleteField(),
  }

  const exhibitRef = doc(
    getColRef(repository.firestore, slideDeckId),
    exhibitId
  )

  await updateDocWithError(
    exhibitRef,
    deleteFieldValues,
    'DeleteSlideDeckExhibitImageError'
  )
}
