import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type {
  FieldValue,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  CollectionReference,
} from 'firebase/firestore'
import {
  collection,
  doc,
  orderBy,
  query,
  serverTimestamp,
  type Firestore,
  type Transaction,
  writeBatch,
  collectionGroup,
  where,
  getCountFromServer,
} from 'firebase/firestore'
import type { FirestoreTeachingPlanModuleSlideDeck } from './schema'
import { teachingPlanModuleSlideDeckSchema } from './schema'
import {
  collectionSnapshots,
  convertDocumentSnapshotToModel,
  modelListStream,
} from '../../firestore-mobx/stream'
import { TeachingPlanModuleSlideDeck } from '../../models/TeachingPlanModuleSlideDeck'
import type {
  DeleteDocWithErrorsArgsMandatoryOptions,
  SetDocWithErrorsArgsMandatoryOptions,
} from '../../firestore-mobx/fetch'
import {
  deleteDocWithError,
  getDocsWithError,
  setDocWithError,
} from '../../firestore-mobx/fetch'

const converter: FirestoreDataConverter<FirestoreTeachingPlanModuleSlideDeck> =
  {
    toFirestore: (data) => data,
    fromFirestore: (snapshot: QueryDocumentSnapshot) => {
      const data = snapshot.data({ serverTimestamps: 'estimate' })
      return teachingPlanModuleSlideDeckSchema.parse(data)
    },
  }

const getColRef = (
  firestore: Firestore,
  params: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
  }
): CollectionReference<FirestoreTeachingPlanModuleSlideDeck> => {
  return collection(
    firestore,
    'catalog',
    params.catalogId,
    'teaching_plan',
    params.teachingPlanId,
    'module',
    params.moduleId,
    'module_slide_deck'
  ).withConverter(converter)
}

export const getTeachingPlanModuleSlideDecks = (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId: params.catalogId,
    teachingPlanId: params.teachingPlanId,
    moduleId: params.moduleId,
  })
  const q = query(colRef, orderBy('slideDeckOrder'))
  return modelListStream(repository, q, TeachingPlanModuleSlideDeck)
}

export const fetchTeachingPlanModuleSlideDecks = async (
  repository: FirebaseRepository,
  params: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId: params.catalogId,
    teachingPlanId: params.teachingPlanId,
    moduleId: params.moduleId,
  })
  const q = query(colRef, orderBy('slideDeckOrder'))
  const docs = await getDocsWithError(
    q,
    'FetchTeachingPlanModuleSlideDecksError'
  )

  return docs.docs.map((doc) => {
    return convertDocumentSnapshotToModel(
      repository,
      doc,
      TeachingPlanModuleSlideDeck
    )
  })
}

export const fetchTeachingPlanModuleSlideCount = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    moduleId,
  }: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
  }
) => {
  const colRef = getColRef(repository.firestore, {
    catalogId,
    teachingPlanId,
    moduleId,
  })

  const snapshot = await getCountFromServer(colRef)
  return snapshot.data().count
}

export type ModuleSlideDeckFieldsForUpload = Omit<
  FirestoreTeachingPlanModuleSlideDeck,
  'updatedAt'
>

export const saveTeachingPlanModuleSlideDeck = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    moduleId,
    slideDeckId,
    slideDeckOrder,
    slideDeckTypeId,
    transaction,
  }: {
    catalogId: string
    teachingPlanId: string
    slideDeckTypeId: string
    moduleId: string
    slideDeckId: string
    slideDeckOrder: number
    transaction?: Transaction
  }
) => {
  const setDocument = (...args: SetDocWithErrorsArgsMandatoryOptions) =>
    transaction ? transaction.set(...args) : setDocWithError(...args)

  interface WriteInterface
    extends Omit<FirestoreTeachingPlanModuleSlideDeck, 'updatedAt'> {
    updatedAt: FieldValue
  }

  const dataToWrite: WriteInterface = {
    catalogId,
    moduleId,
    slideDeckId,
    slideDeckTypeId,
    teachingPlanId,
    slideDeckOrder,
    updatedAt: serverTimestamp(),
  }

  const ref = doc(
    getColRef(repository.firestore, {
      catalogId,
      teachingPlanId,
      moduleId,
    }),
    slideDeckId
  )

  return await setDocument(ref, dataToWrite, {})
}

export const deleteTeachingPlanModuleSlideDeck = async (
  repository: FirebaseRepository,
  {
    catalogId,
    teachingPlanId,
    moduleId,
    transaction,
    slideDeckId,
  }: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
    slideDeckId: string
    transaction?: Transaction
  }
) => {
  const deleteDocument = (...args: DeleteDocWithErrorsArgsMandatoryOptions) =>
    transaction ? transaction.delete(args[0]) : deleteDocWithError(...args)

  await deleteDocument(
    doc(
      getColRef(repository.firestore, {
        catalogId,
        teachingPlanId,
        moduleId,
      }),
      slideDeckId
    ),
    'DeleteTeachingPlanModuleSlideDeckError'
  )
}

/**
 * Sort the modules in the teaching_plan by the slideDeckOrder field and update
 * the slideDeckOrder field with the index of the author in the list so that
 * the slideDeckOrder is always correct and the modules are always sorted by
 * the slideDeckOrder field when the teaching_plan is loaded from Firestore.
 *
 * if **oldIndex** and **newIndex** are supplied then move element at **oldIndex** to **newIndex**
 */

export const sortTeachingPlanModuleSlideDecks = async (
  repository: FirebaseRepository,
  {
    currentOrder,
    oldIndex,
    newIndex,
    catalogId,
    teachingPlanId,
  }: {
    catalogId: string
    teachingPlanId: string
    moduleId: string
    currentOrder: string[]
    oldIndex?: number
    newIndex?: number
  }
) => {
  const moduleIds = [...currentOrder]

  // if old index and new index supplied then reorder
  if (oldIndex !== undefined && newIndex !== undefined) {
    //remove at old index
    const targetId = moduleIds.splice(oldIndex, 1)[0]

    //insert at new index
    moduleIds.splice(newIndex, 0, targetId)
  }

  const batch = writeBatch(repository.firestore)
  moduleIds.forEach((moduleId, index) => {
    // get the author doc ref
    const authorRef = doc(
      getColRef(repository.firestore, { catalogId, teachingPlanId, moduleId }),
      moduleId
    )
    batch.update(authorRef, { slideDeckOrder: index })
  })

  // perform the batch write
  await batch.commit()
}

export const getSlideDeckModulesForSlideDeckType = (
  repository: FirebaseRepository,
  { slideDeckTypeId }: { slideDeckTypeId: string }
) => {
  const q = query(
    collectionGroup(repository.firestore, 'module_slide_deck'),
    where('slideDeckTypeId', '==', slideDeckTypeId)
  ).withConverter(converter)

  return collectionSnapshots(q).asyncMap(async (snapshot) => {
    const moduleSlideDecks = snapshot.docs.map((doc) => {
      return convertDocumentSnapshotToModel(
        repository,
        doc,
        TeachingPlanModuleSlideDeck
      )
    })
    return moduleSlideDecks
  })
}
