import type { MobxDocument } from '../firestore-mobx/document'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty, type FirestoreSlide } from '../firestore/Slide/schema'
import { SlideType } from '../firestore/Slide/types'
import type { FirebaseRepository } from './FirebaseRepository'
import { SlideQuestionType } from './SlideQuestionType'

// This is called SlideModel until the Slide type is removed
export class SlideModel extends ObservableModelWithDecorators<FirestoreSlide> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSlide>
  ) {
    super(repository, doc)
  }

  get compatibleQuestionTypes() {
    switch (this.data.slideType) {
      case SlideType.interactivePoll:
        return [
          SlideQuestionType.poll,
          SlideQuestionType.customPoll,
          SlideQuestionType.title,
        ]
      case SlideType.interactiveQuiz:
      case SlideType.soloQuiz:
        return [
          SlideQuestionType.multipleChoice,
          SlideQuestionType.sorting,
          SlideQuestionType.title,
        ]
      case SlideType.groupQuiz:
        return [SlideQuestionType.sorting, SlideQuestionType.title]
      default:
        return [SlideQuestionType.title]
    }
  }

  get canHaveImage() {
    return slideTypeCanHaveImage(this.data.slideType)
  }

  get canHaveVideo() {
    return this.data.slideType === SlideType.video
  }

  get hasImage() {
    return this.canHaveImage && this.data.slideImageURL
  }

  get hasVideo() {
    return this.data.slideType === SlideType.video && this.data.slideVideoURL
  }

  get durationOrErrorStr() {
    let value = this.data.slideDuration.toString()
    if (this.data.slideType === SlideType.video) {
      if (this.data.slideVideoError) {
        value = this.data.slideVideoError
      } else if (this.data.slideVideoDuration) {
        value = this.data.slideVideoDuration!.toString()
      } else {
        value = ''
      }
    }
    return value
  }

  get hasError() {
    return this.data.slideType === SlideType.video && this.data.slideVideoError
  }
}

export const slideTypeCanHaveImage = (type: SlideType) => {
  const eligible = [
    SlideType.professorFeedback,
    SlideType.discussion,
    SlideType.image,
  ]
  return eligible.includes(type)
}
