import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
} from '../shared/schema'

export enum SettingsSlideDeckTagStatus {
  hidden = -1,
  active = 0,
}

export const schema = z.object({
  tagName: z.string(),
  tagStatus: z.nativeEnum(SettingsSlideDeckTagStatus),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export const empty = Object.freeze(
  schema.parse({
    tagName: '',
    tagStatus: SettingsSlideDeckTagStatus.hidden,
  })
)

export type FirestoreSettingsSlideDeckTag = z.infer<typeof schema>
export type FirestoreSettingsSlideDeckWrite = z.infer<typeof writeSchema>
