import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreTeachingPlanAuthor } from '../firestore/TeachingPlanAuthor/schema'
import { emptyTeachingPlanAuthor } from '../firestore/TeachingPlanAuthor/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class TeachingPlanAuthor extends ObservableModelWithDecorators<FirestoreTeachingPlanAuthor> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, emptyTeachingPlanAuthor)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, emptyTeachingPlanAuthor)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreTeachingPlanAuthor>
  ) {
    super(repository, doc)
  }
}
