import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirebaseRepository } from './FirebaseRepository'
import {
  empty,
  type FirestoreRoomStateRubricResultDetail,
} from '../firestore/RoomStateRubricResultDetail'

export class RoomStateRubricResultDetail extends ObservableModelWithDecorators<FirestoreRoomStateRubricResultDetail> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateRubricResultDetail>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get rubricId() {
    return this.data.rubricId
  }
}
