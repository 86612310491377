import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { PublicUser } from '../models/PublicUser'
import { type RoomState } from '../models/RoomState'
import type { RoomStateRubricResult } from '../models/RoomStateRubricResult'
import { Section } from '../models/Section'
import type { SlideRubric } from '../models/SlideRubric'
import type { InvitationType } from '../types'
import {
  InstructorSectionView,
  SectionRequestState,
  SectionState,
} from '../types'

import type { StaticModelCollection } from '../firestore-mobx/model'
import { createStudentInvitation } from '../firestore/Invitation'
import { getPublicUsers } from '../firestore/PublicUser'
import {
  getSection,
  setSectionInvoiced,
  setSectionNotInvoiced,
  setSectionState,
  updateSection,
} from '../firestore/Section'
import { getSectionAssignments } from '../firestore/SectionAssignment'
import { getSlideDeck, getSlideDecksForCatalog } from '../firestore/SlideDeck'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SectionAssignment } from '../models/SectionAssignment'
import { SlideDeck } from '../models/SlideDeck'
import { InstructorAssignmentCubit } from './InstructorAssignmentCubit'
import { Cubit } from './core'
import { Catalog } from '../models/Catalog'
import { getCatalogsForUser } from '../firestore/Catalog'
import { RoomStateEngagement } from '../models/RoomStateEngagement'
import type { UserPromotion } from '../models/UserPromotion'
import { redeemPromotions } from '../firestore/UserPromotionRedemption'
import type { SectionRequest } from '../models/SectionRequest'
import type { FirestoreSectionRequest } from '../firestore/SectionRequest'
import {
  createSectionInvoiceRequest,
  getSectionRequestsBySectionId,
  SectionRequestType,
  withdrawSectionRequest,
} from '../firestore/SectionRequest'
import type { Organization } from '../models/Organization'
import { getOrganization } from '../firestore/Organization'
import type { SectionPromotion } from '../models/SectionPromotion'
import { getSectionPromotions } from '../firestore/SectionPromotion'
import { captureException } from '@sentry/core'

export class InstructorSectionCubit extends Cubit {
  repository: FirebaseRepository
  sectionId: string

  @observable selectedView: InstructorSectionView = InstructorSectionView.cases

  public section: Section
  public assignments: StaticModelCollection<SectionAssignment>
  public filterChips = observable.array<string>([])
  public users: StaticModelCollection<PublicUser>
  public slideDeckMap = observable.map<string, SlideDeck>({}, { deep: false })
  public catalogs: StaticModelCollection<Catalog>
  public assignmentCubits = observable.map<string, InstructorAssignmentCubit>(
    {},
    { deep: false }
  )

  public sectionInvoiceRequests = observable.array<SectionRequest>([])

  @observable
  public sectionOrganization: Organization | null = null

  @observable
  public promotions: SectionPromotion[] = []

  private instructorUserId?: string

  constructor(
    repository: FirebaseRepository,
    sectionId: string,
    {
      instructorUserId,
    }: {
      instructorUserId?: string
    } = {}
  ) {
    super()
    makeObservable(this)

    this.repository = repository
    this.sectionId = sectionId
    this.section = Section.empty(repository)
    this.assignments = SectionAssignment.emptyCollection(repository)
    this.users = PublicUser.emptyCollection(repository)
    this.catalogs = Catalog.emptyCollection(repository)
    this.instructorUserId = instructorUserId
  }

  initialize() {
    this.initializeSectionStream()
    this.initializeSectionRequestsStream()

    this.addStream(
      getSectionAssignments(this.repository, {
        sectionId: this.sectionId,
      }),
      (models) => {
        this.assignments.replaceModels(models)

        this.addSlideDeckStreams(models)
        this.loadAssignmentCubits()
      }
    )
  }

  withdrawSectionRequest = async (requestId: string) => {
    await withdrawSectionRequest(this.repository, this.sectionId, requestId)
  }

  createSectionInvoiceRequest = async ({
    sectionId,
    sectionRequest,
  }: {
    sectionId: string
    sectionRequest: Pick<
      FirestoreSectionRequest,
      | 'sectionRequestAssignmentCount'
      | 'sectionRequestStudentCount'
      | 'requestedAt'
      | 'organizationId'
      | 'sectionId'
      | 'updatedByUserId'
    >
  }) => {
    await createSectionInvoiceRequest(this.repository, sectionId, {
      ...sectionRequest,
      userId: this.repository.uid,
      sectionRequestType: SectionRequestType.invoice,
      sectionRequestState: SectionRequestState.pending,
      updatedAt: new Date(),
      requestedAt: new Date(),
    })
  }

  getOrganization = async () => {
    if (
      !this.section.data.organizationId ||
      this.hasStream('organization-stream')
    ) {
      return
    }

    this.addStream(
      getOrganization(this.repository, {
        organizationId: this.section.data.organizationId,
      }),
      (organization) => {
        runInAction(() => {
          this.sectionOrganization = organization
        })
      },
      { name: 'organization-stream' }
    )
  }

  @computed
  get activeSectionRequest() {
    const latestRequest = this.sectionInvoiceRequests?.[0]

    if (!latestRequest) return null
    if (
      latestRequest.data.sectionRequestState === SectionRequestState.withdrawn
    ) {
      return null
    }

    return latestRequest
  }

  @computed
  get pendingSectionRequest() {
    const latestRequest = this.sectionInvoiceRequests?.[0]

    if (!latestRequest) return null
    if (latestRequest.isPending || latestRequest.isRejected) {
      return latestRequest
    }

    return null
  }

  initializeSectionStream() {
    this.addStream(
      getSection(this.repository, {
        sectionId: this.sectionId,
      }),
      (section) => {
        this.section.replaceModel(section)

        this.loadAssignmentCubits()
        this.addUserStream()
        this.initCatalogAndSlideDeckStreams()
        this.getOrganization()
        this.startSectionPromotionStream()
      }
    )
  }

  initializeSectionRequestsStream() {
    this.addStream(
      getSectionRequestsBySectionId(this.repository, this.sectionId),
      (models) => {
        // Sorted by requestedAt date, descending
        const sortedRequests = models.sort((a, b) => {
          return b.data.requestedAt.getTime() - a.data.requestedAt.getTime()
        })

        runInAction(() => {
          this.sectionInvoiceRequests.replace(sortedRequests)
        })
      }
    )
  }

  @action
  dispose() {
    this.slideDeckMap.clear()
    this.assignmentCubits.forEach((cubit) => cubit.dispose())
    return super.dispose()
  }

  startSectionPromotionStream(retryCount = 0): void {
    const name = `section-promotions-${this.section.id}`
    if (this.hasStream(name)) return
    this.addStream(
      getSectionPromotions(this.repository, {
        sectionId: this.section.id,
      }),
      (promotions) => {
        runInAction(() => {
          this.promotions = promotions
        })
      },
      {
        name: name,
        disableCaptureException: true,
        onError: (error) => {
          if (retryCount < 5) {
            setTimeout(() => {
              this.startSectionPromotionStream(retryCount + 1)
            }, 400 * retryCount)
          } else {
            captureException(error)
          }
        },
      }
    )
  }

  @action
  changeSelectedView(selectedView: InstructorSectionView) {
    this.selectedView = selectedView
  }

  addUserStream() {
    const data = this.section.data
    if (!data) return

    const userIds = [...data.userIds, data.instructorUserId]

    this.addStream(getPublicUsers(this.repository, { userIds }), (models) => {
      this.users.replaceModels(models)
    })
  }

  @action
  addSlideDeckStreams(models: SectionAssignment[]) {
    for (const assignment of models) {
      const slideDeckId = assignment.data.slideDeckId

      if (this.slideDeckMap.has(slideDeckId)) {
        continue
      }

      const slideDeck = SlideDeck.empty(this.repository)
      slideDeck.id = slideDeckId
      this.slideDeckMap.set(slideDeckId, slideDeck)

      this.addStream(
        getSlideDeck(this.repository, { slideDeckId }),
        (model) => {
          slideDeck.replaceModel(model)
        }
      )
    }
  }

  @action
  loadAssignmentCubits() {
    if (!this.allDataLoaded) return

    this.assignments.models.forEach((assignment) => {
      if (!this.assignmentCubits.has(assignment.id)) {
        const cubit = new InstructorAssignmentCubit(this.repository, {
          sectionId: this.sectionId,
          assignmentId: assignment.id,
          initialSection: this.section,
          initialSectionAssignment: assignment,
        })
        cubit.initialize()
        this.assignmentCubits.set(assignment.id, cubit)
      }
    })
  }

  updateSection = async ({
    className,
    sectionName,
    organizationId,
  }: {
    className: string
    sectionName: string
    organizationId?: string
  }) => {
    await updateSection(this.repository, this.section.id, {
      className,
      sectionName,
      organizationId,
    })

    return this.section.id
  }

  private initCatalogAndSlideDeckStreams() {
    // admin logic
    const catalogsKey = 'catalogs-stream'

    // if we already have the stream(s), return
    if (this.hasStream(catalogsKey)) return

    const userId = this.instructorUserId || this.repository.uid
    // instructor and TA logic
    this.addStream(
      getCatalogsForUser(this.repository, {
        userId: userId,
      }),
      (catalogs) => {
        this.catalogs.replaceModels(catalogs)
        catalogs.forEach((catalog) => {
          const catalogSlideDeckStreamName = `catalogSlideDecks:${catalog.id}`
          if (this.hasStream(catalogSlideDeckStreamName)) return
          this.addStream(
            getSlideDecksForCatalog(this.repository, { catalogId: catalog.id }),
            (slideDecks) => {
              slideDecks.forEach((slideDeck) => {
                runInAction(() => {
                  this.slideDeckMap.set(slideDeck.id, slideDeck)
                })
              })
            },
            { name: catalogSlideDeckStreamName }
          )
        })
      },
      { name: catalogsKey }
    )
  }

  addFilterChip = (chip: string) => {
    this.filterChips.push(chip)
  }

  removeFilterChip = (chip: string) => {
    this.filterChips.remove(chip)
  }

  createStudentInvitation = async ({ type }: { type: InvitationType }) => {
    const doc = await createStudentInvitation(this.repository, {
      sectionId: this.section.id,
      type: type,
    })

    return doc.id
  }

  async closeSection() {
    await setSectionState(this.repository, {
      sectionId: this.section.id,
      sectionState: SectionState.completed,
    })
  }

  async markAsInProgress() {
    await setSectionState(this.repository, {
      sectionId: this.section.id,
      sectionState: SectionState.inProgress,
    })
  }

  redeemPromotions = async (
    sectionId: string,
    userPromotions: UserPromotion[]
  ) => {
    await Promise.all(
      userPromotions.map((userPromotion) =>
        redeemPromotions(this.repository, {
          userId: this.repository.breakoutUser!.uid,
          promotionId: userPromotion.data.promotionId,
          userPromotionId: userPromotion.id,
          sectionId,
        })
      )
    )
  }

  invoicedSection = async () => {
    await setSectionInvoiced(this.repository, {
      sectionId: this.section.id,
    })
  }

  setToStudentPay = async () => {
    await setSectionNotInvoiced(this.repository, {
      sectionId: this.section.id,
    })
  }

  @computed
  get slideDecksById() {
    // calling size will trigger the computed to recompute when
    // a new slide deck is added or removed
    return this.slideDeckMap
  }

  @computed
  get assignmentCount() {
    return this.assignments.models.length
  }

  @computed
  get allDataLoaded() {
    return this.section.isLoaded && this.assignments.isLoaded
  }

  @computed
  get allAssignmentCubitsLoaded() {
    if (!this.allDataLoaded) return false

    const cubits = Array.from(this.assignmentCubits.values())

    return cubits.every((cubit) => cubit.allDataLoaded)
  }

  @computed
  get sectionAssignmentsSorted() {
    // make a shallow copy of the assignments.models array
    const sortedAssignments = this.assignments.models.concat()
    return sortedAssignments.sort((a, b) => {
      // sort by assignedAt Date field ascending
      return a.data.assignedAt.getTime() - b.data.assignedAt.getTime()
    })
  }

  @computed
  get sectionUserData() {
    const sectionUserData = new Map<string, SectionUserData>()

    const hasAllData =
      this.section.isLoaded &&
      this.assignments.isLoaded &&
      this.allAssignmentCubitsLoaded

    if (!hasAllData) return sectionUserData

    // if there are no assignments
    if (this.assignments.length === 0) {
      this.users.models.forEach((user) => {
        sectionUserData.set(user.id, {
          user,
          roomStates: [],
          assignment: new Map(),
          quizScore: new Map(),
          engagementData: new Map(),
          rubricResults: new Map(),
        })
      })
    }

    // loop over the assignments
    for (const assignment of this.sectionAssignmentsSorted) {
      const cubit = this.assignmentCubits.get(assignment.id)
      if (!cubit) continue

      const groupData = cubit.assignmentGroupDataSortedWithNotInGroup

      const roomStates: RoomState[] = []

      groupData.forEach((group) => {
        if (group.roomState) {
          roomStates.push(group.roomState)
        }
      })

      for (const group of groupData) {
        for (const member of group.groupMembers) {
          const user = this.repository.userStore.getUser(member)

          if (!sectionUserData.has(member)) {
            sectionUserData.set(
              member,
              new SectionUserData({
                user,
                roomStates,
                assignment: new Map(),
                quizScore: new Map(),
                engagementData: new Map(),
                rubricResults: new Map(),
              })
            )
          }

          const memberData = sectionUserData.get(member)
          if (memberData && group.roomState !== undefined) {
            if (group.roomState.isEmpty) {
              const { assignment, quizScore, engagementData, rubricResults } =
                memberData
              // todo: why does dart app include the empty room state results ?????
              // should we just omit these ??
              // in dart we the roomState.empty is a static and therefore
              // we can only have one empty room in the map. empty room is not static in js
              // so we delete all empty rooms from the map before setting a new one to maintain behavior
              const removeEmptyRooms = (map: Map<RoomState, unknown>) => {
                Array.from(map.keys()).forEach((roomState) => {
                  if (!roomState.isEmpty) return
                  map.delete(roomState)
                })
              }
              ;[assignment, quizScore, engagementData, rubricResults].forEach(
                removeEmptyRooms
              )
            }
            memberData.assignment.set(group.roomState, assignment)
            memberData.quizScore.set(
              group.roomState!,
              group.quizScore.get(member!) || 0.0
            )
            memberData.engagementData.set(
              group.roomState,
              group.engagementData.get(member!) ||
                RoomStateEngagement.empty(this.repository)
            )
            memberData.rubricResults.set(
              group.roomState,
              group.rubricResults.get(member!) || new Map()
            )
          }
          if (memberData) {
            sectionUserData.set(member, memberData)
          }
        }
      }
    }

    return sectionUserData
  }

  @computed
  get sectionUserDataSorted() {
    const sectionUserDataArr = Array.from(this.sectionUserData.values())

    sectionUserDataArr.sort(function (a, b) {
      const aLastName = a.user.data.lastName
      const bLastName = b.user.data.lastName

      if (aLastName && bLastName && aLastName !== bLastName) {
        return aLastName.localeCompare(bLastName)
      }

      const aFirstName = a.user.data.firstName
      const bFirstName = b.user.data.firstName

      if (aFirstName && bFirstName && aFirstName !== bFirstName) {
        return aFirstName.localeCompare(bFirstName)
      }

      return 0
    })

    return sectionUserDataArr
  }

  @computed
  get sectionUserDataFiltered() {
    // filter the sectionUserData by the filterChips using the user.fullName
    return this.sectionUserDataSorted
      .filter((data) => {
        const isSectionUser = this.section.data.userIds.includes(data.user.id)
        const fullName = data.user.fullName
        if (!fullName || !isSectionUser) return false
        // filter for any record that contains any chip, we do not need all of them
        return this.filterChips.length
          ? this.filterChips.some((chip) => {
              return fullName.toLowerCase().includes(chip.toLowerCase())
            })
          : true
      })
      .sort((a, b) => {
        return a.user.fullName.localeCompare(b.user.fullName)
      })
  }

  @computed
  get userCount() {
    return this.section.data.userIds.length
  }
}

export class SectionUserData {
  public assignment: Map<RoomState, SectionAssignment>
  public user: PublicUser
  public quizScore: Map<RoomState, number>
  public roomStates: RoomState[]
  public engagementData: Map<RoomState, RoomStateEngagement>
  public rubricResults: Map<
    RoomState,
    Map<SlideRubric, RoomStateRubricResult[]>
  >

  constructor(params: {
    user: PublicUser
    roomStates: RoomState[]
    assignment?: Map<RoomState, SectionAssignment>
    quizScore?: Map<RoomState, number>
    rubricResults?: Map<RoomState, Map<SlideRubric, RoomStateRubricResult[]>>
    engagementData?: Map<RoomState, RoomStateEngagement>
  }) {
    this.user = params.user
    this.assignment = params.assignment || new Map()
    this.quizScore = params.quizScore || new Map()
    this.roomStates = params.roomStates
    this.rubricResults = params.rubricResults || new Map()
    this.engagementData = params.engagementData || new Map()
  }
}
