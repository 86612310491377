import { z } from 'zod'

export const settingsSlideDeckFieldsSchema = z.object({
  slideDeckIndustries: z.array(z.string()).default([]),
  slideDeckDisciplines: z.array(z.string()).default([]),
})

export const emptySettingsSlideDeckFields = Object.freeze(
  settingsSlideDeckFieldsSchema.parse({
    slideDeckIndustries: [],
    slideDeckDisciplines: [],
  })
)

export interface FirestoreSettingsSlideDeckFields
  extends z.infer<typeof settingsSlideDeckFieldsSchema> {}

// Optional utility functions
export const isEmptySettingsSlideDeckFields = (
  fields: FirestoreSettingsSlideDeckFields
): boolean => {
  return JSON.stringify(fields) === JSON.stringify(emptySettingsSlideDeckFields)
}

export const isNotEmptySettingsSlideDeckFields = (
  fields: FirestoreSettingsSlideDeckFields
): boolean => {
  return !isEmptySettingsSlideDeckFields(fields)
}
