import { z } from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeString,
} from '../../firestore/shared/schema'
import { TeachingPlanStatus, TeachingPlanUploadType } from './types'

export const fieldsWithExtensionsFromTeachingPlanUploadType = (
  uploadType: TeachingPlanUploadType
) => {
  switch (uploadType) {
    case TeachingPlanUploadType.mp4:
      return {
        teachingPlanUploadDetails: {
          urlFieldName: 'teachingPlanVideoURL',
          extension: 'mp4',
          mimeType: 'video/mp4',
        },
      }
    case TeachingPlanUploadType.jpg:
      return {
        teachingPlanUploadDetails: {
          urlFieldName: 'teachingPlanImageURL',
          extension: 'jpg',
          mimeType: 'image/jpeg',
        },
      }
  }
}

const TeachingPlanStatusSchema = z.nativeEnum(TeachingPlanStatus)

export const schema = z.object({
  catalogId: nullSafeString(''),
  teachingPlanName: nullSafeString(''),
  teachingPlanDescription: nullSafeOptionalString(),
  teachingPlanStatus: z.preprocess(
    (val) => val ?? TeachingPlanStatus.draft,
    TeachingPlanStatusSchema
  ),
  teachingPlanImageURL: nullSafeOptionalString(),
  teachingPlanVideoURL: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    catalogId: '',
    teachingPlanName: '',
    teachingPlanStatus: TeachingPlanStatus.draft,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export interface FirestoreTeachingPlan extends z.infer<typeof schema> {}
