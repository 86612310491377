import { makeObservable, observable, runInAction } from 'mobx'

import { createCatalog, getCatalogs } from '../firestore/Catalog'
import { Catalog } from '../models/Catalog'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { StaticModelCollection } from '../types'
import { Cubit } from './core'
import { fetchSlideDeckCountForCatalog } from '../firestore/SlideDeck'

export class AdminCatalogsCubit extends Cubit {
  repository: FirebaseRepository

  catalogs: StaticModelCollection<Catalog>
  slideDeckCounts = observable.map<string, number>()

  @observable showHidden = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)
    this.repository = repository
    this.catalogs = Catalog.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(getCatalogs(this.repository), (catalogs) => {
      this.catalogs.replaceModels(catalogs)
      this.fetchSlideDeckCounts()
    })
  }

  fetchSlideDeckCounts(): void {
    this.catalogs.models.forEach(async (catalog) => {
      const count = await fetchSlideDeckCountForCatalog(this.repository, {
        catalogId: catalog.id,
      })

      runInAction(() => {
        this.slideDeckCounts.set(catalog.id, count)
      })
    })
  }

  createCatalog(params: {
    catalogName: string
    catalogDescription: string
  }): Promise<string> {
    return createCatalog(this.repository, params)
  }
}
