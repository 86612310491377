import { type FirestoreRoomStateEngagement } from '../../firestore/RoomStateEngagement'

export const mockRoomStateEngagement: Array<FirestoreRoomStateEngagement> = [
  {
    cameraOffTime: 0,
    durationPresent: 2287.104461,
    roomId: 'bj94rZs75V0XY0ke1WIk',
    totalTalkTime: 1206,
    totalTime: 2314.328545,
    //"userId": "B13G1Nvfc4WOvutV6h6XjkLSC6Y2",
    userId: '1',
    userTalkTime: 325,
    assignmentId: 'AyMuJfYGWJcufZu8NEUD',
    sectionId: 'aD7jb4zXgn6x89ky1lyI',
    updatedAt: new Date('2024-05-23T20:15:29.737Z'),
  },
  {
    cameraOffTime: 0,
    durationPresent: 2313.743364,
    roomId: 'bj94rZs75V0XY0ke1WIk',
    totalTalkTime: 1206,
    totalTime: 2314.328545,
    //"userId": "EXPJpAGoLUdfeWoYWQuHCB9bUCd2",
    userId: '2',
    userTalkTime: 200,
    assignmentId: 'AyMuJfYGWJcufZu8NEUD',
    sectionId: 'aD7jb4zXgn6x89ky1lyI',
    updatedAt: new Date('2024-05-23T20:15:29.605Z'),
  },
  {
    cameraOffTime: 0,
    durationPresent: 2278.317361,
    roomId: 'bj94rZs75V0XY0ke1WIk',
    totalTalkTime: 1206,
    totalTime: 2314.328545,
    //"userId": "Pv9I4jMZASggDfrhRVOlq1xuAux1",
    userId: '3',
    userTalkTime: 681,
    assignmentId: 'AyMuJfYGWJcufZu8NEUD',
    sectionId: 'aD7jb4zXgn6x89ky1lyI',
    updatedAt: new Date('2024-05-23T20:15:29.800Z'),
  },
]
