import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeOptionalStringArray,
} from '../shared/schema'

// this is the dart definition
// required String id,
// required String roomId,
// required int score,
// required String userId,
// String? comment,
// int? updatedAt,
// bool? hubspotWrite,
export const schema = z.object({
  roomId: z.string(),
  score: z.number(),
  userId: z.string(),
  reasons: nullSafeOptionalStringArray(),
  flagMode: nullSafeOptionalString(),
  comment: nullSafeOptionalString(),
  hubspotWrite: nullSafeOptionalBoolean(false),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    score: -1,
    userId: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export type FirestoreRoomStateFeedback = z.infer<typeof schema>
