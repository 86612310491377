export enum SlideType {
  video = 0,
  image = 1,
  discussion = 2,
  interactiveQuiz = 3,
  interactivePoll = 4,
  soloQuiz = 5,
  professorFeedback = 6,
  processingData = 7,
  sessionResults = 8,
  exhibitGrid = 9,
  groupQuiz = 10,
}
