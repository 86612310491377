import { RoomStateAnswer } from '../../../models/RoomStateAnswer'
import type { SlideQuestion } from '../../../models/SlideQuestion'
import type { MeetingCubit } from '../../MeetingCubit'
import { MeetingQuestion } from '../MeetingQuestion'

export class PreviewQuestion extends MeetingQuestion {
  meeting: MeetingCubit
  constructor(meeting: MeetingCubit, model: SlideQuestion) {
    super(meeting, model)
    this.meeting = meeting
  }

  protected sendUserAnswer({
    answer,
    answerList,
    isGroupAnswer,
  }: {
    answer: number
    answerList?: number[]
    isGroupAnswer: boolean
  }): Promise<void> {
    const collection = isGroupAnswer
      ? this.meeting.roomStateAnswersForGroup
      : this.meeting.roomStateAnswersPerUser.get(this.meeting.currentUser.uid)

    if (collection === undefined) return Promise.resolve()

    const existingAnswer = isGroupAnswer ? this.groupAnswer : this.userAnswer

    const userAnswer = RoomStateAnswer.emptyForPreview(
      this.meeting.repository,
      {
        userId: this.meeting.currentUser.uid,
        slideQuestionId: this.id,
        answer,
        answerList: answerList || [],
      }
    )

    if (existingAnswer) {
      existingAnswer.replaceModel(userAnswer)
    } else {
      collection.replaceModels([...collection.models, userAnswer])
    }
    return Promise.resolve()
  }

  answerForGroup(
    answer: number,
    answerList?: number[] | undefined
  ): Promise<void> {
    this.answer(answer, answerList)
    return Promise.resolve()
  }

  protected submitUserAnswer() {
    const collection = this.meeting.roomStateAnswersPerUser.get(
      this.meeting.currentUser.uid
    )

    if (collection === undefined) return Promise.resolve()

    const currentAnswer = collection.models.find(
      (ua) => ua.data.slideQuestionId === this.id
    )

    currentAnswer?.replaceData({
      ...currentAnswer.data,
      submitted: true,
    })

    return Promise.resolve()
  }
}
