import z from 'zod'
import { nullSafeFirestoreTimestamp } from '../shared/schema'

export const schema = z.object({
  institutionName: z.string(),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const writeSchema = schema.extend({})

export const empty = Object.freeze(
  schema.parse({
    institutionName: '',
  })
)

export type FirestoreInstitution = z.infer<typeof schema>
