import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import type { DateTime } from 'luxon'
import {
  createDemoInvitation,
  getDemoInvitations,
} from '../firestore/Invitation'
import { fetchAllAppUsersByRole } from '../firestore/PublicUser'
import {
  adminStartDemoMeeting,
  createDemoRoomState,
  deleteDemoRoomState,
  fetchRoomState,
  getDemoRoomStates,
  roomStateAddUser,
  updateRoomState,
} from '../firestore/RoomState'
import { getDemoSlideDecks } from '../firestore/SlideDeck'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RoomState } from '../models/RoomState'
import { SlideDeck } from '../models/SlideDeck'
import type { AppUser } from '../stores/AppUser'
import { UserProfileRole, type StaticModelCollection } from '../types'
import { Cubit } from './core'
import type { Invitation } from '../models/Invitation'
import { arrayUnion } from 'firebase/firestore'
import { pollForRoomToken } from '../util/observe'

export class AdminDemosCubit extends Cubit {
  repository: FirebaseRepository

  @observable tab: 'upcoming' | 'completed' = 'upcoming'

  roomStates: StaticModelCollection<RoomState>
  slideDecks: StaticModelCollection<SlideDeck>
  admins = observable.array<AppUser>()
  invitations = observable.map<string, Invitation[]>()
  private roomIdFromParam: string | undefined
  private didFetchRoomIdFromParam = false

  constructor(
    repository: FirebaseRepository,
    roomIdFromParam: string | undefined
  ) {
    super()
    makeObservable(this)

    this.repository = repository

    this.roomStates = RoomState.emptyCollection(repository)

    this.slideDecks = SlideDeck.emptyCollection(repository)

    this.roomIdFromParam = roomIdFromParam
  }

  initialize(): void {
    this.loadDemoRoomStates()
    this.addStream(getDemoSlideDecks(this.repository), (slideDecks) => {
      this.slideDecks.replaceModels(slideDecks)
    })

    fetchAllAppUsersByRole(this.repository, [
      UserProfileRole.admin,
      UserProfileRole.corre,
    ]).then((users) => {
      this.admins.replace(users)
    })
  }

  @action
  changeMode(tab: 'upcoming' | 'completed'): void {
    this.tab = tab
    // reload the demo room states
    if (this.hasStream('roomStates')) {
      this.removeStream('roomStates')
    }
    this.loadDemoRoomStates()
  }

  loadDemoRoomStates(): void {
    if (!this.hasStream('roomStates')) {
      this.addStream(
        getDemoRoomStates(this.repository, {
          mode: this.tab,
        }),
        async (roomStates) => {
          // if roomId is defined in the URL, but we didn't load it in our first load of the stream
          // fetch is manually, and check if it's completed
          // if it is completed change the the cubit view to 'completed'
          // this should only happen once, and then trigger the
          // session results modal after the stream resolves with data
          if (
            this.roomIdFromParam &&
            !this.didFetchRoomIdFromParam &&
            !roomStates.some(({ id }) => id === this.roomIdFromParam)
          ) {
            this.didFetchRoomIdFromParam = true
            const roomFromParam = await fetchRoomState(this.repository, {
              roomStateId: this.roomIdFromParam,
              returnEmptyOnNotExists: true,
            })
            if (roomFromParam.data.isDemo && roomFromParam.isDemoCompleted) {
              this.changeMode('completed')
            }
          }
          this.roomStates.replaceModels(roomStates)
          this.startInvitationsStreams(roomStates)
        },
        {
          name: 'roomStates',
        }
      )
    }
  }

  @computed
  get rooms(): RoomState[] {
    return this.roomStates.models
  }

  startInvitationsStreams(roomStates: RoomState[]): void {
    for (const room of roomStates) {
      const name = `invitations-${room.id}`

      if (this.hasStream(name)) continue

      this.addStream(
        getDemoInvitations(this.repository, { roomId: room.id }),
        (invitations) => {
          runInAction(() => {
            this.invitations.set(room.id, invitations)
          })
        },
        {
          name,
        }
      )
    }
  }

  async deleteRoomState(roomStateId: string) {
    await deleteDemoRoomState(this.repository, {
      roomStateId,
    })
  }

  async createDemoMeeting({
    userId,
    slideDeckId,
    scheduledAt,
  }: {
    userId: string
    slideDeckId: string
    scheduledAt: DateTime
  }) {
    const result = await createDemoRoomState(this.repository, {
      userId,
      slideDeckId,
      scheduledAt,
    })

    return result.id
  }

  async updateDemoMeeting(
    roomStateId: string,
    {
      userId,
      slideDeckId,
      scheduledAt,
    }: {
      userId: string
      slideDeckId: string
      scheduledAt: DateTime
    }
  ) {
    await updateRoomState(this.repository.firestore, roomStateId, {
      userIds: arrayUnion(userId),
      groupLeaderUserIds: [userId],
      slideDeckId,
      scheduledAt: scheduledAt.toJSDate(),
    })
  }

  inviteToDemo(
    roomId: string,
    {
      firstName,
      lastName,
      emailAddress,
    }: {
      firstName: string
      lastName: string
      emailAddress: string
    }
  ) {
    return createDemoInvitation(this.repository, {
      roomId,
      firstName,
      lastName,
      emailAddress,
    })
  }

  /**
   * adds user to room and checks for the token on user profile
   * resolves to true if the token is present
   */
  async joinMeeting(roomId: string) {
    await roomStateAddUser(this.repository.firestore, {
      roomId,
      userId: this.repository.uid,
      isGroupLeader: false,
    })
    return await pollForRoomToken(this.repository, roomId)
  }

  /**
   * starts room and checks for the token on user profile
   * resolves to true if the token is present
   */
  async startMeeting(room: RoomState) {
    await adminStartDemoMeeting(this.repository, { roomStateId: room.id })
    return await pollForRoomToken(this.repository, room.id)
  }
}
