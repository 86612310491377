import { makeObservable } from 'mobx'
import { Slide } from './Slide'

export class GroupQuizSlide extends Slide {
  isGroup = true

  afterConstructor() {
    makeObservable(this, {
      questions: true,
    })
  }

  get questions() {
    return this.meeting.questions.filter(
      (q) => q.groupSlideId === this.meeting.currentSlide?.id && q.isQuiz
    )
  }
}
