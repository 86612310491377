import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
  nullSafeString,
  nullSafeStringArray,
} from '../shared/schema'
import { SlideQuestionType } from '../../models/SlideQuestionType'

const slideQuestionTypeSchema = z.nativeEnum(SlideQuestionType)

// dart definition
// required List<String> answers, default: []
// required String question, default: ''
// required SlideQuestionType questionType, default: SlideQuestionType.poll
// int? correct,
// String? groupSlideId,
// String? slideId,
// int? updatedAt,
export const schema = z.object({
  answers: nullSafeStringArray([]),
  question: nullSafeString(''),
  questionType: z.preprocess(
    (val) => val ?? SlideQuestionType.poll,
    slideQuestionTypeSchema
  ),
  correct: nullSafeOptionalNumber(),
  groupSlideId: nullSafeOptionalString().nullable(),
  slideId: nullSafeOptionalString().nullable(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    answers: [],
    question: '',
    questionType: SlideQuestionType.poll,
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

export interface FirestoreSlideQuestion extends z.infer<typeof schema> {}
