import z from 'zod'
import {
  nullSafeFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

// this is the dart definition
// required String roomId,
// required String summary,
// String assignmentId,
// String sectionId,
// int? updatedAt,
//
// no default values
export const schema = z.object({
  roomId: z.string(),
  summary: z.string(),
  assignmentId: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    summary: '',
  })
)

export type FirestoreRoomStateSummary = z.infer<typeof schema>
