// User object that is shared publicly

import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreRoomStateEngagement,
} from '../firestore/RoomStateEngagement'
import type { FirebaseRepository } from './FirebaseRepository'

export class RoomStateEngagement extends ObservableModelWithDecorators<FirestoreRoomStateEngagement> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateEngagement>
  ) {
    super(repository, doc)
  }

  /**
   * returns float between 0 and 1 representing the ratio of time the camera was on
   * if inadequate data to calculate ratio return null
   */
  get cameraOnRatio() {
    const { durationPresent, cameraOffTime } = this.data
    if (!durationPresent) return null
    return 1 - (cameraOffTime || 0) / durationPresent
  }

  /**
   * returns a float with no rounding/decimal limit representing the talk time in minutes
   * will return 0 if no data
   */
  get talkTimeMinutes() {
    const talkTime = this.data.userTalkTime || 0
    return talkTime / 60
  }
}
