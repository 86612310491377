import { action, computed, makeObservable, observable } from 'mobx'

import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import type { FirestoreSlideDeckRubricTest } from '../firestore/SlideDeckRubricTest'
import {
  archiveSlideRubricTest,
  getSlideDeckRubricTests,
  saveSlideDeckRubricTest,
} from '../firestore/SlideDeckRubricTest'
import type { StaticModelCollection } from '../types'
import { SlideDeckRubricTest } from '../models/SlideDeckRubricTest'
import { getSlideDeckRubric } from '../firestore/SlideRubric'
import { SlideRubric } from '../models/SlideRubric'
import { SlideDeckRubricAI } from '../models/SlideDeckRubricAI'
import { SlideDeckRubricTestResult } from '../models/SlideDeckRubricTestResult'
import { getSlideDeckRubricTestResults } from '../firestore/SlideDeckRubricTestResult'
import {
  forceRubricTestAIProcessing,
  getSlideDeckRubricAI,
} from '../firestore/SlideDeckRubricAI'

export class AdminRubricTestsCubit extends Cubit {
  repository: FirebaseRepository
  rubricId: string
  slideDeckId: string
  rubricTests: StaticModelCollection<SlideDeckRubricTest>
  rubric: SlideRubric

  @observable
  selectedRubricTestId: string | null = null

  rubricAiRecords: StaticModelCollection<SlideDeckRubricAI>
  rubricTestResults: StaticModelCollection<SlideDeckRubricTestResult>

  constructor(
    repository: FirebaseRepository,
    {
      rubricId,
      slideDeckId,
    }: {
      rubricId: string
      slideDeckId: string
    }
  ) {
    super()
    makeObservable(this)
    this.repository = repository
    this.rubricId = rubricId
    this.slideDeckId = slideDeckId
    this.rubricTests = SlideDeckRubricTest.emptyCollection(repository)
    this.rubric = SlideRubric.empty(repository)
    this.rubricAiRecords = SlideDeckRubricAI.emptyCollection(repository)
    this.rubricTestResults =
      SlideDeckRubricTestResult.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getSlideDeckRubricTests(this.repository, {
        rubricId: this.rubricId,
        slideDeckId: this.slideDeckId,
      }),
      (tests) => {
        const filteredTests = tests.filter((test) => !test.data.archivedAt)
        this.rubricTests.replaceModels(filteredTests)
      }
    )
    this.addStream(
      getSlideDeckRubricTestResults(this.repository, this.rubricId),
      (results) => {
        this.rubricTestResults.replaceModels(results)
      }
    )
    this.addStream(
      getSlideDeckRubric(this.repository, {
        slideDeckId: this.slideDeckId,
        slideRubricId: this.rubricId,
      }),
      (rubric) => {
        this.rubric.replaceModel(rubric)
      }
    )
    this.addStream(
      getSlideDeckRubricAI(this.repository, {
        slideDeckId: this.slideDeckId,
        rubricId: this.rubricId,
      }),
      (rubricAiRecords) => {
        this.rubricAiRecords.replaceModels(rubricAiRecords)
      }
    )
  }

  @computed
  get selectedRubricTest() {
    return this.rubricTests.models.find(
      (test) => test.id === this.selectedRubricTestId
    )
  }

  @computed
  get selectedRubricTestResults() {
    return this.getSelectedRubricTestResults(this.selectedRubricTestId || '')
  }

  get isLoading() {
    return (
      this.rubricTests.isLoading ||
      this.rubric.isLoading ||
      this.rubricAiRecords.isLoading ||
      this.rubricTestResults.isLoading
    )
  }

  getSelectedRubricTestResults(rubricTestId: string) {
    return this.rubricTestResults.models.filter((result) => {
      return result.data.rubricTestId === rubricTestId
    })
  }

  @computed
  get selectedRubricTestAiRecord() {
    if (!this.selectedRubricTestId) {
      return []
    }
    return this.getSelectedRubricTestAiRecords(this.selectedRubricTestId)
  }

  getSelectedRubricTestAiRecords = (rubricTestId: string) => {
    const records = this.rubricAiRecords.models.filter(
      (record) => record.data.rubricTestId === rubricTestId
    )

    return records
  }

  @action
  setSelectedRubricTestId(rubricTestId: string) {
    this.selectedRubricTestId = rubricTestId
  }

  saveSlideDeckRubricTest = async ({
    rubricTestName,
    rubricTestScore,
    rubricTestScript,
    rubricTestId,
  }: { rubricTestId?: string } & Pick<
    FirestoreSlideDeckRubricTest,
    'rubricTestName' | 'rubricTestScore' | 'rubricTestScript'
  >): Promise<string> => {
    return await saveSlideDeckRubricTest(this.repository, {
      slideDeckId: this.slideDeckId,
      rubricId: this.rubricId,
      rubricTestId,
      rubricTestName,
      rubricTestScore,
      rubricTestScript,
    })
  }

  archiveSlideRubricTest = async (rubricTestId: string) => {
    return await archiveSlideRubricTest(this.repository, {
      slideDeckId: this.slideDeckId,
      rubricId: this.rubricId,
      rubricTestId,
    })
  }

  checkIfAiRecordsAreStaleOrCompleted() {
    if (!this.selectedRubricTestAiRecord) {
      return false
    }

    const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000)

    // Check if all records are stale (older than 5 minutes)
    const allRecordsStaleOrCompleted = this.selectedRubricTestAiRecord.every(
      (record) => {
        return (
          !record.data.startedAt ||
          record.data.startedAt <= fiveMinutesAgo ||
          !!record.data.endedAt
        )
      }
    )
    return allRecordsStaleOrCompleted
  }

  forceAIProcessing(rubricTestId: string) {
    return forceRubricTestAIProcessing(this.repository, {
      rubricId: this.rubricId,
      slideDeckId: this.slideDeckId,
      rubricTestId,
    })
  }
}
