import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
  nullSafeStringArray,
} from '../shared/schema'

// this is the dart definition
// required List<String> professorFeedback,
// required List<String> technicalFeedback,
// required String roomId,
// required String userId,
// String assignmentId,
// String sectionId,
// int? updatedAt,
export const schema = z.object({
  roomId: z.string(),
  userId: z.string(),
  professorFeedback: nullSafeStringArray([]).transform((val) => {
    const value = val || []
    return value.sort((a: string, b: string) => b.length - a.length)
  }),
  technicalFeedback: nullSafeStringArray([]).transform((val) => {
    const value = val || []
    return value.sort((a: string, b: string) => b.length - a.length)
  }),
  assignmentId: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    roomId: '',
    userId: '',
  })
)

export const writeSchema = schema.extend({
  updatedAt: firestoreWriteTimestamp,
})

type FirestoreRoomStateProfessorFeedbackInferred = z.infer<typeof schema>
export interface FirestoreRoomStateProfessorFeedback
  extends FirestoreRoomStateProfessorFeedbackInferred {}
export type FirestoreRoomStateProfessorFeedbackWrite = z.infer<typeof schema>
