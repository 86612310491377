import type { SlideDeck } from '../models/SlideDeck'

function checkMatch(filter: string, field: string | undefined) {
  if (!field) return false
  return field.toLowerCase().includes(filter.toLowerCase())
}

export function filterSlideDeck(filter: string, slideDeck: SlideDeck) {
  if (!filter) return true
  const data = slideDeck.data

  const matches =
    checkMatch(filter, data.slideDeckName) ||
    checkMatch(filter, data.slideDeckTeaser) ||
    checkMatch(filter, data.slideDeckDescription) ||
    data.slideDeckKeyConcepts.some((concept) => checkMatch(filter, concept)) ||
    data.slideDeckLearningObjectives.some((objective) =>
      checkMatch(filter, objective)
    ) ||
    data.slideDeckDisciplines.some((objective) => checkMatch(filter, objective))

  return matches
}
