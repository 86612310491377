import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import type { StaticModelCollection } from '../firestore-mobx/model'
import { getCatalogs, getMyCatalogs } from '../firestore/Catalog'
import { getSlideDecks, getSlideDecksForCatalog } from '../firestore/SlideDeck'
import { Catalog } from '../models/Catalog'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { SlideDeck } from '../models/SlideDeck'
import { Cubit } from './core'
import { getTeachingPlansForCatalog } from '../firestore/TeachingPlan'
import { TeachingPlan } from '../models/TeachingPlan'
import { TeachingPlanAuthor } from '../models/TeachingPlanAuthor'
import { getTeachingPlanAuthors } from '../firestore/TeachingPlanAuthor'
import { SlideDeckMaterial } from '../models/SlideDeckMaterial'
import { fetchSlideDeckMaterialsForInstructor } from '../firestore/SlideDeckMaterial'
import { filterSlideDeck } from '../util/filtering'
import { getTeachingPlanAggregation } from '../firestore/TeachingPlanAggregation'
import type { TeachingPlanAggregation } from '../models/TeachingPlanAggregation'
import { SlideDeckAuthor } from '../models/SlideDeckAuthor'
import { getSlideDeckAuthors } from '../firestore/SlideDeckAuthor'
import { SlideDeckReference } from '../models/SlideDeckReference'
import { fetchSlideDeckReferences } from '../firestore/SlideDeckReference'

export class InstructorLibraryWithTeachingPlansCubit extends Cubit {
  repository: FirebaseRepository

  catalogs: StaticModelCollection<Catalog>

  private _adminSlideDecks: StaticModelCollection<SlideDeck>

  private _slideDecksByCatalogId = observable.map<
    string,
    StaticModelCollection<SlideDeck>
  >()

  private _teachingPlansByCatalogId = observable.map<
    string,
    StaticModelCollection<TeachingPlan>
  >()

  teachingPlansAuthorsByPlanId = observable.map<
    string,
    StaticModelCollection<TeachingPlanAuthor>
  >()

  slideDeckAuthorsBySlideDeckId = observable.map<
    string,
    StaticModelCollection<SlideDeckAuthor>
  >()

  materialsBySlideDeckId = observable.map<
    string,
    StaticModelCollection<SlideDeckMaterial>
  >()

  referencesBySlideId = observable.map<
    string,
    StaticModelCollection<SlideDeckReference>
  >()

  private _isCorre: boolean

  teachingPlanAggregationByTeachingPlanId = observable.map<
    string,
    TeachingPlanAggregation
  >()

  catalogIsLoadingMap = observable.map<string, boolean>()

  @observable searchFilter: string = ''
  @observable selectedCatalogId: string | null = null

  constructor(repository: FirebaseRepository, initialCatalogId?: string) {
    super()
    makeObservable(this)

    this.repository = repository
    this.catalogs = Catalog.emptyCollection(repository)
    this._adminSlideDecks = SlideDeck.emptyCollection(repository)

    this._isCorre =
      repository.breakoutUser !== null && repository.breakoutUser.isCorre

    this.selectedCatalogId = initialCatalogId || null
  }

  @computed
  get isLoading() {
    const isCorreLoading =
      this.hasStream('corre-slide-decks') && this._adminSlideDecks.isLoading
    return (
      this.catalogs.isLoading ||
      isCorreLoading ||
      Array.from(this._slideDecksByCatalogId.values()).some(
        (collection) => collection.isLoading
      )
    )
  }

  @action
  setSelectedCatalogId(catalogId: string) {
    this.selectedCatalogId = catalogId
  }

  @computed
  get currentCatalogId() {
    if (this.selectedCatalogId) return this.selectedCatalogId

    // if not selected, take first
    if (this.catalogs.models.length > 0) {
      return this.catalogs.models[0].id
    }

    return undefined
  }

  @computed
  get slideDecksForCurrentCatalog() {
    if (!this.currentCatalogId) return []
    this.startCurrentCatalogStreams()

    return this.slideDecksByCatalogId[this.currentCatalogId] || []
  }

  @computed
  get teachingPlansForCurrentCatalog() {
    if (!this.currentCatalogId) return []
    this.startCurrentCatalogStreams()

    return this.teachingPlansByCatalogId[this.currentCatalogId] || []
  }

  @computed
  get slideDecksByCatalogId() {
    const applySearchFilter = (
      slideDecks: SlideDeck[],
      searchFilter: string
    ) => {
      if (!searchFilter) return slideDecks
      return slideDecks.filter((slideDeck) =>
        filterSlideDeck(searchFilter, slideDeck)
      )
    }

    // convert to a plain object and apply search filter if present
    const slideDecksByCatalog = Object.fromEntries(
      Array.from(this._slideDecksByCatalogId.entries()).map(([key, value]) => [
        key,
        applySearchFilter(value.models, this.searchFilter),
      ])
    )
    return slideDecksByCatalog
  }

  @computed
  get teachingPlansByCatalogId() {
    // convert to a plain object
    return Object.fromEntries(
      Array.from(this._teachingPlansByCatalogId.entries()).map(
        ([key, value]) => [key, value.models]
      )
    )
  }

  @computed
  get slideDecks() {
    if (!this._isCorre) {
      const soFar = new Set()
      const slideDecks = Array.from(this._slideDecksByCatalogId.values())
        .map((collection) => collection.models)
        .flat()
      // don't allow duplicate slide decks
      return slideDecks.filter((slideDeck) => {
        if (soFar.has(slideDeck.id)) return false
        soFar.add(slideDeck.id)
        return true
      })
    }
    return this._adminSlideDecks.models
  }

  initialize(): void {
    // if user is an admin call getCatalogs
    // otherwise call getMyCatalogs
    const isCorre = this.repository.breakoutUser?.isCorre
    this.addStream(
      isCorre ? getCatalogs(this.repository) : getMyCatalogs(this.repository),
      (catalogs) => {
        this.catalogs.replaceModels(catalogs)
      },
      {
        name: 'catalogs',
      }
    )
  }

  startAdminSlideDeckStream() {
    if (this.hasStream('corre-slide-decks')) return
    this.addStream(
      getSlideDecks(this.repository),
      (slideDecks) => {
        this._adminSlideDecks.replaceModels(slideDecks)
      },
      {
        name: 'corre-slide-decks',
      }
    )
  }

  startCurrentCatalogStreams() {
    if (!this.currentCatalogId) return

    this.addSlideDeckStreams(this.currentCatalogId)
    this.addTeachingPlanStreams(this.currentCatalogId)
  }

  get adminSlideDecks() {
    this.startAdminSlideDeckStream()

    return this._adminSlideDecks
  }

  @computed
  get hasSearchFilter() {
    return this.searchFilter.length > 0
  }

  @action
  setSearchFilter(filter: string) {
    this.searchFilter = filter
  }

  @action
  markLoadingState(catalogId: string, isLoading: boolean) {
    this.catalogIsLoadingMap.set(catalogId, isLoading)
  }

  addSlideDeckStreams(catalogId: string) {
    const streamName = `slide-decks-${catalogId}`

    if (this.hasStream(streamName)) return

    this.markLoadingState(catalogId, true)
    this.addStream(
      getSlideDecksForCatalog(this.repository, {
        catalogId: catalogId,
      }),
      (slideDecks) => {
        const found = this._slideDecksByCatalogId.get(catalogId)

        if (found) {
          found.replaceModels(slideDecks)
        } else {
          const collection = SlideDeck.emptyCollection(this.repository)
          collection.replaceModels(slideDecks)
          runInAction(() => {
            this._slideDecksByCatalogId.set(catalogId, collection)
          })
        }
        this.fetchMaterials(slideDecks)
        this.fetchReferences(slideDecks)
        this.getSlideDeckAuthors(slideDecks)
        this.markLoadingState(catalogId, false)
      },
      {
        name: streamName,
        onError: (err) => {
          console.error(err)
          this.markLoadingState(catalogId, false)
        },
      }
    )
  }

  addTeachingPlanStreams(catalogId: string) {
    const streamName = `teaching-plans-${catalogId}`

    if (this.hasStream(streamName)) return

    this.addStream(
      getTeachingPlansForCatalog(this.repository, {
        catalogId: catalogId,
      }),
      (teachingPlans) => {
        const found = this._teachingPlansByCatalogId.get(catalogId)

        if (found) {
          found.replaceModels(teachingPlans)
        } else {
          const collection = TeachingPlan.emptyCollection(this.repository)
          collection.replaceModels(teachingPlans)
          runInAction(() => {
            this._teachingPlansByCatalogId.set(catalogId, collection)
          })
        }
        this.getTeachingPlanAggregations(teachingPlans)
        this.getTeachingPlanAuthors(teachingPlans)
      },
      {
        name: streamName,
      }
    )
  }

  getTeachingPlanAggregations(teachingPlans: TeachingPlan[]) {
    const formatStreamName = (teachingPlanId: string) =>
      `teachingPlanAggregation-${teachingPlanId}`

    // Setup a stream for each aggregation.
    teachingPlans.forEach(async (teachingPlan) => {
      const streamName = formatStreamName(teachingPlan.id)
      if (this.hasStream(streamName)) {
        return
      }

      this.addStream(
        getTeachingPlanAggregation(this.repository, {
          catalogId: teachingPlan.data.catalogId,
          teachingPlanId: teachingPlan.id,
        }),
        (aggregation) => {
          runInAction(() => {
            this.teachingPlanAggregationByTeachingPlanId.set(
              teachingPlan.id,
              aggregation
            )
          })
        },
        { name: streamName, onError: (err) => console.error(err) }
      )
    })
  }

  fetchMaterials(slideDecks: SlideDeck[]) {
    slideDecks.forEach(async (slideDeck) => {
      const id = slideDeck.id

      const found = this.materialsBySlideDeckId.get(id)
      // if we already have the materials, don't fetch them again
      if (found) return

      const materials = await fetchSlideDeckMaterialsForInstructor(
        this.repository,
        {
          slideDeckId: id,
        }
      )

      const collection = SlideDeckMaterial.emptyCollection(this.repository)
      collection.replaceModels(materials)
      runInAction(() => {
        this.materialsBySlideDeckId.set(id, collection)
      })
    })
  }

  fetchReferences(slideDecks: SlideDeck[]) {
    slideDecks.forEach(async (slideDeck) => {
      const id = slideDeck.id

      const found = this.referencesBySlideId.get(id)
      // if we already have the references, don't fetch them again
      if (found) return

      const references = await fetchSlideDeckReferences(this.repository, {
        slideDeckId: id,
      })

      const collection = SlideDeckReference.emptyCollection(this.repository)
      collection.replaceModels(references)
      runInAction(() => {
        this.referencesBySlideId.set(id, collection)
      })
    })
  }

  getTeachingPlanAuthors(teachingPlans: TeachingPlan[]) {
    teachingPlans.forEach(async (teachingPlan) => {
      const id = teachingPlan.id
      const catalogId = teachingPlan.data.catalogId
      const streamName = `teaching-plan-authors-${id}`
      if (this.hasStream(streamName)) return

      this.addStream(
        getTeachingPlanAuthors(this.repository, {
          catalogId,
          teachingPlanId: id,
        }),
        (authors) => {
          const found = this.teachingPlansAuthorsByPlanId.get(id)

          if (found) {
            found.replaceModels(authors)
          } else {
            const collection = TeachingPlanAuthor.emptyCollection(
              this.repository
            )
            collection.replaceModels(authors)
            runInAction(() => {
              this.teachingPlansAuthorsByPlanId.set(id, collection)
            })
          }
        },
        {
          name: streamName,
        }
      )
    })
  }

  getSlideDeckAuthors(slideDecks: SlideDeck[]) {
    slideDecks.forEach(async (slideDeck) => {
      const id = slideDeck.id
      const streamName = `slide-deck-authors-${id}`
      if (this.hasStream(streamName)) return

      this.addStream(
        getSlideDeckAuthors(this.repository, {
          slideDeckId: id,
        }),
        (authors) => {
          const found = this.slideDeckAuthorsBySlideDeckId.get(id)

          if (found) {
            found.replaceModels(authors)
          } else {
            const collection = SlideDeckAuthor.emptyCollection(this.repository)
            collection.replaceModels(authors)
            runInAction(() => {
              this.slideDeckAuthorsBySlideDeckId.set(id, collection)
            })
          }
        },
        {
          name: streamName,
        }
      )
    })
  }
}
