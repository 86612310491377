import type { MobxDocument } from '../firestore-mobx'

import type { FirebaseRepository } from './FirebaseRepository'
import { makeObservable } from 'mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { FirestoreSettingsSlideDeckTag } from '../firestore/SettingsSlideDeckTag/schema'
import { empty } from '../firestore/SettingsSlideDeckTag/schema'

export class SettingsSlideDeckTag extends ObservableModelWithDecorators<FirestoreSettingsSlideDeckTag> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSettingsSlideDeckTag>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get tagName() {
    return this.doc.data.tagName
  }

  get visible() {
    return this.doc.data.tagStatus >= 0
  }

  get hidden() {
    return !this.visible
  }
}
