import { StorageError, deleteObject, StorageErrorCode } from 'firebase/storage'

/**
 * same as deleteObject but captures the error if the object does not exist
 * returns true if the object was deleted, false if it did not exist
 */
export const safeDeleteStorageObject = async (
  ...args: Parameters<typeof deleteObject>
) => {
  try {
    await deleteObject(...args)
    return true
  } catch (e) {
    if (
      e instanceof StorageError &&
      e.code.includes(StorageErrorCode.OBJECT_NOT_FOUND)
    ) {
      return false
    }
    throw e
  }
}
